.matched-buddy-container {
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  margin: 30px;
  border-radius: 10px;
  .match-heading {
    padding: 10px;
    font-size: 40px;
    font-weight: bold;
    margin-top: 20px;
    position: relative;
    .heart1 {
      position: absolute;
      font-size: 44px;
      top: 52px;
      margin: 10px;
      transform: rotate(35deg);
    }
    .heart2 {
      position: absolute;
      font-size: 44px;
      top: 80px;
      right: 180px;
      margin: 10px;
      transform: rotate(-17deg);
    }
  }
  .match-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px;
    .match-text {
      margin: 20px;
      font-size: 18px;
      font-weight: 500;
      position: relative;
      .heart3 {
        transform: rotate(12deg);
        position: absolute;
        bottom: 28px;
        right: 57px;
        font-size: 26px;
      }
    }
    .buddy-pictures {
      margin-bottom: 40px;
      position: relative;
      .user-pic,
      .buddy-pic {
        width: 220px;
        height: 220px;
        border-radius: 50%;
        object-fit: cover;
        object-position: top center;
      }

      .circle {
        position: absolute;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 40px;
        bottom: 60px;
        left: 180px;
        img{
          background: #fff;
          border-radius: 50%;
          width: 6rem !important;
        }
      }
    }
    .btn-items {
      position: relative;
      .btn-message {
        border-radius: 4px;
        background: rgb(63, 131, 236);
        border: none;
        color: #fff;
        text-align: center;
        padding: 2px;
        transition: all 0.5s;
        cursor: pointer;
        margin: 36px;
        box-shadow: 0 10px 20px -8px rgba(0,0, 0,0.3);
        width: 200px;
        height: 50px;
        font-weight: bolder;
      }
      button{
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: 0.5s;
      }
      
      button:after {
        content: '»';
        position: absolute;
        opacity: 0;  
        top: 14px;
        right: -20px;
        transition: 0.5s;
      }
      
      button:hover{
        padding-right: 24px;
        padding-left:8px;
      }
      
      button:hover:after {
        opacity: 1;
        right: 10px;
      }
      .heart4 {
        position: absolute;
        bottom: 109px;
        font-size: 42px;
        transform: rotate(12deg);
        left: -75px;
      }
      .heart5 {
        position: absolute;
        bottom: 115px;
        font-size: 26px;
        transform: rotate(-19deg);
        left: 258px;
      }
      .heart6 {
        position: absolute;
        top: 56px;
        font-size: 42px;
        transform: rotate(-17deg);
        left: 202px;
      }
    }

    .swipe {
      margin-top: 30px;
      color: rgb(63, 131, 236);
      font-size: 16px;
      font-weight: 700;
      transition: all 0.5s;
      cursor: pointer;
      transition: 0.5s;
      width: 204px;
      height: 26px;
      position: relative;
      display: inline-block;
      text-align: center;
    }
    .swipe:after {
      content: "»";
      position: absolute;
      opacity: 0;
      top: 14px;
      right: -20px;
      transition: 0.5s;
      width: 204px;
      height: 26px;
    }
    .swipe:hover {
      box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
      width: 204px;
      height: 26px;
    }
  }
  .grp-heart {
    color: rgba(63, 131, 236, 0.2);
  }
}
