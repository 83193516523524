@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
  .view-matches-container .view-match-layout .headings .settings svg {
    font-size: 1.2rem;
    color: #1B68DF;
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
  }
  .view-matches-container .view-match-layout .headings .live-chat,
  .view-matches-container .view-match-layout .headings .settings {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  .view-matches-container .view-match-layout .person-details {
    margin: 5px;
    color: #2b607d;
    font-weight: bold;
    font-size: 14px;
  }
  .view-matches-container .view-match-layout .activity {
    margin: 5px;
    color: #4d4d4d;
    font-weight: bold;
    font-size: 14px;
  }
  .view-matches-container .view-match-layout .about-info,
  .view-matches-container .view-match-layout .interest-text  {
    font-size: 14px;
  }
  .view-matches-container .view-match-layout .interest-details {
    margin: 5px;
    color: #2b607d;
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 20px);
    margin-top: 10px;
    height: 100px;
    font-size: 14px;
    font-weight: normal;
  }
  .view-matches-container .view-match-layout .pic-with-button .display-pic {
    width: 100%;
    height: 425px;
  }
  .view-matches-container .view-match-layout .pic-with-button {
    width: 100%;
    height: auto;
  }
  .view-matches-container .view-match-layout .pic-with-button .icons-group {
    width: 100%;
    justify-content: center;
  }
  .view-matches-container
    .view-match-layout
    .pic-with-button
    .icons-group
    .icon-back-undo-disabled {
    width: 50px;
    height: 50px;
    right: 24px;
  }
  .view-matches-container
    .view-match-layout
    .pic-with-button
    .icons-group
    .undo {
    font-size: 35px;
  }
  .view-matches-container
    .view-match-layout
    .pic-with-button
    .icons-group
    .icon-back-reject {
    right: 10px;
    position: relative;
  }
  .view-matches-container
    .view-match-layout
    .pic-with-button
    .icons-group
    .icon-back-star {
    left: 12px;
    width: 50px;
    height: 50px;
    top: 30px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
  .view-matches-container .view-match-layout .headings .settings svg {
    font-size: 1.2rem;
    color: #1B68DF;
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
  }
  .view-matches-container .view-match-layout .headings .live-chat,
  .view-matches-container .view-match-layout .headings .settings {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  .view-matches-container .view-match-layout .person-details {
    margin: 5px;
    color: #2b607d;
    font-weight: bold;
    font-size: 14px;
  }
  .view-matches-container .view-match-layout .activity {
    margin: 5px;
    color: #4d4d4d;
    font-weight: bold;
    font-size: 14px;
  }
  .view-matches-container .view-match-layout .about-info,
  .view-matches-container .view-match-layout .interest-text  {
    font-size: 14px;
  }
  .view-matches-container .view-match-layout .interest-details {
    margin: 5px;
    color: #2b607d;
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 20px);
    margin-top: 10px;
    height: 100px;
    font-size: 14px;
    font-weight: normal;
  }
  .view-matches-container .view-match-layout .pic-with-button .display-pic {
    width: 100%;
    height: 425px;
  }
  .view-matches-container .view-match-layout .pic-with-button {
    width: 100%;
    height: auto;
  }
  .view-matches-container .view-match-layout .pic-with-button .icons-group {
    width: 100%;
    justify-content: center;
  }
  .view-matches-container
    .view-match-layout
    .pic-with-button
    .icons-group
    .icon-back-undo-disabled {
    width: 50px;
    height: 50px;
    right: 24px;
  }
  .view-matches-container
    .view-match-layout
    .pic-with-button
    .icons-group
    .undo {
    font-size: 35px;
  }
  .view-matches-container
    .view-match-layout
    .pic-with-button
    .icons-group
    .icon-back-reject {
    right: 10px;
    position: relative;
  }
  .view-matches-container
    .view-match-layout
    .pic-with-button
    .icons-group
    .icon-back-star {
    left: 12px;
    width: 50px;
    height: 50px;
    top: 30px;
  }
}