.select-search {
	//max-width: 350px;
	width: 58vw;
	position: relative;
    font-family: 'Montserrat' !important;
	box-sizing: border-box;
	* {
		box-sizing: inherit;
		&::after {
			box-sizing: inherit;
		}
		&::before {
			box-sizing: inherit;
		}
	}
	&:not(.is-disabled) {
		.select-search__input {
			cursor: pointer;
		}
	}
	&:not(.is-loading) {
		&:not(.select-search--multiple) {
			.select-search__value {
				&::after {
					transform: rotate(45deg);
					border-right: 1px solid #000;
					border-bottom: 1px solid #000;
					pointer-events: none;
				}
			}
		}
	}
	&:not(.select-search--multiple) {
		.select-search__input {
			&:hover {
				border-color: #1B68DF;
			}
		}
		.select-search__select {
			position: absolute;
			z-index: 3;
			top: 60px;
			right: 0;
			left: 0;
			border-radius: 3px;
			overflow: auto;
			max-height: 360px;
			border: 40px;
			border-radius: 15px;
		}
	}
}
.select-search__value {
	position: relative;
	z-index: 1;
	&::after {
		content: '';
		// display: inline-block;
		display: none;
		position: absolute;
		top: calc(50% - 9px);
		right: 19px;
		width: 11px;
		height: 11px;
	}
}
.select-search__input {
	display: block;
	height: 36px;
	width: 100%;
	padding: 0 40px 0 16px;
	background: #fff;
	background-color: rgb(255, 255, 255);
	border: 1px solid transparent;
	box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	outline: none;
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	text-align: left;
	text-overflow: ellipsis;
	line-height: 36px;
	-webkit-appearance: none;
	background-color: white;
	display: flex;
	align-items: center;
	// border: 2px solid #ddd;
	border-radius: 50px;
	padding: 26px 10px;
	&::-webkit-search-decoration {
		-webkit-appearance: none;
	}
	&::-webkit-search-cancel-button {
		-webkit-appearance: none;
	}
	&::-webkit-search-results-button {
		-webkit-appearance: none;
	}
	&::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}
	&:not([readonly]) {
		&:focus {
			cursor: initial;
		}
	}
}
.select-search__select {
	background: #FFF;
	box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}
.select-search__options {
	padding: 12px 0 !important;
	margin: 0px 0 !important;
	list-style: none;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
.select-search__row {
	&:not(:first-child) {
		//border-top: 1px solid #eee;
	}
}
.select-search__option {
	display: block;
	height: max-content;
	padding: 10px 5px;
	width: 100%;
	background: #fff;
	border: none;
	outline: none;
	font-family: 'Montserrat' !important;
	font-weight: 500;
	font-size: 14px;
	text-align: left;
	cursor: pointer;
	&:not(.is-selected) {
		&:hover {
			background: #a1c1f1;
			//color: #fff;
		}
	}
}
.select-search__not-found {
	display: block;
	height: max-content;
	padding: 10px 5px;
	width: 100%;
	background: #fff;
	border: none;
	outline: none;
	font-family: 'Montserrat' !important;
	font-weight: 500;
	font-size: 14px;
	text-align: left;
	cursor: pointer;
	height: auto;
	padding: 16px;
	text-align: center;
	color: #888;
}
.select-search--multiple {
	.select-search__option {
		height: 48px;
	}
	border-radius: 3px;
	overflow: hidden;
	.select-search__input {
		cursor: initial;
		border-radius: 3px 3px 0 0;
	}
	&:not(.select-search--search) {
		.select-search__input {
			cursor: default;
		}
	}
	.select-search__select {
		position: relative;
		overflow: auto;
		//max-height: 260px;
		width: 100%;
		border-top: 2px solid #ddd;
		border-radius: 0 0 3px 3px;
	}
}
.select-search__option.is-selected {
	background: #1B68DF;
	color: #fff;
	&:hover {
		background: #1B68DF;
		color: #fff;
	}
}
.select-search__option.is-highlighted {
	background: #a1c1f1;
	//color: #fff;
}
.select-search__option.is-highlighted.is-selected {
	background: #1B68DF;
	color: #fff;
}
.select-search__group-header {
	font-size: 10px;
	// text-transform: uppercase;
	// background: #eee;
	padding: 8px 8px;
	// border-bottom: 1px solid #eee;
	padding-top: 10px;
	font-size: 22px;
	font-weight: 600;
	color: #033786;
	font-family: 'Montserrat' !important;
}
.select-search.is-disabled {
	opacity: 0.5;
}
.select-search.is-loading {
	.select-search__value {
		&::after {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
			background-size: 11px;
		}
	}
}
