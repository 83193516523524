.route-not-found {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn-primary {
        margin-top: 1rem;
        box-shadow: none;
        border-radius: 10px;
        width: 14rem;
    }
}