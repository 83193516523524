.topMenuNotificationBtn {
    color: $izi-logo-color;
    position: relative;
    height: min-content;
    .topMenuNotificationCount {
      position: absolute;
      background-color: rgb(255, 21, 21);
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: white;
      top: -10px;
      left: -10px;
      z-index: 1;
    }
  
    .far {
      font-size: 2rem;
    }
  }
  .hamburgerMenuToggle {
    cursor: pointer;
    .fas {
      font-size: 35px;
      color: #1B68DF;
      z-index: 2;
    }
  }
  .circle-button{
    width:2.5rem;
    height:2.5rem;
    cursor: pointer;
  }
  .dash-circle-button{
    width:3rem;
    height:3rem;
    cursor: pointer;
  }
  .whatsapp-icon{
    width:3.75rem;
    height:3.75rem;
    margin: 7px 0px 0px 10px;
  }
  .cust-left-pos{
    margin-left: 1rem;
  }
  .topMenuButtonsAlign {
    margin-top: 0.5rem !important;
  }
  .topMenuGeneralSettingsCog {
    margin-top: 5px;
    font-size: 2rem !important;
  }
  .topDashboardMenuUserProfileBtn {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    border-radius: 50%;
    margin-top: 0.4rem !important;
    border:2px solid #E5F69E;
  
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: top center;
    }
  }
  .topMenuUserProfileBtn {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    border-radius: 50%;
    margin-top: 0.4rem !important;
    border:2px solid #E5F69E;
  
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: top center;
    }
  }
  .top-title-custom {
    font-size: 1.5rem;
    font-family: Montserrat;
    font-weight: 600;
    white-space: nowrap;
    margin-top: 0.3rem;
  }
  
  .topMenuBackButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    background-color: #e7faad;
    border-radius: 50%;
    text-align: center !important;
    cursor: pointer;
    .far{
      font-size: 2rem;
    }
  }
.top-menu-pad{
  padding-right: 1rem;
  padding-left: 1rem;
}