@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
  .clinic-by-region {
    font-size: 14px;
    .field-min-width {
      min-width: 145px !important;
    }
    .next-patient {
      height: auto;
      margin-top: 15px !important;
      padding: 0px;
      margin-left: 0px !important;
    }
  }
  .amount-to-pay{
    max-width: 52px !important;
  }
  .amount-to-pay-dropdown{
    max-width: 84px !important;
  }
}
