@media (max-width: 767px) {
 .topMenuNotificationBtn {
    color: $izi-logo-color;
    position: relative;
    height: min-content;
    .topMenuNotificationCount {
        align-items: center;
        background-color: #ff1515;
        border-radius: 50%;
        color: #fff;
        display: flex;
        height: 1rem;
        justify-content: center;
        left: 0px;
        position: absolute;
        top: 0px;
        width: 1rem;
        z-index: 1;
    }
    .far {
      font-size: 1.3rem;
    }
  }
  .hamburgerMenuToggle {
    cursor: pointer;
    .fas {
      font-size: 1.2rem;
      color: #1B68DF;
      z-index: 2;
    }
  }
  .circle-button{
    width:1.6rem;
    height:1.6rem;
    cursor: pointer;
  }
  .dash-circle-button{
    width:2rem;
    height:2rem;
    cursor: pointer;
  }
  .whatsapp-icon{
    width:2.5rem;
    height:2.5rem;
    margin: 7px 0px 0px 10px;
  }
  .tawk-min-container .tawk-button-circle.tawk-button-large {
    width: 2.6rem;
    height: 2.6rem;
  }
  .tawk-min-chat-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .top-title-custom {
    font-size: 1rem;
    font-family: Montserrat;
    font-weight: 600;
    white-space: nowrap;
    margin-top: 0.2rem;
  }
  .topMenuButtonsAlign {
    margin-top: 0.5rem !important;
  }
  .cust-left-pos{
    margin-left: 1rem;
  }
  .topMenuGeneralSettingsCog {
    margin-top: 5px;
    font-size: 1.5rem !important;
  }
  .topDashboardMenuUserProfileBtn {
    width: 2rem;
    height: 2rem;
    border: none;
    margin-top: 0.4rem !important;
    border:2px solid #E5F69E;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: top center;
    }
  }
  .topMenuUserProfileBtn {
    width: 1.6rem;
    height: 1.6rem;
    border: none;
    margin-top: 0.4rem !important;
    border:2px solid #E5F69E;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: top center;
    }
  }
  .topMenuBackButton {
    background-color: #e7faad;
    border-radius: 50%;
    height: 1.8rem;
    width: 1.8rem;
    text-align: center !important;
    margin-top:5px;
    .btn{
      font-size:10px;
    }
    .far{
      font-size: 1rem;
    }
  }
  .top-menu-pad{
    padding:0 !important
  }
  .cust-left-pos{
    margin-left: 1rem;
  }
}

  //For Ipad and Tab
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .top-menu-pad{
      padding-left: 0rem !important;
    }
    .cust-left-pos{
      margin-left: 1rem;
    }
}