@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
  .onboard-fitness-buddy {
    .buddy-details {
      margin: 0px;
      padding: 0px;
      padding-left: 2rem;
    }
  }
  .v-timeline{
    .v-timeline-icon{
    display: none !important;
    }
    .pl-30 {
      padding-left: 0px !important;
    }
  }
  .fb-details-container {
    border-radius: 100%/0 0 20% 20% !important;
    .buddy-profile {
      flex-direction: column;
      align-items: center;
    }
    .profile-info {
      width: 50%;
      height: 100px;
      padding: 0px;
      flex-direction: row !important;
    }
    .profile-pic {
      width: 100px !important;
      height: 100px !important;
    }
  }
  .fb-gallery-container {
    display: none;
  }
  .buddy-details
    .fb-section-1
    .fb-details-container
    .buddy-profile
    .profile-info
    .upload-img {
    bottom: 0px;
    left: -16px;
    top: 20px;
    width: 30px;
    height: 30px;
  }
  .buddy-details .fb-section-1 .fb-details-container .buddy-profile .about-me {
    margin: 0px;
    font-size: 14px;
  }
  .add-interest .modal-dialog .modal-content {
    width: 300px;
    height: 400px;
  }
  .add-interest {
    .modal-dialog {
      width: 100%;
      height: 400px;
      .modal-content {
        width: 100%;
        height: 400px;
        margin: 20px;
      }
    }
  }
  .show-interest {
    height: 200px;
  }
  .add-interest .modal-dialog .modal-content .modal-body .show-interest ul {
    height: 200px;
  }
  ::-webkit-scrollbar {
    width: 2px !important;
  }
  .buddy-details .fb-section-1 .fb-details-container .all-interest {
    height: 100px;
  }
  .buddy-details
    .fb-section-1
    .fb-details-container
    .all-interest
    .display-interest {
    font-size: 14px;
    height: 20px;
  }
  .buddy-details
    .fb-section-1
    .fb-details-container
    .all-interest
    .display-interest
    svg {
    top: -5px;
  }
  .buddy-details .fb-section-1 .fb-details-container .add-interest button {
    width: 100%;
    height: 36px;
    font-size: 14px;
  }
  .buddy-details .fb-section-1 .fb-details-container .add-interest svg {
    font-size: 22px;
  }
  .buddy-details .fb-section-1 .fb-details-container .add-interest {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  .buddy-details .fb-section-2 .fb-settings-container {
    width: 100%;
    font-size: 14px;
    margin-right: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .buddy-details .fb-section-2 .fb-settings-container .setting {
    font-size: 16px;
    margin: 0px;
    margin-top: 10px;
  }
  .buddy-details .fb-section-2 .fb-settings-container .setting .is-adult {
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
  }
  .buddy-details .fb-section-2 .fb-settings-container .setting .is-adult .text {
    margin-right: 0px;
    padding-right: 0px;
  }
  .buddy-details
    .fb-section-2
    .fb-settings-container
    .discover-settings
    .d-setting {
    font-size: 16px;
    margin: 0px;
  }
  .buddy-details
    .fb-section-2
    .fb-settings-container
    .discover-settings
    .info
    .location,
  .buddy-details
    .fb-section-2
    .fb-settings-container
    .discover-settings
    .info
    .location
    .loc-details
    .current-location {
    font-size: 14px;
    width: 100%;
  }
  .buddy-details
    .fb-section-2
    .fb-settings-container
    .discover-settings
    .info
    .location
    .button-map
    svg {
    font-size: 28px;
  }
  .buddy-details .fb-section-2 .fb-settings-container .distance,
  .buddy-details .fb-section-2 .fb-settings-container .g-text,
  .buddy-details .fb-section-2 .fb-settings-container .age-slider .age-details,
  .buddy-details .fb-section-2 .fb-settings-container .is-online {
    margin: 12px 0px 0px 5px;
  }
  .buddy-details .fb-section-2 .fb-settings-container .discover-settings .info {
    margin-left: 5px;
  }
  .buddy-details .fb-section-2 .range-style {
    padding: 0px;
  }
  .distance {
    input[type="range"]::-webkit-slider-thumb {
      height: 30px !important;
      width: 30px !important;
      top: -25% !important;
    }
  }
  .react-switch-label {
    width: 40px;
    height: 20px;
    .react-switch-button {
      top: 0px;
      left: 0px;
      width: 20px;
      height: 20px;
    }
  }
  .buddy-details .fb-section-2 .fb-settings-container .gender .male,
  .buddy-details .fb-section-2 .fb-settings-container .gender .female,
  .buddy-details .fb-section-2 .fb-settings-container .gender .all {
    font-size: 14px;
    gap: 2px;
    label {
      margin: 0px !important;
    }
  }
  .buddy-details .fb-section-2 .fb-settings-container .gender .grid {
    gap: 5px;
    flex-wrap: wrap;
  }
  .buddy-details
    .fb-section-2
    .fb-settings-container
    .age-slider
    .min-max-slider {
    left: 0px;
    input[type="range"]::-webkit-slider-thumb {
      height: 30px !important;
      width: 30px !important;
      top: -25% !important;
    }
  }
  .buddy-details .fb-section-2 .fb-settings-container .is-online {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
  }
  .buddy-details .fb-section-2 .fb-settings-container .online-info-text {
    margin: 10px 5px;
  }
  .buddy-details .fb-section-2 .fb-notifi-container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 1rem;
    height: 100% !important;
    width: 100%;
  }
  .buddy-details .fb-section-2 .fb-notifi-container .vaccinated label,
  .buddy-details .fb-section-2 .fb-notifi-container .new-matches label,
  .buddy-details .fb-section-2 .fb-notifi-container .messages label,
  .buddy-details .fb-section-2 .fb-notifi-container .message-likes label,
  .buddy-details .fb-section-2 .fb-notifi-container .super-likes label,
  .buddy-details .fb-section-2 .fb-notifi-container .in-app-vibrations label,
  .buddy-details .fb-section-2 .fb-notifi-container .in-app-sounds label {
    margin: 0px;
  }
  .buddy-details .fb-section-2 .fb-notifi-container .notifi-text {
    font-size: 16px;
  }
  .buddy-details .fb-section-2 .fb-notifi-container .new-matches,
  .buddy-details .fb-section-2 .fb-notifi-container .messages,
  .buddy-details .fb-section-2 .fb-notifi-container .message-likes,
  .buddy-details .fb-section-2 .fb-notifi-container .super-likes,
  .buddy-details .fb-section-2 .fb-notifi-container .in-app-vibrations,
  .buddy-details .fb-section-2 .fb-notifi-container .in-app-sounds {
    margin-left: 0px;
    font-size: 14px;
    height: 33px;
  }
  .buddy-details .fb-section-2 {
    flex-direction: column;
  }
  .map-modal {
    .modal-dialog {
      width: 100%;
      height: 600px;
      .modal-content {
        width: 100%;
        height: 600px;
        margin: 20px;
      }
    }
  }
  .map-modal .modal-dialog .modal-content .modal-header .modal-title {
    font-size: 16px;
  }
  .map-modal .modal-dialog .modal-content .modal-body .location-text {
    font-size: 14px;
  }
  .map-search-box {    
    .location-name{
      width: 100%;
    }
  }
  
  .map-modal .modal-dialog .modal-content .modal-body .map-view-container .location-name{
    width: 100%;
  }
  .map-modal .modal-dialog .modal-content .modal-body .map-view-container .dropdown-place{
    margin:0px;
    width: 100%;
    top:35px;
    }
    .map-modal .modal-dialog .modal-content .modal-body .map-view-container .map-search-box .remove-locationName{
      font-size: 35px;
    }
  .map-modal .modal-dialog .modal-content .modal-body .map-view-container .apply-button{
    right: 0px;
    position: absolute;
    top: 456px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .onboard-fitness-buddy {
    .buddy-details {
      margin: 0px;
      padding: 0px;
      padding-left: 2rem;
    }
  }
  .fb-details-container {
    border-radius: 100%/0 0 20% 20% !important;
    .buddy-profile {
      flex-direction: column;
      align-items: center;
    }
    .profile-info {
      width: 50%;
      height: 100px;
      padding: 0px;
      flex-direction: row !important;
    }
    .profile-pic {
      width: 100px !important;
      height: 100px !important;
    }
  }
  .fb-gallery-container {
    display: none;
  }
  .buddy-details
    .fb-section-1
    .fb-details-container
    .buddy-profile
    .profile-info
    .upload-img {
    bottom: 0px;
    left: -16px;
    top: 20px;
    width: 30px;
    height: 30px;
  }
  .buddy-details .fb-section-1 .fb-details-container .buddy-profile .about-me {
    margin: 0px;
    font-size: 14px;
  }
  .add-interest .modal-dialog .modal-content {
    width: 100%;
    height: 400px;
  }
  .add-interest {
    .modal-dialog {
      width: 100%;
      height: 400px;
      .modal-content {
        width: 100%;
        height: 400px;
      }
    }
  }
  .show-interest {
    height: 200px;
  }
  .add-interest .modal-dialog .modal-content .modal-body .show-interest ul {
    height: 200px;
  }
  ::-webkit-scrollbar {
    width: 2px !important;
  }
  .buddy-details .fb-section-1 .fb-details-container .all-interest {
    height: 100px;
  }
  .buddy-details
    .fb-section-1
    .fb-details-container
    .all-interest
    .display-interest {
    font-size: 14px;
    height: 20px;
  }
  .buddy-details
    .fb-section-1
    .fb-details-container
    .all-interest
    .display-interest
    svg {
    top: -5px;
  }
  .buddy-details .fb-section-1 .fb-details-container .add-interest button {
    width: 100%;
    height: 36px;
    font-size: 14px;
  }
  .buddy-details .fb-section-1 .fb-details-container .add-interest svg {
    font-size: 22px;
  }
  .buddy-details .fb-section-1 .fb-details-container .add-interest {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  .buddy-details .fb-section-2 .fb-settings-container {
    width: 100%;
    font-size: 14px;
    margin-right: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .buddy-details .fb-section-2 .fb-settings-container .setting {
    font-size: 16px;
    margin: 0px;
    margin-top: 10px;
  }
  .buddy-details .fb-section-2 .fb-settings-container .setting .is-adult {
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
  }
  .buddy-details .fb-section-2 .fb-settings-container .setting .is-adult .text {
    margin-right: 0px;
    padding-right: 0px;
  }
  .buddy-details
    .fb-section-2
    .fb-settings-container
    .discover-settings
    .d-setting {
    font-size: 16px;
    margin: 0px;
  }
  .buddy-details
    .fb-section-2
    .fb-settings-container
    .discover-settings
    .info
    .location,
  .buddy-details
    .fb-section-2
    .fb-settings-container
    .discover-settings
    .info
    .location
    .loc-details
    .current-location {
    font-size: 14px;
    width: 100%;
  }
  .buddy-details
    .fb-section-2
    .fb-settings-container
    .discover-settings
    .info
    .location
    .button-map
    svg {
    font-size: 28px;
  }
  .buddy-details .fb-section-2 .fb-settings-container .distance,
  .buddy-details .fb-section-2 .fb-settings-container .g-text,
  .buddy-details .fb-section-2 .fb-settings-container .age-slider .age-details,
  .buddy-details .fb-section-2 .fb-settings-container .is-online {
    margin: 12px 0px 0px 5px;
  }
  .buddy-details .fb-section-2 .fb-settings-container .discover-settings .info {
    margin-left: 5px;
  }
  .buddy-details .fb-section-2 .range-style {
    padding: 0px;
  }
  .distance {
    input[type="range"]::-webkit-slider-thumb {
      height: 30px !important;
      width: 30px !important;
      top: -25% !important;
    }
  }
  .react-switch-label {
    width: 40px;
    height: 20px;
    .react-switch-button {
      top: 0px;
      left: 0px;
      width: 20px;
      height: 20px;
    }
  }
  .buddy-details .fb-section-2 .fb-settings-container .gender .male,
  .buddy-details .fb-section-2 .fb-settings-container .gender .female,
  .buddy-details .fb-section-2 .fb-settings-container .gender .all {
    font-size: 14px;
    gap: 2px;
    label {
      margin: 0px !important;
    }
  }
  .buddy-details .fb-section-2 .fb-settings-container .gender .grid {
    gap: 5px;
    flex-wrap: wrap;
  }
  .buddy-details
    .fb-section-2
    .fb-settings-container
    .age-slider
    .min-max-slider {
    left: 0px;
    input[type="range"]::-webkit-slider-thumb {
      height: 30px !important;
      width: 30px !important;
      top: -25% !important;
    }
  }
  .buddy-details .fb-section-2 .fb-settings-container .is-online {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
  }
  .buddy-details .fb-section-2 .fb-settings-container .online-info-text {
    margin: 10px 5px;
  }
  .buddy-details .fb-section-2 .fb-notifi-container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: 100% !important;
    padding: 1rem;
    width: 100%;
  }
  .buddy-details .fb-section-2 {
    flex-direction: column;
  }
  .buddy-details .fb-section-2 .fb-notifi-container .vaccinated label,
  .buddy-details .fb-section-2 .fb-notifi-container .new-matches label,
  .buddy-details .fb-section-2 .fb-notifi-container .messages label,
  .buddy-details .fb-section-2 .fb-notifi-container .message-likes label,
  .buddy-details .fb-section-2 .fb-notifi-container .super-likes label,
  .buddy-details .fb-section-2 .fb-notifi-container .in-app-vibrations label,
  .buddy-details .fb-section-2 .fb-notifi-container .in-app-sounds label {
    margin: 0px;
  }
  .buddy-details .fb-section-2 .fb-notifi-container .notifi-text {
    font-size: 16px;
  }
  .buddy-details .fb-section-2 .fb-notifi-container .new-matches,
  .buddy-details .fb-section-2 .fb-notifi-container .messages,
  .buddy-details .fb-section-2 .fb-notifi-container .message-likes,
  .buddy-details .fb-section-2 .fb-notifi-container .super-likes,
  .buddy-details .fb-section-2 .fb-notifi-container .in-app-vibrations,
  .buddy-details .fb-section-2 .fb-notifi-container .in-app-sounds {
    margin-left: 0px;
    font-size: 14px;
  }
  .map-modal {
    .modal-dialog {
      width: 100%;
      height: 600px;
      .modal-content {
        width: 100%;
        height: 600px;
        margin: 20px;
      }
    }
  }
  .map-modal .modal-dialog .modal-content .modal-header .modal-title {
    font-size: 16px;
  }
  .map-modal .modal-dialog .modal-content .modal-body .location-text {
    font-size: 14px;
  }
  .map-search-box {    
    .location-name{
      width: 100%;
    }
  }
  
  .map-modal .modal-dialog .modal-content .modal-body .map-view-container .location-name{
    width: 100%;
  }
  .map-modal .modal-dialog .modal-content .modal-body .map-view-container .dropdown-place{
    margin:0px;
    width: 100%;
  }
  .map-modal .modal-dialog .modal-content .modal-body .map-view-container .apply-button{
    right: 0px;
    position: absolute;
    top: 456px;
  }
  .map-modal .modal-dialog .modal-content .modal-body .map-view-container .map-search-box .remove-locationName{
    font-size: 35px;
  }
}
