@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
    .mobile-redirect{
      margin-top: 13px;
      font-size: 17px;
      margin-right: 1rem;
      margin-left: 0.5rem;
      width: 20rem;
    }
    .mealplan-card {
        background-color: rgb(255 255 255 / 70%);
        //box-shadow: 0px 0px 5px #bbb;
        padding: 5px 5px 5px 5px;
        border-radius: 10px;
        margin-left: 1rem;
    }
    .mp-sub-header{ margin-left: 1.2rem; margin-top: .5rem; }
    .browser_result {
        /* max-width: 25%; */
        min-width: 25%;
        border-style: none;
        background-color: rgba(255,255,255,0);
        list-style: none;
      }
      .card-img-top {
        width: 100%;
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px);
      }
      .food_browser_results_list{
        min-width: 50rem;
        padding:0px 0px !important;
        margin-left:0rem;
      }
      .mp-card{
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        //background-color: #fff;
        background-clip: border-box;
        //border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        max-width:17rem;
        padding:0 !important;
        .card-body {
            flex: 1 1 auto;
            min-height: 1px;
            padding: 0.5rem;
          }
          .card-title {
            margin-bottom: 0.3rem;
          }
          .card-footer {
            background-color: rgb(0 0 0 / 0%);
            border-top: 1px solid rgb(255 255 255 / 0%);
            padding: 0;
          }
    }
    .set-tile-width{
      min-width: 9rem;
      margin:12px;
    }
    .holo-card-spec{
      min-width: 12rem;
    }
    .mp-alert{
      margin-bottom: .1rem;
      .mp-alert-heading{
        font-size: .7rem;
      }
      p{
        font-size: .6rem;
        margin-right: 1rem;   
      }
    }
    
    // .food_browser_results_list {
    //     overflow-x: hidden;
    //     width: 100%;
    //     .loading-container { width: 100px; }
    // }
    // .scroll-snap-parent-x { scroll-snap-type: block; } 
    // .food_browser_results_list { overflow-x: scroll; } 
    // .food_browser_results_list::-webkit-scrollbar { height: 5px !important; } /* Track */ 
    // .food_browser_results_list::-webkit-scrollbar-track { background: #f1f1f1; } /* Handle */ 
    // .food_browser_results_list::-webkit-scrollbar-thumb { background: rgb(245, 248, 254); } /* Handle on hover */ 
    // .food_browser_results_list::-webkit-scrollbar-thumb:hover { background: #555; } 
    // .scrollBtn { display: none; }

    }
    //For Ipad and Tab
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      .mobile-redirect{
        margin-top: 13px;
        font-size: 17px;
        margin-right: 1rem;
        margin-left: 0.5rem;
      }
      .food_browser_results_list{
          min-width: 51rem;
          margin-left:0rem;
        }
        // // .set-tile-width {
        // //   max-width: 12rem;
        // //   margin-left: 3rem;
        // // }
        .mp-alert{
          margin-bottom: .1rem;
          .mp-alert-heading{
            font-size: 1rem;
          }
          p{
          font-size: .9rem;
          margin-right: 1rem;
          }
        }
        .mp-card{
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          min-width: 0;
          word-wrap: break-word;
          //background-color: #fff;
          background-clip: border-box;
          //border: 1px solid rgba(0, 0, 0, 0.125);
          border-radius: 0.25rem;
          max-width:17rem;
          padding:0 !important;
          .mp-inner-card{
            min-height: 38vh;
            background: white;
            min-width: 11.5vw;
            border-radius: 0.5rem;
          }
          .card-body {
              flex: 1 1 auto;
              min-height: 1px;
              padding: 0.5rem;
            }
            .card-title {
              margin-bottom: 0.3rem;
            }
            .card-footer {
              background-color: rgb(0 0 0 / 0%);
              border-top: 1px solid rgb(255 255 255 / 0%);
              padding: 0;
            }
      }
    }