@import "variables";
@media (max-width: 867px) {
  /* Tablet Portrait and Mobile */
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  .h6, h6 {
    font-size: .95rem;
  }
  .izi-container {
    width: 95% !important;
    margin: 0.5rem 0% 0% 7%;
    padding: 20px 20px 20px 20px;
    background: #ffffffeb;
    border-radius: 1rem;
    border: 1px solid #ffffffab;
  }
  .mobile-px-none{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .btn {
    font-size: 11px;
    font-family: "Montserrat", sans-serif;
  }
  .btn-primary {
    border-radius: 15px;
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
    border-radius: 50px !important;
    margin-top: 1rem;
    box-shadow: none;
    border-radius: 10px;
    width: 75%;
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
  }
  .side_drawer .mm-nav .nav-link {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
    border-radius: 50px;
    margin-bottom: 0.5rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    justify-content: flex-start;
    align-items: center;
    height: 2.5rem;
    text-overflow: clip;
    font-family: "Montserrat";
    font-size: 16px;
  }
  .side_drawer .mm-nav .nav-link.active {
    background-color: #1B68DF;
    color: white;
  }
  .side_drawer .mm-nav .nav-link.hover {
    background-color: #3d3d3d;
    color: white;
  }
  .nav-label {
    font-family: "Montserrat";
    font-size: 14px;
    display: flex;
    justify-content: left;
    align-items: left;
  }
  .menuToggle .fas {
    font-size: 1.3rem;
    color: #1B68DF;
    z-index: 10001;
    margin-top: 0.1rem;
    margin-left: 0.5rem;
  }
  .topBarButtonsAlign {
    margin-top: 0.5rem !important;
  }
  .ant-drawer-close{
    font-size: 12px !important;
  }
  .generalSettings{
    .region-dropdown{
      width:8rem;
    }
  }
  .profile-btn {
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    margin-top: 0.4rem !important;
    border: 10px solid orange;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: top center;
    }
  }
  .user-profile-btn {
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    margin-top: 0.4rem !important;
  
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: top center;
    }
  }
  .FooterStoreButtons {
    cursor: pointer;
    object-fit: contain;
    width: 7rem !important;
  }
  .live-session-image-holder{
    width:21rem;
  }
  .auth-base-layout .top-right-circle,
  .auth-base-layout .bottom-left-circle {
    position: fixed;
    width: 19rem;
    height: 19rem;
    border-radius: 50%;
    z-index: 0;
  }
  .custom-card-styling-pract {
    width: 10rem !important;
    border: 4px solid #ebebeb !important;
    height: 11rem !important;
    margin-bottom: 1rem !important;
    margin-left:0;
  }
  .doctor-name {
    font-weight: 500;
    color: #022360;
    font-size: 14px !important;
    font-weight: 600;
    margin-bottom: 0.1rem;
    margin-right: 0.6rem;
  }
  .top-doc-style{
    color: #666;
    font-size: .7rem !important;
  }
  .fees-amount {
    font-size: 11px !important;
  }
  .side-menu.open {
    right: 0px;
    padding: 0 !important;
    z-index: 10001;
  }
  .correctpadding {
    .select-search__input {
      padding: 1rem 3rem !important;
      font-size: 12px;
    }
  }
  .align-quick-launch{
    width:36rem !important;
  }
  .dashboard-book-consultation .select-search__input{
    padding: 1rem 3rem !important;
    font-size: 12px;
  }
  .dashboard-book-consultation .top-doctors .doctor-cards-list .doctor-card .doctor-speciality{
    color: #477da4;
    font-weight: 500;
    font-size: 0.7rem;
  }
  .dashboard-book-consultation .top-doctors .doctor-cards-list .doctor-card .card-link{
    white-space: nowrap;
    color: #1B68DF;
    font-weight: normal;
    font-size: 0.7rem !important;
  }
  .dashboard-book-consultation .top-doctors .doctor-cards-list .doctor-card .day-card{
    font-size: .7rem !important;
  }
  .dashboard-book-consultation .top-doctors .doctor-cards-list .doctor-card .day-slots{
    font-size: .7rem !important;
  }
  .no-slots-available{
    font-size: .7rem;
  }
  .insurance-covered {
    background-color: #fac96e;
    color: #021d48;
    padding: 5px 10px;
    height: min-content;
    border-radius: 30px;
    white-space: pre-wrap;
    font-size: 0.7rem !important;
    span {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .insurance-not-covered {
    background-color: #8dc0fe;
    color: #021d48;
    padding: 5px 10px;
    height: min-content;
    border-radius: 30px;
    white-space: pre-wrap;
    font-size: 0.7rem !important;
    span {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .stripe-payment-checkout .btn-secondary-circle-border, .stripe-payment-checkout .btn-primary{
    width: 8rem;
  }
  .payment-info .payment-status-card .status{
    font-size: 1.5rem !important;
  }
  .payment-card{
    background-color: white;
    // padding: 1rem 0rem;
    .title {
      justify-content: center;
    }

    .sub-heading {
      text-align: center;
    }
  }
  .font-style-custom-payment{
    font-size: .9rem !important;
  }
  .payment-info .payment-status-card .mm-radio-button .radio{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    background-color: #a8a8a8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notifications-btn .notifications-count {
    align-items: center;
    background-color: #ff1515;
    border-radius: 50%;
    color: #fff;
    display: flex;
    height: 1rem;
    justify-content: center;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 1rem;
    z-index: 1;
  }
  .card {
    justify-content: center;
    align-items: center;
    padding: 8px I !important;
    border-radius: 10px;
  }
  .custom-appointment-btn{
    width: 6rem !important;
    height: 3rem;
    padding: 0 !important;
    margin-left: 1rem;
  }
  .doctor-speciality-icon {
    color: #477da4 !important;
    font-size: 12px;
  }
  .select-search {
    max-width: 16rem;
    width: 16rem;
    position: relative;
    font-family: "Montserrat" !important;
    box-sizing: border-box;
  }
  .more-filters {
    background: #fff;
    // border: 2px solid #ddd;
    // box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 15%) !important;
    border-radius: 50px !important;
    min-width: 20rem;
    width: 20rem;
    max-height: 36px !important;
    padding: 1rem 0rem !important;
    .more {
      color: #1B68DF;
      font-weight: 500;
      font-size: 14px;
    }
    .fas {
      font-size: 1.2rem;
  }
  }
  .appointment-details-card {
    .appointment-date {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
    }

    .day-time {
      display: flex;
      justify-content: space-between;

      .day-time-item {
        width: 48%;
        background-color: lighten($color: $izi-logo-color, $amount: 20);
        border-radius: 10px;
        color: rgb(238, 238, 238);
        padding: 0.5rem 0.6rem;
        display: flex;
        align-items: center;

        &.selected {
          background-color: $izi-logo-color;
          .icon-container {
            background-color: white;

            .fas,
            .fal {
              color: $izi-logo-color;
            }
          }
        }

        .icon-container {
          background-color: rgb(238, 238, 238);
          padding: 0.2rem;
          border-radius: 0.5rem;
          margin-right: 0.5rem;

          .fas,
          .fal {
            font-size: 1.5rem;
            color: grey;
          }
        }
      }
    }

    .allocated-slot {
      display: flex;
      align-items: center;

      .text {
        font-weight: 600;
        margin-bottom: 0px;
      }

      .value {
        background-color: lighten($color: $izi-logo-color, $amount: 30);
        height: 3rem;
        width: 7rem;
        font-weight: 500;
        border-radius: 5px;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .appointment-feature-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(214, 214, 214);
        width: 2rem;
        height: 2rem;
        border-radius: 5px;
        color: $izi-logo-color;
        margin-right: 1rem;
      }

      .feature-name {
        margin-bottom: 0px;
        font-weight: 600;
      }

      .feature-desc {
        font-size: 0.8rem;
        margin-bottom: 0rem;
      }

      &.selected {
        background-color: #8fc9fe;
      }
    }

    .slot-time-details {
      font-size: 13px !important;
      font-weight: 600;
      margin-top: 2rem;
      color: #000 !important;
    }
  }
  .sign-up-otp-modal {
    .modal-dialog {
      width: 12rem !important;
      min-width: 16rem;
      margin: auto;
    }
    .neumorphic-input {
      box-shadow: inset 1px 2px 8px #efefef, inset -8px -8px 8px #ffffff;
      border-radius: 10px;
    }
    .modal-content {
      width: 12rem !important;
      min-width: 16rem;
      height: 500px;
    }
  }
  .signup-header-text{
    font-size: .7rem;
  }

  .section-header .title {
    margin: 0px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px !important;
    margin-left: 0.5rem;
  }
  .appointment-bold {
    font-weight: bold;
    min-width: 1rem;
    font-size: 10px;
  }
  .appointment-content {
    font-size: 10px;
  }
  .time-slot-appointment {
    border: none;
    border-radius: 0px;
    background-color: #fff;
    min-width: 20px;
    width:6rem;
  }
  .time-slot-appointment .header {
    background-color: #22274c; 
    width: 100%;
    height: 0px;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; 
}
  .download-btn-appointment {
    background-color: #4a83e7;
    color: #fff !important;
    font-size: 15px;
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
    max-width: max-content;
  }
  .custom-tile-card {
    gap: 10px;
    max-width: 100%;
  }
  .tabs {
    width: 100%;
    height: min-content;
    .tab {
      cursor: pointer;
      flex: 1;
      font-size:.7rem;
      &.active {
        span {
          font-weight: 700;
          color: #000 !important;
        }
      }
      &:not(.active) {
        color: rgb(179, 179, 179);
      }
    }
  }
  .quick-book{
    border-radius: 50px;
    color: #1b68df;
    border: 1px solid #1b68df;
    padding: 4px 15px;
    font-size:.6rem;
    background-color: #1b69df00;
    }
  .online-tag-align {
    //color: #3fec84;
    gap: 5px;
    top: 5px;
    right: 10px;
    font-size:10px;
  }
  .online-fees-align {
    color: #000000;
    gap: 5px;
    top: 5px;
    right: 10px;
  }
  .online-green-tag {
    width: 15px;
    height: 15px;
    background-color: #3fec84;
    border-radius: 50%;
  }
  .big-online-green-tag {
    width: 30px;
    height: 30px;
    background-color: #3fec84;
    border-radius: 50%;
  }
  .online-text-tag {
    color: #3fec84;
    display: none;
  }
  .prime-button-style {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
    border-radius: 50px !important;
    margin-top: 1rem !important;
    margin-left: 25%;
  }
  .card-gap-set {
    width: auto;
    gap: 0px !important;
    height: max-content;
  }
  .card-tiles-font {
    font-size: 10px !important;
    font-weight: normal;
  }
  .video-card .video-card-thumbnail {
    height: 75%;
    min-height: 124px;
  }
  .video-card {
    width: 10rem !important;
    margin-left: .8rem !important;
    .title {
      font-size: 0.8rem !important;
      font-weight: 500 !important;
    }
    .live-details-button {
      background-color: #1b69df00 !important;
      color: #1B68DF;
      border: 1px solid #1B68DF;
      padding: none;
      border-radius: 50px;
      font-size: 12px;
    }
  }
  .session-type {
    font-size: 0.6rem !important;
  }
  .fees-align {
    margin-top: 1.2rem;
    font-weight: 500;
    color: #01096e;
    .fees-amount {
      font-size: 9px !important;
      width:3rem !important;
      margin-left: 15% !important;
    }
  }
  .warning-appointment {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
  }
  .dashboard-home .home-fitness .video-card {
    //margin-right: 1.5rem;
    min-height: max-content;
    width: 7rem !important;
  }
  .doctor-cards-list-pad{
    padding: 0px 0px;
    overflow-x: hidden;
  }
  .quicklinks-align{
    background-color:rgb(0 0 0 / 0%);
    margin-bottom:7rem;
  }
  .topMenuRegion{
    .region-dropdown {
      width: 7rem;
      min-width: 6rem !important;
      margin-right: .5rem;
      margin-top: .3rem;
      color: #002f78;
      font-weight: bold;
      .ant-select-selector {
        border-radius: 50px !important;
        height: 40px;
        padding-top: 5px;
      }
    }
  }
  .topMenuLanguage{
    .language-dropdown {
      width: 6rem;
      min-width: 6rem !important;
      //margin-right: 1rem;
      margin-top: .3rem;
      color: #002f78;
      font-weight: bold;
      .ant-select-selector {
        border-radius: 50px !important;
        height: 40px;
        padding-top: 5px;
      }
    }
  }
  .banner{
    .banner-header {
      text-align: start;
      font-size: 20px;
      margin-left: 2rem;
      font-size: 1.2rem;
      font-weight: bold;
      word-break: break-word;
      width: 15rem;
    }
    .banner-text {
      margin-left: 20px;
      margin-top: 10px;
      margin-left: 2rem;
      font-size: 1rem;
      font-weight: 500;
    }
    .banner-description {
      margin-left: 20px;
      margin-top: 5px;
      margin-left: 2rem;
      font-size: .8rem;
      word-break: break-word;
      width:15rem;
    }
  }
  .item-img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }

}
@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
  .fitness-header {
    padding: 0px !important;
    h5 {
      font-size: 14px;
    }
  }
  .justify-content-center {
    justify-content: center !important;
  }
  .customer-dashboard-item-card {
    height: 15.5rem !important;
  }
  .notifications-side-menu {
    width: 18rem;
    right: -26rem;
  }
  .backbutton-insight {
    background-color: #e7faad;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    text-align: center !important;
    margin-top:5px;
    .btn{
      font-size:10px;
    }
  }
  .general-settings-cog {
    margin-top: 5px;
    font-size: 1.5rem !important;
  }

  .Page-title-custom {
    font-size: 1.1rem;
    font-family: Montserrat;
    font-weight: 600;
    white-space: nowrap;
    margin-top: 0.6rem;
}

.tab-title-custom {
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 1px;
  margin-top: 0.4rem;
}
  .title-border {
    width: 100%;
    background: #1B68DF;
    height: 3px;
    border-radius: 50px;
  }
  .history-notifications {
    display: flex;
    justify-content: center;
    gap: 6px;
    background: #1B68DF;
    width: 100%;
    white-space: nowrap;
    color: #fff;
    padding: 9px 13px;
    border-radius: 8px;
    max-width: min-content;
    align-self: start;
    margin-left: 1rem;
    cursor: pointer;
    font-size: 12px;
    border-radius: 50px;
  }
  .notifications-btn .far {
    font-size: 1.5rem;
  }
  .not-found-card {
    min-height: 20rem;
    width: 100% !important;
  }
  .card-title {
    font-size: 18px;
  }

  .modal_custom .modal-content {
    width: 100% !important;
  }
  .custom-profile-setting-card {
    padding: 20px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background: #fff !important;
    min-width: 90vw;
    width: auto;
    max-width: 40vw;
    min-height: 400px;
    .custom-profile-setting-heading {
      width: min-content;
    }
    .custom-profile-setting-label {
      font-weight: 600;
      min-width: 100px;
      width: auto;
      text-align: start;
      white-space: nowrap;
      font-size:12px;
    }
    .custom-profile-setting-select > * {
      min-width: 8rem !important;
    }
    .custom-profile-setting-input > * {
      min-width: 40px !important;
      max-width: 100% !important;
      width: 100% !important;
      height:1.5rem !important;
      font-size: 11px;
    }
  
    .custom-profile-setting-btn {
      background-color: #1B68DF;
      width: 100px;
      border-radius: 50px;
      color: #fff;
    }
    .btn-gray {
      background-color: #686a6c;
    }
    .custom-settings-profile-container {
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      border-radius: 10px;
      min-width: 350px;
      max-width: 350px;
      width: auto;
      height: auto;
      .custom-settings-profile {
        height: 300px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      // .row {
      // }
    }

  }
  .profile-settings-button-gap{
    gap:20px;
  }
  .gender-select-field {
    display: flex;
  
    .field-label {
      width: 40%;
      font-weight: bold;
      font-size:12px;
    }
  
    .check-box-container {
      display: flex;
    }
  
    .mm-radio-button {
      display: flex;
      align-items: center;
      .label {
        color: rgb(54, 54, 54);
        font-size:11px;
      }
  
      .radio {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        margin-right: 0.5rem;
        background-color: rgb(168, 168, 168);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
  
        &.selected {
          background-color: $izi-logo-color;
        }
  
        .far {
          color: white;
          font-size: 1.3rem;
        }
      }
    }
  }
  
  .video-card {
    margin-left: 0rem; 
    height: 15rem !important;
    position: relative;
    background-color: white;
    min-width: 10rem !important;
    position: relative;
    background-color: white;
  }
  .doctor-tile-gap{
    gap: 5px;
    max-width: 100%;
    margin-left: .5rem;
  }
  .card-list-gap{
    gap: 20px;
    max-width: 800px;
  }
  .timing {
    background-color: #0069d9;
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 0 10px;
    padding: 5px 10px;
    color: white;
    font-size: 0.7rem !important;
  }
  .free-consul{
    margin-right: -11px;
  }
}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .logo-style {
    position: "relative";
    top: "35px";
    font-size: "25px";
    max-width: 20% !important;
    min-width: 35% !important;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  // .logo-style{ position: "relative"; top: "35px"; font-size: "25px" ; max-width: 20% !important; }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  //.logo-style{ position: "relative"; top: "35px"; font-size: "25px" ; max-width: 20% !important; }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
  .logo-style {
    position: "relative";
    top: "35px";
    font-size: "25px";
    max-width: 20% !important;
    min-width: 35% !important;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .live-session-image-holder{
    width:48rem;
  }
  .video-card {
    margin-left: 0rem; 
    height: 15rem !important;
    position: relative;
    background-color: white;
    min-width: 12rem !important;
    position: relative;
    background-color: white;
    width:11rem !important;
    margin-left:1rem;
  }
  /* Styles */
}
/**********
    iPad 3
    **********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  .logo-style {
    position: "relative";
    top: "35px";
    font-size: "25px";
    max-width: 20% !important;
    min-width: 35% !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .logo-style {
    position: "relative";
    top: "35px";
    font-size: "25px";
    max-width: 20% !important;
    min-width: 35% !important;
  }
}
/* iPhone 6 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  .logo-style {
    position: "relative";
    top: "35px";
    font-size: "25px";
    max-width: 20% !important;
    min-width: 35% !important;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}
