// create prescription page style start
.consultation-section-3 {
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  border-radius: 12px;
  // padding-bottom: 55px;
  // border: 1px solid #3e3f40;
  //box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
}

.pres-download-para {
  color: #a5a8b9;
  margin-top: 20px;
  font-weight: 500;
  margin-left: 20px;
  width: 70%;
}

.create-prescription {
  .container-fluid {
    width: 97%;
  }

  .tab {
    span {
      padding-bottom: 0.5rem;
      display: inline-block;

      &.selected {
        border-bottom: 2px solid #41b9dd;
      }
    }
  }
}

.create-prescription-details {
  margin: auto;
  margin-top: 15px;
  // background-color: pink;
  justify-content: space-between;
  .col {
    padding: 0;
  }
}
.skip-pres {
  color: white;
  border: none;
  background-color: #1B68DF;
  padding: 3px 10px;
  border-radius: 3px;
  margin: 5px 0;
}

.timer-sty {
  color: black;
  border: none;
  background-color: $ligh-teal-color;
  padding: 3px 10px;
  border-radius: 3px;
  margin: 5px 0;
  width: 120px;
  margin-left: 15px;
}

@media (max-width: 400px) {
  .skip-pres {
    width: 200px;
  }
  .btn-screens {
    flex-direction: column;
    align-items: center;
  }
  .timer-sty {
    margin-left: 0px;
  }
}

.create-pres-modal {
  cursor: pointer;
  background-color: #ffffff;
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  // position: relative;
  margin-bottom: 1rem;
  // padding: 15px 20px;
  h3 {
    // padding: 15px 0;
    font-size: 20px;
    font-weight: 700;
    color: #417b9b;
    margin-bottom: 0px;
    // margin-left: 20px;
  }
  .create-pres-plus--icon {
    // position: absolute;
    // top: 15%;
    // right: 2%;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #1B68DF;
  }
  .react-plus--icon {
    color: #fff !important;
    cursor: pointer;
  }
}

.ant-modal-mask {
  position: static !important;
}

.ant-modal-wrap {
  position: fixed !important;
  top: 0 !important;
  right: 30px !important;
  left: auto;
}

.create-prescription-details .form-label {
  font-weight: 600;
}
.create-prescription-details .form-control {
  border-radius: 10px;
  border: 1px solid #d4d4dd;
}

.full-width {
  max-width: 100% !important;
}

@media (min-width: 768px) {
  .create-prescription .col-md-7 {
    flex: 0 0 auto;
    width: 50%;
  }
  .create-prescription .col-md-5 {
    flex: 0 0 auto;
    width: 40%;
  }
}

.medicines-heading {
  font-weight: 600;
  margin: 10px 0px 15px;
  font-size: 16px;
}
.med-btn {
  background-color: #30536c;
  border: 0;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  padding: 5px 13px;
  letter-spacing: 0.6px;
  margin-right: 15px;
  margin-bottom: 5px;
}
.btn-add {
  background-color: #1B68DF;
  border: 0;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  padding: 5px 13px;
  letter-spacing: 0.3px;
}
.apply-btn {
  background-color: #1B68DF;
  border: 0;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  padding: 5px 13px;
  letter-spacing: 0.3px;
  width: 25%;
}
.del-icon {
  color: #ed1c24;
  cursor: pointer;
}
.pres-table .table {
  border-color: #ffffff !important;
  margin-top: 15px;
  th {
    color: #4e525a;
    letter-spacing: 0.5px;
    background-color: #fff !important;
  }
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border: 0 !important;
  }
  tr:nth-child(1) {
    background-color: #fafafa;
    .form-control {
      background-color: #fafafa;
    }
  }
  tr:nth-child(2) {
    background-color: #fff;
    .form-control {
      background-color: #fff;
    }
  }
  tr {
    color: #4e525a;
    font-size: 14px;
    .form-control {
      //border: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .serial-num {
    font-weight: 600;
    padding: 10px 8px;
  }
  .color-dark {
    color: #1e5e8b !important;
  }
}

.pres-table .table tbody,
.pres-table .table td,
.pres-table .table tfoot,
.pres-table .table th,
.pres-table .table thead,
.pres-table .table tr {
  // padding-top: 0;
  padding-top: 2px;
}
.pres-table tr td input {
  max-width: 100px;
}
.pres-table tr td:nth-child(3) input {
  max-width: 80px;
}
// create prescription page style end
.consultation {
  .tab-row {
    .tab {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.85) !important;
      font-weight: 600;
    }
    .underline {
      background-color: #41b9dd;
      height: 2px;
      border-radius: 10px;
    }
  }
}
