@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
  .dashboard-book-consultation {
    .book-header {
      flex-direction: row;
      min-width: 400px;
      width: 500px;
      padding: 0px !important;
      justify-content: space-around !important;
    }
    .res-back-button {
      align-items: center;
      padding: 0px !important;
      h5 {
        font-size: 14px !important;
        margin: 0px !important;
      }
    }
    .search-section {
      //margin: 0px !important;
      //padding-left: 4px !important;
      padding-right: 0rem !important;

      .group-search {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
      }
    }
    .doctor-section {
      padding: 0px !important;
      margin: 10px !important;
      .section-container {
        padding: 0px !important;
        .top-doctors {
          margin: 0px !important;
          padding: 0px !important;
          .doctor-cards-list .doctor-card .doctor-name {
            font-size: 12px !important;
          }
          .section-header {
            padding: 0px !important;
            margin-top: 0rem;
            //margin-left: 1rem;
          }
        }
      }
    }
    ::-webkit-scrollbar {
      width: 0px !important;
    }
    ::-webkit-scrollbar-thumb {
      background-image: linear-gradient(#bbb, #aaa);
      border-radius: 3px;
    }
    .dropdown-menu.show {
      transform: translate(-172px, 50px) !important;
    }
    .res-card-link {
      flex-direction: column;

      .view-all {
        text-align: center !important;
      }
    }
    .res-card-items {
      flex-wrap: nowrap !important;
    }
    .filter-counter {
      width: 60%;
      margin-left: 1rem;
    }
    .doctor-cards-list {
      margin-top: 10px;
    }
    .filter-selected {
      font-size: 14px;
      padding: 0px;
      .btn{
        padding: 1px !important;
        svg{
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }
  .customer-confirm-consultation {
    padding-left: 15px !important;
    padding-right: 15px !important;
    .backbutton-insight {
      h5 {
        font-size: 14px;
        margin: 0px;
      }
    }
    .doctor-tiles {
      margin: 0px !important;
      margin-top: 10px !important;
      padding: 0px;
      .tiles-container {
        padding: 0px;
      }
    }
    .provide-consultation-info .gender-select-field .mm-radio-button .radio {
      width: 1.5rem;
      height: 1.5rem;
    }
    .neumorphic-input {
      font-size: 12px;
      padding-top: 10px;
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 10px;
      box-shadow: inset 5px 5px 5px #efefef, inset -4px -4px 5px #ffffff;
    }
    .slot-info-btn {
      width: 10rem !important;
    }
  }
  .work-day-item {
    margin-top: 10px;
    margin-bottom: 1.5rem;
    padding: 0px;
  }
  .working-time {
    flex-direction: column;
  }
  .dashboard-book-consultation
    .top-doctors
    .doctor-cards-list
    .doctor-card
    .view-all {
    cursor: pointer;
    position: absolute;
    left: 12rem;
    top: 0px;
    z-index: 99999;
    font-size: 14px !important;
  }
  .info-card-container {
    padding: 0px;
  }
  .provide-consultation-info .voice-record-field .btn {
    font-size: 18px !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .dashboard-book-consultation {
    .filter-selected {
      font-size: 14px;
      padding: 0px;
      .btn{
        padding: 1px !important;
        svg{
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
    .book-header {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
      justify-content: space-between !important;
    }
    .res-back-button {
      .btn {
        font-size: 10px;
      }
    }
    .search-section {
      //margin: 0px !important;
      padding-left: 0rem !important;
      .group-search {
        padding-left: 2rem !important;
      }
    }
    .doctor-section {
      margin: 10px !important;
      padding: 0px !important;
      .section-container {
        padding: 0px !important;
        .top-doctors {
          margin: 0px !important;
          padding: 0px !important;
          .doctor-cards-list .doctor-card .doctor-name {
            font-size: 14px !important;
          }
          .section-header {
            padding-left: 2rem !important;
          }
        }
      }
    }
  }
  .provide-consultation-info .input-field .neumorphic-input {
    width: auto;
  }
  .provide-consultation-info .input-field label {
    width: 42%;
  }
  .provide-consultation-info .voice-record-field .btn {
    font-size: 18px !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .dashboard-book-consultation {
    .filter-counter {
      width: 100%;
    }
    .filter-selected {
      font-size: 14px;
      padding: 0px;
      .btn{
        padding: 1px !important;
        svg{
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }
}
