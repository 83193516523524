@media (max-width: 767px) {
  .landing-base-layout {
    background-color: $blue-background;
    width: 100vw;
    // height: 100vh;
    position: relative;
    // overflow-x: hidden;
    // overflow-y: scroll;

    .video-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .tolldiv {
        margin: 0px;
      }
    }

    .top-right-circle-base,
    .bottom-left-circle-base {
      position: fixed;
      z-index: 0;
    }
    .top-right-circle-ring,
    .bottom-left-circle-ring {
      position: fixed;
      z-index: 0;
    }

    .layout-container {
      width: 100vw;
      height: auto;
      max-width: 100vw;
      overflow-y: scroll;
      overflow-x: hidden;
      margin: 0px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // flex-direction: column;
      // padding-top: 4rem;
      padding-top: 2rem;
    }

    .welcome-text {
      color: $grey-text-color;
      margin: 30px 0 10px;
      // padding-top: 20px;
    }

    .top-right-circle-base,
    .bottom-left-circle-base {
      position: fixed;
      width: $background-circles-resp-size;
      height: $background-circles-resp-size;
      border-radius: 50%;
      z-index: 0;
    }
    .top-right-circle-ring,
    .bottom-left-circle-ring {
      position: fixed;
      width: $background-circles-ring-resp-size;
      height: $background-circles-ring-resp-size;
      border-radius: 50%;
      z-index: 0;
    }
    .top-right-circle-ring {
      background-color: $top-right-ring-circle-color;
      top: -100px;
      right: -100px;
    }

    .bottom-left-circle-ring {
      background-color: $bottom-left-ring-circle-color;
      bottom: -100px;
      left: -100px;
    }
    .top-right-circle-base {
      background-color: $top-right-base-circle-color;
      top: -100px;
      right: -100px;
    }

    .bottom-left-circle-base {
      background-color: $bottom-left-base-circle-color;
      bottom: -100px;
      left: -100px;
    }

    .logo-m {
      width: 200px;
    }

    .logo {
      width: 250px;
    }
    .align-quick-launch {
      width: 50rem;
    }
    .modal-dialog-landing {
      max-width: 100% !important;
    }
    .landing-base-layout-card {
      // padding-top: 2rem;
      padding-top: 0.5rem;
      padding-bottom: 3rem;
      background-image: linear-gradient(to top left, #f2f4fc0d, #ffffff2e);
      // width: 45vw;
      border-radius: 10px;
      //   box-shadow: 5px 5px 10px #eee;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 70%;

      .auth-buttons {
        .auth-button {
          border: none;
          width: 4rem;
          height: 4rem;
          background-color: $grey-background;
          border: none;
          border-radius: 2rem;
          box-shadow: 5px 5px 10px #ddd;

          .fab {
            font-size: 1.5rem;
          }

          .fa-facebook-f,
          .fa-twitter,
          .fa-google {
            color: $izi-logo-color;
          }
        }
      }

      ::placeholder {
        color: $grey-text-color;
        font-weight: 600;
      }
    }
  }
  .tolldiv {
    background-image: url(https://devmindminastorage.blob.core.windows.net/image/Theme/mobiletheme.png);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 47.5vw;
    height: 98.2vw;
    padding-top: 4.5vw;
    padding-left: 1.9vw;
    margin-top: 10%;
    margin-left: 20%;
  }
  .tollframe {
    width: 43.5vw;
    height: 88vw;
    border-radius: 16px;
    position: absolute;
  }
  .tollframe-mp {
    width: 40.5vw;
    height: 87.8vw !important;
    border-radius: 16px;
    position: absolute;
    margin-left: 0.3rem;
  }
  .welcome-header {
    font-size: 1.2rem;
    margin-top: 0.5rem;
    text-align: center;
    color: white;
  }
  .back-button-landing {
    position: absolute;
    top: 0px;
    font-size: 25px;
  }
  .btn-white {
    background-color: white;
    box-shadow: 0px 0px 20px lighten(blue, 15%);
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-radius: 50px;
    color: darkblue;
    margin-top: 1rem;
    font-size: 1rem;
  }
  .btn-white-filter {
    background-color: white;
    box-shadow: 0px 0px 20px lighten(blue, 15%);
    border-radius: 50px;
    width:4rem;
    height:2rem;
    color: darkblue;

    font-size: 1rem;
  }
  .btn-white:hover {
    background-color: rgb(225, 238, 252);
  }
  .setone {
    color: white;
    padding: 1rem;
    margin-top: 1rem;
  }
  .setone-header {
    color: white;
    font-size: 1.2rem;
  }
  ul {
    list-style-type: none;
  }
  .set-list-design {
    font-size: 1rem;
    // min-width: 20rem;
  }
  .para-text {
    text-align: justify;
    font-size: 0.7rem;
    // min-width: 18rem;
  }
  //  .setmaxdeviceheight{
  //   max-height: auto !important;
  //   overflow-y:none !important;
  //   }
  .review-text {
    font-size: 0.7rem;
  }
  .name-header {
    margin-top: 10px;
    font-size: 1rem;
  }
  .location-header {
    font-size: 0.8rem;
  }
  .review-description {
    margin-left: 0px;
    color: #186be8;
    min-width: 10rem;
  }
  .review-image-card {
    position: absolute;
    right: 10%;
    justify-content: center;
    align-items: center;
    padding: 0px;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin-bottom: 18rem !important;
    margin-right: 20px;
  }
  .render-card {
    max-width: 30rem;
    width: 30rem;
    min-width: 0rem;
    margin-right: 0.5rem;
  }
  .set-review-image {
    object-fit: cover;
    width: 4.5rem;
    height: 5rem;
    background-repeat: repeat-y;
  }
  .interested-button {
    font-size: 1rem !important;
    color: #2875ea;
    font-weight: 500;
  }
  .rating-adjust {
    margin-right: 0rem;
  }
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
    padding: 1rem;
    width: 20rem;

    & > [class^="col-"],
    & > [class*=" col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .carousel-align {
    width: 18rem;
    min-width: 18rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .meal-plan-landing {
    .FooterStoreButtons {
      width: 10rem;
    }
  }

  .tolldiv {
    background-image: url(https://devmindminastorage.blob.core.windows.net/image/Theme/mobiletheme.png);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 35.5vw;
    height: 71.2vw;
    padding-top: 3vw;
    padding-left: 1.8vw;
    margin-top: 3%;
    margin-left: 25%;
  }
  .tollframe-mp {
    width: 22.5vw !important;
    height: 66vw !important;
    border-radius: 16px;
    position: absolute;
    margin-left: 0.3rem;
  }
  .set-list-design {
    font-size: 1.4rem;
    // min-width: 27rem;
  }
  .welcome-header {
    font-size: 2rem;
    margin-top: 0.5rem;
    text-align: center;
    color: white;
  }
  .render-card {
    min-width: 25rem;
    width: 30rem;
  }
  .tollframe {
    width: 31.5vw;
    height: 66vw;
    border-radius: 16px;
    position: absolute;
  }
  .review-image-card {
    position: absolute;
    right: 10%;
    justify-content: center;
    align-items: center;
    padding: 0px;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin-bottom: 17rem !important;
    margin-right: 20px;
  }
  .set-review-image {
    object-fit: cover;
    width: 4.5rem;
    height: 6rem;
    background-repeat: repeat-y;
  }
  .interested-button {
    font-size: 1.3rem !important;
    color: #2875ea;
    font-weight: 500;
  }
  .rating-adjust {
    margin-right: 1rem;
  }
  .carousel-align {
    width: 28rem;
    min-width: 28rem;
  }
}
