.live-chat-container {
  font-family: "Montserrat";
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .live-chat-layout {
    width: calc(100% - 15%) !important;
    margin: 10px 0px 20px 20px;
    padding: 20px 20px 20px 20px;
    background: #fff;
    border-radius: 2%;
    border: 1px solid #ddd;
    .headings {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      padding: 5px;
      margin-bottom: 10px;
      .settings {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          font-size: 30px;
          color: #1B68DF;
          margin: 2px;
        }
      }
      .settings {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          font-size: 30px;
          color: #1B68DF;
          margin: 2px;
        }
      }
    }
    .split-screen {
      display: flex;
      .is-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .left-window {
        display: flex;
        flex-direction: column;
        width: 50%;
        max-width: 50%;
        margin: 10px;
        border-right: 8px solid #ccc;
        label {
          text-transform: capitalize;
          font-weight: 500;
        }
        .search-box {
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 10px;
          svg {
            position: relative;
            left: 25px;
            font-size: 20px;
          }
        }
        input {
          width: 80%;
          text-align: center;
          height: 36px;
          outline: none;
          font-size: 14px;
          text-overflow: ellipsis;
          line-height: 36px;
          -webkit-appearance: none;
          display: flex;
          align-items: center;
          border-radius: 10px;
        }
        .nw-matches-grp {
          gap: 0px;
          overflow-y: hidden !important;
          height: 85px;
          margin-bottom: 15px;
          .profile-container {
            width: 72px;
            height: 85px;
            display: flex;
            flex-direction: column;
            .nm-profile {
              position: relative;
              .profile-btn {
                border: none;
                border-radius: 50%;
                margin-top: 4px !important;
                margin-left: 0px;
                width: 50px;
                height: 50px;
              }
            }
            .online-status {
              width: 15px;
              height: 15px;
              position: absolute;
              border-radius: 52%;
              left: 40px;
              top: 20px;
              border: 1px solid #fff;
            }
            .pc-name {
              display: inline-block;
              margin-bottom: 0.5rem;
              overflow: hidden;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              width: 80px;
            }
          }
        }

        .grp-matches {
          display: flex;
          button {
            border: none;
            margin: 2px;
          }
          .profile-btn img {
            margin-left: 8px;
          }
        }
        .grp-messages {
          height: 375px;
          overflow-x: hidden;
          .profile-details {
            display: flex;
            margin-bottom: 20px;
            border-bottom: 1px solid #ddd;
            margin-top: 20px;
            cursor: pointer;
            .profile-container {
              position: relative;
              .profile-btn {
                border: none;
                border-radius: 50%;
                margin-top: 4px !important;
                margin-left: 0px;
                width: 50px;
                height: 50px;
              }
              .online-status {
                width: 15px;
                height: 15px;
                position: absolute;
                border-radius: 50%;
                left: 40px;
                top: 20px;
                border: 1px solid #fff;
              }
            }

            .user-details {
              display: flex;
              margin-left: 10px;
              flex-direction: column;
              .last-message {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 25rem;
              }
              .message-highlight {
                font-weight: bold;
              }
            }
          }
          .profile-details:hover {
            background: #ddd;
          }
        }
      }
      .left-window {
        width: 50%;
        .chat-header {
          display: flex;
        }
      }
      .right-window {
        width: 50%;
        max-width: 50%;

        .chat-header {
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #ddd;
          margin: 0px 10px 10px 10px;
          .user-profile {
            display: flex;
            align-items: center;
            .profile-container {
              position: relative;
              margin-right: 10px;
              .profile-btn {
                border: none;
                border-radius: 50%;
                margin-top: 4px !important;
                margin-left: 0px;
                width: 50px;
                height: 50px;
              }
              .online-status {
                width: 15px;
                height: 15px;
                position: absolute;
                border-radius: 50%;
                left: 38px;
                top: 20px;
                border: 1px solid #fff;
              }
            }
          }
          svg {
            color: #1B68DF;
          }
        }
        .chat-body {
          height: 460px;
          overflow-x: hidden;
          .sent-msg--block {
            display: flex;
            justify-content: flex-end;
            margin: 7px 0;
          }
          .sent-msg {
            //width: 65%;
            min-width: 160px;
            background: #1B68DF;
            position: relative;
            display: block;
            color: white;
            font-weight: 500;
            border-radius: 6px;
            line-height: 1.3;
            align-items: right;
            padding: 15px 20px;
            font-size: 12px;
            border-bottom-right-radius: 0px;
          }
          .recived-message {
            position: relative;
            display: block;
            color: #a3a5b6;
            font-weight: 500;
            border-radius: 6px;
            line-height: 1.3;
            align-items: right;
            padding: 6px 9px;
            font-size: 12px;
            border-bottom-left-radius: 0px;
            .bubble {
              min-width: 170px;
              max-width: fit-content;
              height: auto;
              display: flex;
              background: #f5f5f5;
              border-radius: 4px;
              position: relative;
              margin: 0 0 25px;
              padding: 8px;
              &.alt {
                margin: 0 0 0 60px;
              }
              .txt {
                max-width: 200px;
                padding: 8px 55px 8px 14px;
                .name {
                  font-weight: 600;
                  font-size: 12px;
                  margin: 0 0 4px;
                  color: #3498db;
                  span {
                    font-weight: normal;
                    color: #b3b3b3;
                  }
                  &.alt {
                    color: #2ecc71;
                  }
                }
                .message {
                  font-size: 12px;
                  margin: 0;
                  color: #2b2b2b;
                }
                .timestamp {
                  font-size: 8px;
                  position: absolute;
                  bottom: 8px;
                  right: 10px;
                  text-transform: uppercase;
                  color: #999;
                }
              }
              .bubble-arrow {
                position: absolute;
                width: 0;
                bottom: 42px;
                left: -16px;
                height: 0;
                &.alt {
                  right: -2px;
                  bottom: 40px;
                  left: auto;
                }
              }
              .bubble-arrow:after {
                content: "";
                position: absolute;
                border: 0 solid transparent;
                border-top: 9px solid #f5f5f5;
                border-radius: 0 20px 0;
                width: 15px;
                height: 30px;
                transform: rotate(145deg);
              }
              .bubble-arrow.alt:after {
                transform: rotate(45deg) scaleY(-1);
              }
            }
          }
        }
        .sent-msg--input {
          position: relative;
          margin-top: auto;

          .form-grp {
            position: relative;
            border-radius: 8px;
            padding: 1px;
            display: flex;
            margin: 30px 0px 0px 20px;
            background: none;
            border-bottom: 1px solid;
            justify-content: flex-start;
            width: 100%;
            .gif {
              display: flex;
              align-items: center;
              color: #1B68DF;
              font-size: 25px;
            }
          }

          .form-control {
            width: 80%;
            border: 0 !important;
            margin: 2px;
            padding-right: 30px;
          }
        }
      }
    }
  }
  .right-messages {
    display: none;
  }
  .pagination{
    justify-content: center;
  }
  .pagination > li {
    display: inline-block;
    padding-left: 0;
  }
  .pagination > li {
    list-style: none;
    border: 0.9px solid;
  }
  .pagination > li > a,
  .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #2c689c;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
  }

  .pagination > li.active > a {
    color: #fff;
    background-color:#1B68DF;
    border-color: #1B68DF;
  }

  /* Style the active class (and buttons on mouse-over) */
  .pagination > li > a:hover {
    background-color: #bfc4cc;
    color: white;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    padding: 0px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    display: none !important;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    margin-right: 0;
    padding: 0px !important;
    border-top-right-radius: 4px;
    display: none !important;
  }
}
