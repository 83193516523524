.home-top-online-doctors {
    .list-container {
        overflow-x: hidden;
        width: 100%;

        .loading-container {
            width: 100px;
        }
    }
}

@media (max-width: 600px) {
    .home-top-online-doctors {

        .scroll-snap-parent-x {
            scroll-snap-type: block;
        }

        .list-container {
            overflow-x: scroll;
        }

        .list-container::-webkit-scrollbar {
            height: 5px !important;
        }

        /* Track */
        .list-container::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        .list-container::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        .list-container::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .scrollBtn {
            display: none;
        }
    }
}
