.view-recipe-details {
  .view-recipe-card {
    background-color: #ffffff91;
    margin: 1rem 1rem;
    border-radius: 20px;

    .rcp-image {
      width: 100%;
      max-width: 500px;
      aspect-ratio: 4 / 3;
      object-fit: cover;
      border-radius: 20px;
    }

    .cooking-prep-time {
      display: flex;
      align-items: center;

      .cook-time,
      .prep-time {
        flex: 1;
        display: flex;
        align-items: flex-start;

        .icon {
          margin-right: 0.7rem;
          color: $izi-logo-color;
        }
      }
    }

    .unit-quantity {
      display: flex;
      align-items: center;
      gap: 1rem;

      .number-input {
        .ant-input-number {
          width: 140px;
          border-radius: 20px;
        }
      }
    }

    .detailed-nutritions-btn {
      border: none;
      border-radius: 10px;
      box-shadow: $box-shadow;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      height: 50px;

      .icon {
        background-color: $greenish-color;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        min-width: 1.5rem;
        min-height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $izi-logo-color;
      }
    }

    .view-recipe-ingredients {
      .ingredients-list {
        height: 200px;
        max-height: 200px;
        overflow-y: scroll;
      }
    }

    .view-recipe-cooking-instructions {
      .instructions-list {
        height: 300px;
        max-height: 300px;
        overflow-y: scroll;
        padding: 1rem 0rem;
      }
    }
  }
}

.recipe-nutritions-info-drawer {
  .ant-drawer-content-wrapper {
    width: 550px;

    .nutrition-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nutrition-name {
        flex: 4;
      }

      .nutrition-weight {
        flex: 2;
      }

      .nutrition-percentage {
        flex: 1;
      }
    }
  }
}

@media (max-width: 560px) {
  .recipe-nutritions-info-drawer {
    .ant-drawer-content-wrapper {
      width: 100%;
    }
  }
}
