.upload-prescription {
  // .upload-container {
  //   padding: 1rem;
  // }
  // .presc-container {
  // }
  .remove-button {
    margin-right: 20px;
    position: relative;
    float: right;
    display: flex;
    color: #1B68DF;
    font-weight: bold;
    cursor: pointer;
    justify-content: center;
    align-items: baseline;
    .circle {
      margin-left: 5px;
      border: 2px solid #1B68DF;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .cont {
    width: 100%;
    height: 500px;
    padding: 0.5rem;
    border: 1px solid;
    border-radius: 12px;
  }
  .dropzone {
    background: #fff;
    box-shadow: none;
    position: relative;
    width: 100%;
    border-radius: 1rem;
    height: 310px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .react-pdf__Page {
    position: relative;
    display: flex;
    // padding: 1rem;
    width: 100%;
    height: 100%;
  }
  .react-pdf__Page__canvas {
    width: auto !important;
    height: auto !important;
    max-width: 100%;
  }
  .react-pdf__Page__textContent {
    display: none;
  }
  .btn-groups {
    display: flex;
    flex-direction: row-reverse;
    .cancel,
    .confirm {
      border: none;
      border-radius: 20px;
      color: #fff;
      width: 250px;
      height: 35px;
      font-weight: bolder;
    }
    .confirm {
      background: rgb(63, 131, 236);
    }
    .confirm:disabled {
      cursor: no-drop;
    }
    .cancel {
      background: gray;
    }
  }
  .notes-patient {
    height: 150px;
    width: 100%;
    resize: none;
    border-radius: 10px;
  }
}
