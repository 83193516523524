.permissions{
    &-heading{
        margin-top: 30px;
    }
}

.ant-tabs-ink-bar{
    background-color: black !important;
}

.ant-tabs-tab-btn, .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #a9a9aa;
}

.permissions .ant-table-thead > tr > th{
    color: #878787;
}

.permissions .ant-table-tbody > tr > td{
    font-weight: 400;
    color: #bbbbbb;
}