.corporates{

    font-family: "Montserrat", sans-serif;

    &-heading{
        margin: 10px 0;
        font-weight: 600;
        font-size: 19px;
    }

    .onboard-clients{
        background-color: white;
        border-radius: 10px;
        margin: 0;
        &--heading{
            margin-top: 15px;
            font-size: 18px;
        }
        .corporate-inputs{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 0;
            label{
                font-weight: 600;
            }
        }
        .onboard-clients-col2{
            display: flex;
            flex-direction: column;
            // padding: 0 62px;
            padding: 0 4%;
        }
        .onboard-clients-col3{
            display: flex;
            flex-direction: column;

            label{
                font-weight: 600;
            }
        }
    }
}

@media (max-width: 768px){
    .onboard-clients-col2{
        padding: 0px 15px !important;
    }
    .onboard-clients-col2 , .onboard-clients-col3{
        margin-top: 15px;
    }
}

@media (max-width: 500px){
    .corporates .onboard-clients .corporate-inputs{
        flex-direction: column;
    }
}

@media (max-width: 1200px){
    .corporate-input-fields{
        width: 200px !important;
    }
}

.corporate-checkbox{
    display: flex;
    justify-content: space-between;
}

.corporate-input-fields{
    border: none;
    outline: none;
    padding-top: 14px;
    padding-right: 10px;
    padding-left: 20px;
    padding-bottom: 11px;
    border-radius: 15px;
    font-size: 12px;
    // width: 240px;
    width: 260px;
    box-shadow: inset 8px 8px 8px #efefef, inset -8px -8px 8px #ffffff;
}

.random-id{
        margin-top: 15px;
        width: 100%;
}

.gen-corporate-btn{
    border: none;
    background-color: #1B68DF;
    color: #fff;
    border-radius: 3px;
    padding: 5px;
}

.all-client-heading{
    margin: 10px 0;
    font-weight: 600;
    font-size: 19px;
    margin-top: 30px;
}

.submit-corporate-btn-block{
    text-align: right;
}


.submit-corporate-btn{
    border: none;
    background-color: #1B68DF;
    color: #fff;
    border-radius: 3px;
    padding: 5px;
    width: 60%;
    margin-top: 35px;
    margin-bottom: 20px;
}


.add-file{
    margin-top: 15px;
    height: 80px;
    position: relative;
    border: 1px solid #eeeff0;
    padding: 5px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &-corp{
        color: #3F81EC;
        font-weight: 500;
        margin-bottom: 0;
        font-size: 13px;
    }
    &-drop{
        color: #acb0b4;
        font-weight: 500;
        margin-bottom: 0;
        font-size: 13px;
    }
    .paper-pos{
        position: absolute;
        bottom: 6px;
        right: 5px;
    }
}


