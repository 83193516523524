.recipe_inputs  {
    justify-content: space-between;

    .ant-form-item-label,
    .ant-form-item-control {
        width: 50%;
        flex: none;
        // flex-grow: 1;
        // flex-shrink: 1;
    }
    .ant-form-large .ant-form-item-control-input {
        min-height: 40px;
    }
}