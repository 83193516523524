.landing-base-layout {
  background-color: $blue-background;
  width: 100vw;
  // height: 100vh;
  position: relative;
  // overflow-x: hidden;
  // overflow-y: scroll;

  .top-right-circle-base,
  .bottom-left-circle-base {
    position: fixed;
    z-index: 1;
  }
  .top-right-circle-ring,
  .bottom-left-circle-ring {
    position: fixed;
    z-index: 1;
  }

  .layout-container {
    width: 100vw;
    height: auto;
    max-width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    // padding-top: 4rem;
    padding-top: 2rem;
  }

  .welcome-text {
    color: $grey-text-color;
    margin: 30px 0 10px;
    // padding-top: 20px;
  }

  .top-right-circle-base,
  .bottom-left-circle-base {
    position: fixed;
    width: $background-circles-size;
    height: $background-circles-size;
    border-radius: 50%;
    z-index: 0;
  }
  .top-right-circle-ring,
  .bottom-left-circle-ring {
    position: fixed;
    width: $background-circles-ring-size;
    height: $background-circles-ring-size;
    border-radius: 50%;
    z-index: 0;
  }
  .top-right-circle-ring {
    background-color: $top-right-ring-circle-color;
    top: -200px;
    right: -200px;
  }

  .bottom-left-circle-ring {
    background-color: $bottom-left-ring-circle-color;
    bottom: -200px;
    left: -200px;
  }
  .top-right-circle-base {
    background-color: $top-right-base-circle-color;
    top: -200px;
    right: -200px;
  }

  .bottom-left-circle-base {
    background-color: $bottom-left-base-circle-color;
    bottom: -200px;
    left: -200px;
  }

  .logo-m {
    width: 200px;
  }

  .logo {
    width: 250px;
  }
  .align-quick-launch {
    width: 50rem;
  }
  .modal-dialog-landing {
    max-width: 100% !important;
  }
  .landing-base-layout-card {
    // padding-top: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 3rem;
    background-image: linear-gradient(to top left, #f2f4fc0d, #ffffff2e);
    // width: 45vw;
    border-radius: 10px;
    //   box-shadow: 5px 5px 10px #eee;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 70%;

    .auth-buttons {
      .auth-button {
        border: none;
        width: 4rem;
        height: 4rem;
        background-color: $grey-background;
        border: none;
        border-radius: 2rem;
        box-shadow: 5px 5px 10px #ddd;

        .fab {
          font-size: 1.5rem;
        }

        .fa-facebook-f,
        .fa-twitter,
        .fa-google {
          color: $izi-logo-color;
        }
      }
    }

    ::placeholder {
      color: $grey-text-color;
      font-weight: 600;
    }
  }
}
.tolldiv {
  background-image: url(https://devmindminastorage.blob.core.windows.net/image/Theme/mobiletheme.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 16.5vw;
  height: 37.2vw;
  padding-top: 1.5vw;
  padding-left: 0.9vw;
  margin-top: 3%;
}
.tollframe-mp {
    width: 13.5vw;
    height: 30vw;
    border-radius: 20px;
    position: absolute;
    margin-left: 0.5rem;
}
.welcome-header {
  font-size: 1.8rem;
  margin-top: 0.5rem;
  text-align: center;
  color: white;
}
.back-button-landing {
  position: absolute;
  top: 35px;
  font-size: 25px;
}
.btn-white-filter {
  background-color: white;
  box-shadow: 0px 0px 20px lighten(blue, 15%);
  border-radius: 50px;
  width:4rem;
  color: darkblue;
  height:3rem;
  font-size: 1rem;
}
.btn-white {
  background-color: white;
  box-shadow: 0px 0px 20px lighten(blue, 15%);
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-radius: 50px;
  color: darkblue;
  font-size: 1rem;
}
.btn-white:hover {
  background-color: rgb(225, 238, 252);
}
.setone {
  color: white;
  padding: 1rem;
  margin-top: 1rem;
}
.setone-header {
  color: white;
  font-size: 1.8rem;
}
ul {
  list-style-type: none;
}
.set-list-design {
  font-size: 1.5rem;
  // min-width: 20rem;
}
.para-text {
  text-align: justify;
  font-size: 1.2rem;
  // min-width: 18rem;
}
.carousel-align {
  width: 28rem;
  min-width: 28rem;
}
.render-card {
  min-width: 25rem;
  width: 30rem;
  height: 16rem;
}
.review-image-card {
  position: absolute;
  right: 20%;
  justify-content: center;
  align-items: center;
  padding: 0px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 17rem !important;
  margin-right: 20px;
}
.set-review-image {
  object-fit: cover;
  width: 5.5rem;
  height: 7rem;
  background-repeat: repeat-y;
}
.review-description {
  margin-left: 10px;
  color: #186be8;
  min-width: 25rem;
}
.interested-button {
  font-size: 1.4rem;
  color: #2875ea;
  font-weight: 500;
}
.rating-adjust {
  margin-right: 1rem;
}
.name-header {
  font-size: 1.5rem;
}
.location-header {
  font-size: 1rem;
}
