.clinic{
    .card-color{
        background-color:#ddf9ea;
    }
    .pending-card{
        font-family: 'Montserrat';
        .confirmed{
            color:#4bb770 !important;
            text-align: center;
            //align-self: flex-start;
            margin-bottom: 0;
        }
        .pending{
            color:#ff1e1e !important;
            text-align: center;
            //align-self: flex-start;
            margin-bottom: 0;
        }   
        .patient-col1{
            min-width: 180px !important;
        }
        .heading{
            color:#000 !important;
            font-size:14px !important;
            font-weight: 500 !important;
            margin-bottom:0;
        }
        .date{
            min-width: 100px;
        }
        .light-heading{
            font-size:14px !important;
            font-weight: 500 !important;
            margin-bottom:0;
        }
        .title{
            font-size: 20px;
            font-weight: 400;
            color:#000;
        }
        .sub-title{
            font-weight: 500 !important;
            color:#0f947c !important;
            font-size: 12px !important;
            min-width: 180px !important;
        }
        .pending-clock{
            color:#2b607d;
        }
        .accept-btn {
            border: none;
            color: #fff;
            border: none;
            font-size: 14px;
            width: 90px;
            border-radius: 15px;
            padding: 3px;
            margin-right: 10px;
            background-color:#1B68DF !important;
            height: min-content !important;
        }
        .reject-btn{
            border: none;
            color: #fff;
            border: none;
            font-size: 14px;
            width: 90px;
            border-radius: 15px;
            padding: 3px;
            margin-right: 10px;
            background-color:#b9552a !important;
            height: min-content !important;
        }
        .group{
            display:flex;
            min-width: 150px;
        }
        .verify-heading{
            color:#2b5689;
            font-weight:500;
        }
        .radio{
            width: 2rem !important;
            height: 2rem !important;
            cursor: pointer;
        }
        .mm-radio-button{
            min-width: 150px;
        }
        .field-min-width{
            min-width: 200px;
        }
    }
    
    .min-card-width{
        width:100%;
        height: 100%;
        min-height: min-content;
    }
    .left{
        position:absolute;
        font-size: 18px;
        top:20px;
        left:20px;
    }
    .right{
        position:absolute;
        font-size: 18px;
        top:20px;
        right:20px;
    }
    .right-bottom{
        position:absolute;
        font-size: 18px;
        bottom:20px;
        right:20px;
    }
    .highlight-fees{
        color:#1B68DF !important;
      }
      .no-insurance {
        color:#750007;
        font-weight: 600;
        font-style: italic;
        font-size: 15px;
      }
}