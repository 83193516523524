.customer-confirm-consultation {
  & > * {
    font-size: 14px !important;
    color: #000 !important;
  }
  ::-webkit-scrollbar {
    width: 0px !important;
  }
  .doctor-tiles {
    .full-payment,
    .is-insured {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
    }
    .total-amount {
      display: flex;
      padding: 0px;
      font-weight: 500;
      label {
        padding: 0px;
      }
    }
  }
  .doctor-info-card {
    .quality {
      color: #007bff;
      font-size: 1.2rem;
    }
    .fal {
      font-size: 3rem;
    }

    .doctor-patients {
      display: flex;
    }

    .title {
      font-weight: 600;
      font-size: 1rem;
    }

    .experience-years {
      display: flex;
      flex-direction: column;
    }

    .about-doctor-title,
    .working-time-title {
      margin-top: 2rem;
    }

    .about-doctor {
      .about-doctor-text {
        width: 75%;
      }

      .far {
        font-size: 2rem;
        margin: 0px auto;
      }
    }

    .month-select {
      border: none;
      font-weight: 600;
    }

    .work-day-item {
      width: 6rem;
      height: 6rem;
      border-radius: 5px;
      background-color: $izi-logo-color;
      // padding: 0.25rem;
      margin-right: 1rem;
      background-color: $darkBlueColor;
      cursor: pointer;
      min-width: 6rem;
      &.selected {
        background-color: $izi-logo-color;

        .new-content {
          color: $izi-logo-color;
          border: 1px solid $izi-logo-color;
        }
      }
      .cap {
        height: 20%;
        width: 100%;
      }
      .new-content {
        color: $darkBlueColor;
        border: 1px solid $darkBlueColor;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80%;
        width: 100%;
        background-color: white;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        font-weight: 600;
        font-size: 18px;
      }
      /*.content {
        color: $darkBlueColor;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80%;
        width: 100%;
        background-color: white;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        font-weight: 600;
        font-size: 18px;
      }*/
    }
    .day-type {
      display: flex;
      gap: 10px;
      .head {
        color: #477da4 !important;
        font-weight: 600;
      }
      .far {
        color: #1B68DF !important;
      }
    }
    .time-slots {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;

      .time-slot-item {
        // padding: 1rem 3rem;
        padding: 12px 40px !important;
        border-radius: 5px;
        margin: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: "Montserrat", sans-serif;
        cursor: pointer;
        background-color: #009556;
        font-weight: 500;
        color: white;

        &.selected {
          // color: white;
          background-color: $izi-logo-color;
        }
      }

      .day-slots {
        background: #fdfeff;
    border: 2px solid #1B68DF;
    color: #000000;
    border-radius: 50px;
    text-align: center;
        padding: 7px 5px;
        font-size: .7rem;
        font-weight: normal;
        cursor: pointer;
        white-space: nowrap;
        height: max-content;
        &.not-available {
          background: #f2f2f2;
          border: 2px solid #77d2fa;
          color: #77d2fa;
        }
        &.selected {
          background: #1B68DF;
          // border: 2px solid #77d2fa;
          color: #fff;
        }
      }
    }
  }

  .appointment-details-card {
    .appointment-date {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
    }

    .day-time {
      display: flex;
      justify-content: space-between;

      .day-time-item {
        width: 48%;
        background-color: lighten($color: $izi-logo-color, $amount: 20);
        border-radius: 10px;
        color: rgb(238, 238, 238);
        padding: 0.5rem 0.6rem;
        display: flex;
        align-items: center;

        &.selected {
          background-color: $izi-logo-color;
          .icon-container {
            background-color: white;

            .fas,
            .fal {
              color: $izi-logo-color;
            }
          }
        }

        .icon-container {
          background-color: rgb(238, 238, 238);
          padding: 0.2rem;
          border-radius: 0.5rem;
          margin-right: 0.5rem;

          .fas,
          .fal {
            font-size: 1.5rem;
            color: grey;
          }
        }
      }
    }

    .allocated-slot {
      display: flex;
      align-items: center;

      .text {
        font-weight: 600;
        margin-bottom: 0px;
      }

      .value {
        background-color: lighten($color: $izi-logo-color, $amount: 30);
        height: 3rem;
        width: 7rem;
        font-weight: 500;
        border-radius: 5px;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .appointment-feature-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(214, 214, 214);
        width: 2rem;
        height: 2rem;
        border-radius: 5px;
        color: $izi-logo-color;
        margin-right: 1rem;
      }

      .feature-name {
        margin-bottom: 0px;
        font-weight: 600;
      }

      .feature-desc {
        font-size: 0.8rem;
        margin-bottom: 0rem;
      }

      &.selected {
        background-color: #8fc9fe;
      }
    }

    .slot-time-details {
      font-size: 16px;
      font-weight: 600;
      margin-top: 2rem;
      color: #000 !important;
    }
  }

  .btn-primary {
    box-shadow: none;
    border-radius: 10px;
    width: 15rem;
    height: 3rem;
  }
  .tab-row {
    .tab {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.85) !important;
      font-weight: 600;
    }
    .underline {
      background-color: #41b9dd;
      height: 2px;
      border-radius: 10px;
    }
  }
  .slot-info-btn {
    border-radius: 50px !important;
    height: 3rem !important;
    margin: 0px !important;
    width: 200px !important;
    padding: 0 30px;
  }
  .disagree-btn{
    background-color: gray !important;
    cursor: no-drop;
    }
    
}
