.dashboard-book-consultation {
  font-family: "Montserrat" !important;
  position: relative;

  #total-appointments-chart {
    height: 100px !important;
    width: 100px !important;
  }
  .total-appointments {
    flex-basis: 45%;
    min-width: 16rem;

    .total-appointments-card {
      .appointment-number {
        margin: 0rem 1rem;
      }
      .complete-number {
        color: $purble-color;
      }
    }
  }

  .doctor-categories {
    width: 52%;

    .doctor-category-item {
      background-color: white;
      padding: 0.5rem 0.5rem;
      border-radius: 10px;
      font-size: 0.8rem;
      width: 6.3rem;
      aspect-ratio: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .category-img {
        margin-bottom: 0.5rem;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .top-doctors {
    .doctor-cards-list {
      display: flex;
      flex-direction: column;
      //grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
      grid-gap: 1rem 1rem;

      .doctor-card {
        display: flex;
        width: 100%;
        &.homelist {
          flex-direction: column;
          align-items: center;
          max-width: 18rem;
        }
        /* &:not(.homelist){
        justify-content: center;
        align-items: center;
        flex-direction: column;
       }*/
        p {
          margin-bottom: 0px;
        }

        .doctor-name {
          // width  margin-top: 0.5rem;
          font-weight: 500;
          color: #022360;
          font-size: .8rem;
        }
        .top-doc-style{
          font-size: .6rem;
        }
        .doctor-speciality-icon {
          color: #477da4 !important;
        }
        .doctor-speciality {
          color: #477da4;
          font-weight: 500;
          font-size: 0.8rem;
        }

        /* .fas {
          color: orange;
        } */

        .doctor-img {
          width: 5rem;
          height: 5rem;
          object-fit: cover;
          border-radius: 50%;
          box-shadow: 0px 0px 5px #ccc;
        }

        .btn-primary {
          box-shadow: none;
          border-radius: 10px;
          width: 75%;
        }
        .price {
          white-space: nowrap;
        }
        .rate {
          font-size: 12px;
          font-weight: bold;
        }
        .exp {
          font-size: 14px;
          font-weight: 500;
        }
        .card-link {
          white-space: nowrap;
          color: #1B68DF;
          font-weight: normal;
          font-size: .8rem;
          @media (max-width: 992px) {
          }
        }
        .day-card {
          //width: 160px;
          max-width: 160px;
          width: 100%;
          padding: 5px 10px;
          border-radius: 50px;
          border: 1px solid #ddd;
          color: rgba(0, 0, 0, 0.85);
          font-weight: normal;
          font-size: .8rem;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
          cursor: pointer;
          min-width: max-content;
          &.active {
            background-color: #1B68DF;
            color: #fff;
            border: none;
          }
        }
        .day-slots {
          background: #fdfeff;
    border: 2px solid #1B68DF;
    color: #000000;
    border-radius: 50px;
    text-align: center;
          padding: 7px 5px;
          font-size: .7rem;
          font-weight: normal;
          cursor: pointer;
          white-space: nowrap;
          height: max-content;
          min-width: max-content;
          &.selected {
            background: #f2f2f2;
            border: 2px solid #77d2fa;
            color: #77d2fa;
          }
        }
        .consultation {
          color: #477da4;
          font-weight: 500;
          font-size: 16px;
          .fas {
            color: #1B68DF;
          }
        }
        .confirm {
          background: #2abefd;
          border-radius: 12px;
          white-space: nowrap;
          // color: #fff;
          padding: 15px 10px;
          cursor: pointer;
          height: max-content;
          width: min-content !important;
        }
      }
      .load-more {
        display: flex;
        padding: 10px;
        align-items: center;
        border-radius: 10rem;
        color: #000;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.15rem;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        label{
          margin-bottom: 0px;
          margin-right: 5px;
        }
        .fa-chevron-double-down{
          font-size:12px
        }
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;
          border-radius: 10rem;
          z-index: -2;
        }
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 100%;
          background-color: darken(#fff, 15%);
          transition: all .3s;
          border-radius: 10rem;
          z-index: -1;
        }
        &:hover {
          color: #fff;
          &:before {
            width: 100%;
          }
        }
      }
      
    }
  }

  .previous-appointments {
    h5 {
      margin-bottom: 0px;
    }

    .date-header {
      margin-top: 1rem;
    }
  }
  .select-search__input {
    padding: 26px 50px !important;
  }
}
.insured-choice-label {
  font: size 24px;
  color: #1B68DF;
}
.book-back-btn {
  display: flex;
  align-items: center;
}
.res-back-button {
  align-items: center;
}
.res-book-field{
  justify-content: flex-start !important;
}
.filter-selected{
  background-color: #dbfef8;
  color: #35b3d9;
  padding: 0px 10px;
  border-radius: 30px;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}