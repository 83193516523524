.email-signin {
  .auth-base-layout-card {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .ant-form-item-control-input-content {
      gap: 1rem;
      display: flex;

      .email-form-item {
        width: 80%;
        margin-bottom: 0px;
      }

      input::placeholder {
        color: $shadow-grey;
        font-weight: normal;
      }
    }

    .email-field {
      width: 80%;
    }

    .corporate-check-box-container {
      gap: 1rem;
      cursor: pointer;
    }

    .btn-next {
      width: 14rem;
      border-radius: 30px;
    }

    .mm-radio-button {
      .radio {
        width: 2rem;
        height: 2rem;
      }
    }

    .check-icon {
      font-size: 20rem;
      svg {
        width: 10rem;
        height: 10rem;
        color: $izi-logo-color;
      }
    }
  }
}
