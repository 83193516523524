// prescription page styles start
.form-check-input[type="radio"] {
  border-radius: 50%;
  margin: 0;
}

@media (min-width: 768px) {
  .consultation .col-md-6 {
    width: 45% !important;
    margin: 0 auto;
    flex: 0 0 50%;
    max-width: 47%;
  }
}

@media (max-width: 768px) {
  .consultation-section-1,
  .consultation-section-2,
  .consultation-section-3 {
    margin: 10px 0 20px;
  }
}

.consultation-heading {
  font-weight: 600;
  font-size: 18px;
  margin-left: 20px;
}

.consultation-section-1 {
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  border-radius: 12px;
  padding-bottom: 3rem;
  //border: 3px solid #eaebee;

  .btn-primary {
    width: 100%;
  }
}

.doctor-prescription--heading {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin-top: 15px;
  margin-left: 22px;
}

@media (max-width: 375px) {
  .doctor-prescription--heading {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0px;
    margin-top: 15px;
    margin-left: 12px;
  }
}

.prescription-details {
  // background-color: pink;
  width: 90%;
  margin: auto;
  margin-top: 15px;
}
.pres-download-btn {
  color: #a5a8b9;
  margin-top: 20px;
  font-weight: 500;
}
.bg-peach {
  background-color: #fff7f3;
}
.pres-slip {
  margin-top: 20px;
  background-color: #fff7f3;
  border-bottom: 1px solid #c7c7c7;
}
.pres-code {
  font-size: 65px !important;
  font-weight: 500 !important;
}
.pres-detail--row {
  display: flex;
  margin-bottom: 1px;
  // flex-direction: row;
}
.doc-sign {
  width: 80%;
  height: 80%;
}
.pres-medicines {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 1000px) {
  .pres-key {
    width: 100% !important;
  }
  .pres-detail--row {
    flex-direction: column !important;
  }
  .doc-sign {
    width: 180px;
    height: 80%;
    margin-top: 10px;
  }
}

@media (max-width: 1000px) {
  .pres-slip--heading,
  .pres-slip-address {
    width: 100% !important;
  }
}
.pres-slip--heading {
  font-size: 20px;
  font-weight: 600;
  width: 55%;
  margin: 25px auto 10px;
  text-align: center;
}
.bg-green {
  background-color: #c3e0b8;
}
.pres-slip-address {
  font-size: 18px;
  font-weight: 500;
  width: 60%;
  margin: auto;
  text-align: center;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 20px;
}
.pres-key {
  font-size: 16.5px;
  font-weight: 600;
  width: 40%;
}
.pres-value {
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  color: #3a848f;
  font-weight: 600;
  padding: 0 2px;
}
.consultation-section-2 {
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  border-radius: 12px;
  padding-bottom: 70px;
}

.doctor-details {
  // background-color: pink;
  width: 70%;
  margin: auto;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .doctor-details {
    width: 100%;
  }
}

.doctor-details--heading {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.dark-blue {
  color: $izi-logo-color;
}

.doctor-form--input {
  height: 100px;
  border-radius: 12px;
  width: 96%;
  border: 1px solid #aaa;
}

.doctor-details-text {
  display: flex;
  justify-content: space-between;
  color: #aaa;
  width: 96%;
  margin: auto;
  font-weight: 500;
}

.doctor-details-text-1 {
  font-weight: 600;
  letter-spacing: 0.4px;
  font-size: 18px;
}
.doctor-details-text-2 {
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.doctor-details-question {
  color: #aaa;
  width: 96%;
  margin: 25px auto 10px;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.4px;
  line-height: 1.2;
}
.doctor-form--input::placeholder {
  font-size: 14px;
  padding: 5px 10px;
}

.doctor-form-radio {
  font-weight: 600;
  width: 96%;
  margin: auto;
  margin-bottom: 65px;
}

.custom-btn {
  width: 80%;
  margin-top: 10px;
  border-radius: 10px;
  font-weight: 600;
  background-color: #1B68DF !important;
}

.custom-btn-2 {
  width: 52%;
  margin-top: 10px;
  border-radius: 10px;
  font-weight: 600;
  background-color: #1B68DF !important;
}

.custom-btn-3 {
  width: 100%;
  margin-top: 30px;
  border-radius: 10px !important;
  font-weight: 600;
  background-color: #1B68DF !important;
}
// .consultation-section-1 {
// }
.react-pdf__Document {
  height: 485px;
  width: 100%;
}
.doctor-form-radio {
  color: #aaa;
}

.react-pdf__Page {
  position: relative;
  display: flex;
  justify-content: center;
  // padding: 1rem;
  // width: 490px;
  height: 100%;
}
.react-pdf__Page__canvas {
  width: auto !important;
  height: auto !important;
  max-width: 100%;
}
.react-pdf__Page__textContent {
  display: none;
}
// prescription page styles end
.page-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  button {
    border: none;
    background: none;
  }
  svg {
    color: #1B68DF;
  }
  button:disabled {
    svg {
      color: gray;
      cursor: no-drop;
    }
  }
  p {
    margin: 0px;
  }
}
