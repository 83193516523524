.mealplan-card {
  background-color: rgb(255 255 255 / 70%);
  //box-shadow: 0px 0px 5px #bbb;
  padding: 5px 5px 5px 5px;
  border-radius: 10px;
}
.mobile-redirect{
  margin-top: 13px;
  font-size: 17px;
  margin-right: 1rem;
  margin-left: 0.5rem;
}
.food-image {
  width: 100%;
  // min-height: 10rem;
  // max-height: 10rem;
  // min-width: 10rem;
  // max-width: 10rem;
  aspect-ratio: 1;
  border-radius: 0.5rem;
  object-fit: cover;
}
.recipe-image {
  width: 100%;
  min-height: 8rem;
  max-height: 8rem;
  min-width: 8rem;
  max-width: 8rem;
}
.mp-sub-header {
  margin-left: 2rem;
  margin-top: 0.5rem;
}
.browser_result {
  /* max-width: 25%; */
  min-width: 25%;
  border-style: none;
  background-color: rgba(255, 255, 255, 0);
  list-style: none;
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.food_browser_results_list {
  min-width: 30rem;
  margin-left: 1rem;
}
.holo-card-spec {
  min-width: 12rem;
}
.mp-card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  //background-color: #fff;
  background-clip: border-box;
  padding: 4px !important;
  //border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  max-width: 17rem;
  .mp-inner-card {
    // min-height: 38vh;
    // background: white;
    // min-width: 10.5vw;
    // border-radius: 0.5rem;
  }
  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.5rem;
  }
  .card-title {
    margin-bottom: 0.3rem;
  }
  .card-footer {
    background-color: rgb(0 0 0 / 0%);
    border-top: 1px solid rgb(255 255 255 / 0%);
    padding: 0;
  }
}
.set-tile-width {
  max-width: 12rem;
  margin-left: 1rem;
}
.mp-alert {
  margin-bottom: 0.1rem;
  .mp-alert-heading {
    font-size: 1rem;
  }
  p {
    font-size: 1rem;
    margin-right: 1rem;
  }
}
