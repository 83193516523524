.calendar-screen {
  .min-card-width {
    width: 95%;
    height: 100%;
    min-height: max-content;
  }
  .mm-card {
    border-radius: 0;
    //box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .rbc-toolbar {
    padding: 10px;
    gap: 20px;

    .rbc-btn-group {
      display: flex;
      gap: 5px;
      button {
        border: none !important;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        //padding:5px 3px;
        border-radius: 5px;
      }
    }
    .rbc-toolbar-label {
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      padding: 5px 10px;
      border-radius: 5px;
    }

    &:last-child {
      display: none !important;
    }
    .rbc-selected,
    .rbc-selected.rbc-background-event {
      background: #1B68DF !important;
    }
  }

  .rbc-time-header-gutter {
    border: 0 !important;
  }
  .rbc-header,
  .rbc-timeslot-group {
    border-bottom: 1px solid #0e6374 !important;
  }
  .rbc-time-content > * + * > *,
  .rbc-day-bg + .rbc-day-bg,
  .rbc-header + .rbc-header {
    border-left: 1px solid #0e6374 !important;
  }
  .rbc-time-content,
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid #0e6374 !important;
  }
  .rbc-time-view,
  .rbc-month-view {
    border: 1px solid #0e6374 !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-bottom: 0 !important;
  }
  .rbc-today {
    background: #b9cad3 !important;
    border-bottom: 0 !important;
  }
  .rbc-allday-cell {
    display: none !important;
  }
  .rbc-toolbar button:active:hover,
  .rbc-toolbar button:active:focus,
  .rbc-toolbar button.rbc-active:hover,
  .rbc-toolbar button.rbc-active:focus {
    background: #d8e1e1 !important;
  }
  .rbc-off-range-bg {
    background: #d8e1e1 !important;
  }
  .rbc-time-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }
  .rbc-event {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    border-radius: 0 !important;
    .rbc-event-label {
      display: none !important;
    }
  }
  .rbc-day-slot .rbc-events-container {
    margin-right: 0 !important;
  }
  .rbc-current-time-indicator {
    background: transparent !important;
  }
  .rbc-label {
    color: #0e6374 !important;
  }
  .rbc-today:not(.rbc-header) {
    background: #fff !important;
  }
  .rbc-time-header-cell-single-day {
    display: block !important;
  }
  .rbc-header {
    padding: 5px 0 !important;
    background: #e0f2f6 !important;
    border-bottom: none !important;
    span {
      font-weight: 400 !important;
    }
  }
  .maxContentWidth {
    max-width: max-content;
  }
  .status {
    display: flex;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    // //grid-template-rows: repeat(2, 1fr);
    // grid-column-gap: 0px;
    // grid-row-gap: 0px;
    // max-width: 100%;
    // & > div:nth-child(1) {
    //   grid-area: 1 / 1 / 2 / 2;
    // }
    // & > div:nth-child(2) {
    //   grid-area: 1 / 2 / 2 / 3;
    // }
    // & > div:nth-child(3) {
    //   grid-area: 1 / 3 / 2 / 4;
    // }
    // & > div:nth-child(4) {
    //   grid-area: 2 / 1 / 3 / 2;
    // }
    // & > div:nth-child(5) {
    //   grid-area: 2 / 2 / 3 / 3;
    // }
    // & > div:nth-child(6) {
    //   grid-area: 2 / 3 / 3 / 4;
    // }
    // @media screen and (min-width: 1100px) {
    //   max-width: max-content;
    // }
    .status-card {
      font-size: 12px;
      font-weight: 400;
      flex: 1;
      text-align: center;
      max-width: min-content;
      min-width: 100px;
      padding: 8px 0;
      cursor: pointer;
      color: white;
    }
    .pending {
      background: var(--pending);
      border-left: 8px solid #f7d044;
    }
    .ongoing {
      background: var(--ongoing);
      border-left: 8px solid #f7b964;
    }
    .failed {
      background: var(--failed);
      border-left: 8px solid #f54571;
    }
    .completed {
      background: var(--completed);
      border-left: 8px solid #31a876;
    }
    .inprogress {
      background: var(--progress);
      border-left: 8px solid #0261af;
    }
    .hold {
      background: var(--hold);
      border-left: 8px solid #ee46d3;
    }
    .upcoming {
      background: var(--upcoming);
      border-left: 8px solid #af5cfd;
    }
    .cancelled {
      background: var(--cancelled);
      border-left: 8px solid #8f8f8f;
    }
    .skiped {
      background: var(--skiped);
      border-left: 8px solid #48a6f3;
    }
  }

  .custom-toolbar {
    width: 100%;

    .controls {
      background: #dfdfdf;
      padding: 10px;
      border-radius: 50px;
      width: 100%;
      max-width: fit-content;
      display: flex;
      gap: 10px;
      justify-content: space-between;
      gap: 10px;
      align-items: center;

      & > div {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        width: max-content;
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 15px;
        cursor: pointer;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .arrow {
        border-radius: 50% !important;
        width: 30px;
        height: 30px;
        color: #898989;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .select-search {
    max-width: 200px !important;
    .select-search__input {
      border-radius: 16px !important;
      padding: 20px 15px;
      box-shadow: inset 8px 8px 8px $shadow-grey, inset -8px -8px 8px #ffffff;
    }
  }
}
.selected-appoint {
  .headings {
    color: #999a;
  }
}
.side-show {
  width: 6px;
  background-color: #99999914;
  height: 120px;
}
.start-time {
  color: rgba(0, 0, 0, 0.75);
  font-size: 16px;
  font-weight: bold;
}
.doc-name {
  color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.end-time {
  color: #999a;
}
.selected-appoint-card {
  min-height: 10rem;
  //     box-shadow: 0px 0px 3px 0px #aaaaaa;
  //     box-shadow: -14px 3px 7px -5px rgba(153,153,153,0.47);
  // -webkit-box-shadow: -14px 3px 7px -5px rgba(153,153,153,0.47);
  // -moz-box-shadow: -14px 3px 7px -5px rgba(153,153,153,0.47);
  border-radius: 14px;
  padding: 5px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  color: #fff;
  padding: 10px;
}

.pay-btn {
  max-width: 140px;
  min-height: 2.5rem;
  background: #1B68DF;
  text-align: center;
  border-radius: 15px;
  outline: none;
  border: none;
}
.pay-btn:disabled{
  background: gray;
  cursor: no-drop;
}
