$borderColor: #1B68DF;
$greyColor: #7c7d88;

.practitioner-book-consultation {

    .mm-card {
        padding-left: 0px;
        padding-right: 0px;
    }

    .tab-container {
        display: flex;
        color: black;
        text-decoration: none;
        text-align: center;

        .tab {
            font-weight: 600;
            cursor: pointer;
        }

        &.active {
            .tab {
                border-bottom: 3px solid $borderColor;
            }
        }
    }

    .practitioner-details-card {

        .name-img-container {
            display: flex;
            // justify-content: space-around;

            .practitioner-img-container {
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .practitioner-img {
                    width: 7.5rem;
                    height: 7.5rem;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    object-fit: cover;
                    font-size: 3rem;
                }

                .video-audio-icons {
                    color: $izi-logo-color;
                }
            }

            .practitione-name-container {
                display: flex;
                flex-direction: column;
                // align-items: center;
            }
        }

        .doctor-speciality,
        .practitioner-img,
        .languages,
        .specialities,
        .fees {
            color: #477da4;
        }

        .qualifications {
            i {
                color: #477da4;
            }
        }

        .doctor-speciality {
            font-weight: 500;
            font-size: 0.8rem;
        }

        .about-doctor {
            font-size: 0.8rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            &.expanded {
                display: block;
            }
        }

        .working-time {
            display: flex;
            flex-direction: column;

        }

        .head {
            color: rgba(0, 0, 0, 0.85) !important;
            font-weight: 700;
            font-size: 0.8rem;
        }
    
        .tail {
            font-weight: 400;
            font-size: 0.8rem;
        }
        
        .insurance-covered {
            background-color: #fac96e;
            color: #021d48;
            padding: 5px 10px;
            border-radius: 30px;
            white-space: pre-wrap;
            font-size: .8rem;
        }
        .insurance-not-covered {
            background-color: #8dc0fe;
            color: #021d48;
            padding: 5px 10px;
            height: min-content;
            border-radius: 30px;
            white-space: pre-wrap;
            font-size: 0.7rem !important;
            span {
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
        .languages, 
        .specialities,
        .fees {
            display: flex;
            align-items: center;

            i {
                width: 1.5rem;
            }
        }

        .btn-toggle-expand {
            display: flex;
            justify-content: center;

            &:focus {
                box-shadow: none;
            }

            .fas {
                border: 1px solid #ddd;
                border-radius: 50%;
                color: #ddd;
                width: 2rem;
                height: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .practitioner-slot-details {
        padding-left: 0px;
        padding-right: 0px;

        ::-webkit-scrollbar {
            height: 5px !important;
        }
        
        .slots-day {
            border-radius: 5px;
            border: 2px solid $greyColor;
            background-color: $greyColor;
            color: $greyColor;
            margin: 0rem .5rem 0.25rem 0.5rem;
            width: 5rem;
            height: 5rem;
            cursor: pointer;

            .month-name {
                text-align: center;
                color: white;
                // border-top-left-radius: 5px;
                // border-top-right-radius: 5px;
                height: 25%;
                font-size: .8rem;
            }

            .day {
                height: 75%;
                background-color: white;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                font-size: .8rem;
                font-weight: 600;
            }

            &.selected {
                border: 2px solid $borderColor;
                background-color: $borderColor;
                
                .day {
                    color: $borderColor;
                }
            }
        }

        .btn {
            border-radius: 30px;
            box-shadow: none;
            // padding: 1rem 1.5rem;
            width: 48%;
            max-width: 10rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .8rem;
        }
        
        .slots {
            display: flex;
            flex-wrap: wrap;
            justify-content: left;

            .slot {
                margin: 0.25rem .25rem;
                border-radius: 50px;
                border: 2px solid $borderColor;
                color: $borderColor;
                width: 7rem;
                height: 2.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #ffffff;
                cursor: pointer;
                font-size: .8rem;

                &.selected {
                    background-color: $borderColor;
                    color: white;
                }
            }
        }

        .call-duration {
            background-color: #caddfa;
            border-radius: 5px;
            color: black;
            padding: 0.5rem 0.5rem;
            font-size: 14px;
        }

        .subtitle {
            font-size: 16px;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: .8rem;
        }

        .call-mode {
            background-color: #8fc9fe;
            border-radius: 10px;
            display: flex;
            align-items: center;
            padding: 0.5rem;
            font-size: 14px;

            .icon-container {
                color: $borderColor;
                background-color: #d6d6d6;
                // padding: 0.5rem;
                width: 2.5rem;
                height: 2.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                font-size: 14px;
            }

            .text {
                color: black;
                font-size: 14px;

                .title {
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }

        .btns {
            .btn {
                height: 40px;
            }

            .btn-primary {
                margin-top: 0px;
            }
        }
    }

    .booking-info {

        label {
            white-space: break-spaces;
            text-align: start;
        }

        .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            display: none;
            // display: inline-block;
            // margin-right: 4px;
            // color: #ff4d4f;
            // font-size: 14px;
            // font-family: SimSun, sans-serif;
            // line-height: 1;
            // content: '*';
        }

        .ant-form-item-label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 18px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';
        }

        .ant-input,
        .ant-input-affix-wrapper,
        .ant-picker {
            border-radius: 3rem;
        }

        .textarea {
            border-radius: 1rem;
        }


        .ant-picker {
            width: 100%;
        }

        .ant-form-item-control-input-content {
            span {
                // display: block;
                // width: 100%;

                .ant-upload-select {
                    width: 100%;
                }
            }
        }

        .required-mark {
            label::after {
                display: inline-block;
                margin-right: 4px;
                color: #ff4d4f;
                font-size: 18px;
                font-family: SimSun,
                sans-serif;
                line-height: 1;
                content: '*';
            }
        }
        
        .emirates-id-form-item {

            .ant-form-item-control {
                justify-content: center;

                .ant-form-item-control-input {
                    display: block;
                    aspect-ratio: 5 / 3;
                    // height: 260px;
                    width: 433px;
                    max-width: 100%;
                    // max-height: 100%;
                }
            }

            .emirates-id-upload {
                display: block;
                aspect-ratio: 5 / 3;
                // height: 260px;
                width: 433px;
                max-width: 100%;

                // .ant-upload-list {
                //     display: none;
                // }
    
                .ant-upload {
                    width: 100%;
                    height: 100%;
    
                    span.ant-upload {
                        display: block;
                        width: 100%;
                        height: 100%;
    
                        .emirates-id-image-btn {
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;
                            background-color: white;

                            .image-preview {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 10px;
                            }
                        }
                    }
    
                }
    
            }
        }

        .how-often-fieldm,
        .reason-of-visit-field {
            flex-direction: column;
        }

        .ant-btn {
            border-radius: 20px;
            width: 13rem;
        }

        .cancel-btn {
            // background-color: #d9d9d9;
            background-color: grey;
            border-color: grey;
            color: white;
        }

    }
}

@media (max-width: 830px) {
    .practitioner-book-consultation {
        .practitioner-details-card {
            .name-img-container {
                justify-content: center;
            }
        }
    }
}

@media (max-width: 650px) {
    .practitioner-book-consultation {

        .mobile-form-item-row {
            justify-content: space-between;

            .ant-form-item-label,
            .ant-form-item-control {
                width: 50%;
                flex: none;
                // flex-grow: 1;
                // flex-shrink: 1;
            }
        }

        .practitioner-details-card {
            .name-img-container {
                align-items: center;
            }
        }

        .practitioner-slot-details {
            .slots {
                .slot {
                    width: 5rem;
                    font-size: 12px;
                }
            }
        }
        
        .tab-container {
            .tab {
                font-size: 13px;
            }
        }


        .radio {
            width: 1.5rem !important;
            height: 1.5rem !important;
            font-size: 11px !important;
        }

        .booking-info {

            .buttons-row {
                flex-direction: column;
                align-items: center;

                .ant-btn {
                    font-size: 12px;
                    width: 90%;
                    margin-top: 1rem;
                }
            }

            .ant-form-item {

                .ant-form-item-label {
                    label {
                        font-size: 13px;
                    }
                }

                &.proceed-with-full-payment,
                &.gender-select,
                &.is-insured,
                &.booking-info-amount-to-pay {
                    justify-content: space-between;
    
                    .ant-form-item-control,
                    .ant-form-item-label {
                        flex: none;
                    }
                }
            }

            .emirates-id-form-item {
            
                .ant-form-item-control {
                    justify-content: center;
            
                    .ant-form-item-control-input {
                        display: block;
                        aspect-ratio: 5 / 3;
                        height: 260px;
                        width: 100%;
                    }
                }
            
                .emirates-id-upload {
                    display: block;
                    aspect-ratio: 5 / 3;
                    height: 260px;
                    width: 100%;
                }
            }
        }
    }
}