.voucher--rows{
    background-color: #fff;
    border-radius: 5px;
    margin: 15px;
    &-all{
        font-size: 18px;
        margin: 30px 10px 10px 15px;
        font-weight: 600;
    }
    font-family: 'Montserrat';
    position: relative;
}

.save--btn{
    position: absolute;
    top: 20px;
    right: 15px;
    width: 115px;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    background-color: #2ba1e3;
}

.voucher--column-1{
    // margin-left: 12px;
    padding-right: 15px;
    padding-left: 30px;

    .voucher-info-heading{
        margin-top: 8px;
        margin-bottom: 1rem;
        font-weight: 600;
    }

    .voucher-info-desc{
      color: #7f7e7e;
        /* padding-right: 30px; */
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        width: 85%;
    }

    label{
        font-weight: 600;
    }

    .voucher-text--para{
        margin-bottom: 5px;
        font-weight: 600;
    }
}

.voucher--column-2{
    
    label{
        font-weight: 600;
    }
}

.voucher-input{
    border: none;
    outline: none;
    padding-top: 12px;
    padding-right: 10px;
    padding-left: 20px;
    padding-bottom: 13px;
    border-radius: 15px;
    box-shadow: inset 8px 8px 8px #efefef, inset -8px -8px 8px #ffffff;
    width: 100%;
}

.voucher-fields{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0;
}

.voucher-name-input{
    width: 65%;
}

.retail-price-input{
    // margin-left: 25px;
}

.voucher-checkbox{
   display: flex;
   width: 60%;
   justify-content: space-between; 
   label{
       padding-left: 15px;
   }
}




.btn-blocks{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
    font-family: 'Montserrat';
}



.sell-btn{
    background-color: #3975d0;
    border: none;
    border-radius: 3px;
    color: #fff;
    padding: 6px;
    width: 190px;
    margin-right: 15px;
    font-weight: 600;
}

.create--btn{
    background-color: #2ba1e3;
    border: none;
    border-radius: 3px;
    color: #fff;
    padding: 6px;
    width: 190px;
    font-weight: 600;
}

@media (max-width: 850px){
    .btn-blocks{
        flex-direction: column;

        &--inside{
            display: flex;
            flex-direction: column;
        }

        .sell-btn, .create--btn{
            margin-bottom: 10px;
            margin-right: 0px;
        }
    }

}

.voucher-cards-block{
    display: grid;
    grid-template-columns: auto auto;
    margin: 25px;
    text-align: center;
}

@media (max-width: 1367px){
    
    .voucher-cards-block{
        display: flex;
        flex-direction: column;
        // justify-content: center;
        margin: 0px;
        align-items: center;
    }
}

@media (max-width: 645px){
      .voucher-card{
          width: 100% !important;
      }
}


.voucher-outer-box{
    display: flex;
    align-items: center;
    justify-content: center;
}

.voucher-div{
    display: flex;
    justify-content: space-around;
}

@media (max-width: 645px){
    .voucher-outer-box{
        flex-direction: column;
    }
    .voucher-para{
        padding: 8px 8px 0px 8px;
        margin-bottom: 2px;
    }
    .voucher-card--upper{
        height: 35% !important;
    }
    .voucher-div{
        flex-direction: column;
    }
    .rounded-circle--left, .rounded-circle--right{
        display: none !important;
    }
    .voucher-card{
        height: 265px !important;
    }
}

.purple-card{
    background: linear-gradient(133deg, rgba(101,57,246,1) 0%, rgba(177,72,244,1) 100%);
}

.green-card{
    background: linear-gradient(133deg, rgba(0,158,103,1) 0%, rgba(0,165,151,1) 100%);
}

.black-card{
    background-color: #002314;
}

.light-green-card{
    background-color: #0ca925;
}

.dark-green-card{
    background-color: #21804c;
}

.dark-blue-card{
    background-color: #17526b;
}

.orange-card{
    background-color: #ff8c05;
}

.yellow-card{
    // background-color: #faff00;
    background-color: #f0ce4c;
}

.selected-voucher{
    border: 2px solid #a9bed2 !important;
    box-sizing: initial;
}

.voucher-card{
    // background-color: pink;
    color: white;
    border-radius: 20px;
    width: 500px;
    height: 210px;
    margin: 12px 0;
    position: relative;
    &--upper{
        text-align: center;
        height: 50%;
        p{
            padding-top: 25px;
            margin-bottom: 0px;
            font-weight: 500;
        }
    }
    h5{
        color: white;
    }
    &--lower{
        padding-top: 15px;
    }
}

.custom-color--picker{
    border-radius: 50% !important;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;
}

.rounded-circle--left, .rounded-circle--right{
    border-radius: 50% !important;
    background-color: white;
    width: 30px;
    height: 30px;
    position: absolute;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rounded-circle--left{
    top: 45%;
    left: -15px;
}

.rounded-circle--right{
    top: 45%;
    right: -15px;
}

@media (max-width: 992px){
    .voucher--column-2{
        padding-left: 30px;
    }
}

.voucher-preview{
    background-color: #eef9f8;
    padding: 10px;
    border-radius: 3px;
    &--heading{
        font-weight: 600;
    }
    .voucher-card--lower{
        text-align: center;
    }
}

@media (min-width: 992px){
    .modal-xl {
        max-width: 980px !important;
    }
}