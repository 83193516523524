// agora video screen page style start

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap");

.appointment-prescription-drawer {
  .ant-drawer-content-wrapper {
    width: 700px !important;
  }

  .apply-btn {
    width: auto;
  }
}

@media (max-width: 780px) {
  .appointment-prescription-drawer {
    .ant-drawer-content-wrapper {
      width: 80% !important;
    }
  }
}

.video-call--screen {
  .video-call-info-and-chat {
    height: 100vh; //old --> 85vh
  }


  .doc-patient-chat {
    padding: 25px 20px 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  // #remote-videos{
  //   height: 576px !important;
  // }

  @media (min-width: 992px) {
    .col-lg-5 {
      width: 40%;
    }
  }
}

.video-call-card {
  // position: relative;
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  flex: 1;
  // padding-bottom: 9rem;
  // padding-left: 1rem;
  // padding-right: 1rem;
  // padding-top: 1rem;
  background-color: white;
  border-radius: 10px;

  .video {
    // height: 65vh;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    height: 100%;
    // height: 75%;

    #local-video {
      height: 14rem;
      aspect-ratio: 9 / 16;
      border-radius: 10px;
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: 1;

      div {
        border-radius: 10px;
      }
    }

    #remote-videos,
    .remote-video {
      display: flex;
      flex-wrap: wrap;
      height: 100%;

      div {
        width: 100%;
        border-radius: 10px;
      }
    }
  }

  .bottom-bar {
    // position: absolute;
    // left: 0px;
    // right: 0px;
    // bottom: 0px;
    // height: 122px;
    margin-top: auto;
    background-color: $bottom-left-circle-color;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 1rem 1rem;
    display: flex;
    justify-content: space-between;

    .timer-container {
      height: 60px;
    }

    .call-controles {
      display: flex;
      align-items: end;
      justify-content: space-between;

      .btn {
        border-radius: 7px;
        margin: 0px 0.5rem;
        box-shadow: none;
        width: 3rem;
        height: 3rem;
      }
    }

    .name {
      font-weight: 600;
      margin-bottom: 0px;
    }

    .title {
      margin-bottom: 0px;
      color: grey;
    }

    .call-time {
      padding: 0.5rem;
      background-color: lighten($color: $bottom-left-circle-color, $amount: 10);
      border-radius: 5px;
      width: fit-content;
    }
  }
}

@media (max-width: 820px) {
  .video-call-card {
    // padding-bottom: 11rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 0rem;

    .bottom-bar {
      // height: 180px;

      .timer-container {
        height: 50px;
      }

      .btn-danger {
        margin-top: 1rem;
      }
    }

    .video {
      height: 100%;
    }
  }
}

.patient-info {
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
  // background-color: red;
  border: 10px;
  border-radius: 8px;
  padding: 15px 20px;
  font-size: 15px;
  &--heading {
    font-weight: 600;
    color: #1d2836;
    &-reason {
      font-weight: 600;
      font-size: 18px;
      margin: 10px 0px 6px;
    }
  }
  &--details {
    color: #222d3a;
    margin: 4px 0;
  }
  &--reason {
    color: #3d3e40;
    margin: 8px 3px;
    line-height: 1.3;
    width: 90%;
  }
  &-reports {
    width: 49%;
    font-size: 13px;
    font-weight: 600;
    color: #525c65;
    margin: 2px 0;
  }
  &--icons {
    cursor: pointer;
  }
}

.doc-patient-chat {
  background-color: #fff;
  border: 10px;
  font-family: "Montserrat", sans-serif;
  border-radius: 8px;
  margin-top: 10px;

  .messages-list {
    overflow: auto;
    width: 100%;

    .sent-msg--block {
      display: flex;
      justify-content: flex-end;
      margin: 7px 0;
    }

    .recieved-msg--block {
      display: flex;
      margin: 15px 0;
    }
    .file-msg {
      background: #f9f9f9;
      width: max-content;
      border: 1px solid #ddd;
      padding: 5px;
    }
    .sent-msg {
      //width: 65%;
      min-width: 160px;
      background: #1B68DF;
      position: relative;
      display: block;
      color: white;
      font-weight: 500;
      border-radius: 6px;
      line-height: 1.3;
      align-items: right;
      padding: 15px 20px;
      font-size: 12px;
      border-bottom-right-radius: 0px;
    }
    .recieved-msg {
      // width: 65%;
      // background: #1B68DF;
      position: relative;
      display: block;
      color: #a3a5b6;
      font-weight: 500;
      border-radius: 6px;
      line-height: 1.3;
      align-items: right;
      padding: 6px 9px;
      font-size: 12px;
      border-bottom-left-radius: 0px;
    }
    .bubble {
      min-width: 170px;
      max-width: fit-content;
      height: auto;
      display: flex;
      background: #f5f5f5;
      border-radius: 4px;
      position: relative;
      margin: 0 0 25px;
      padding: 8px;
      &.alt {
        margin: 0 0 0 60px;
      }
      .txt {
        max-width: 200px;
        padding: 8px 55px 8px 14px;
        .name {
          font-weight: 600;
          font-size: 12px;
          margin: 0 0 4px;
          color: #3498db;
          span {
            font-weight: normal;
            color: #b3b3b3;
          }
          &.alt {
            color: #2ecc71;
          }
        }
        .message {
          font-size: 12px;
          margin: 0;
          color: #2b2b2b;
        }
        .timestamp {
          font-size: 8px;
          position: absolute;
          bottom: 8px;
          right: 10px;
          text-transform: uppercase;
          color: #999;
        }
      }
      .bubble-arrow {
        position: absolute;
        width: 0;
        bottom: 42px;
        left: -16px;
        height: 0;
        &.alt {
          right: -2px;
          bottom: 40px;
          left: auto;
        }
      }
      .bubble-arrow:after {
        content: "";
        position: absolute;
        border: 0 solid transparent;
        border-top: 9px solid #f5f5f5;
        border-radius: 0 20px 0;
        width: 15px;
        height: 30px;
        transform: rotate(145deg);
      }
      .bubble-arrow.alt:after {
        transform: rotate(45deg) scaleY(-1);
      }

      .sender-name {
        font-size: 0.6rem;
      }
    }
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  & {
    scrollbar-width: thin;
    scrollbar-color: #c0c0c0 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 25px;
    border: 3px solid #ffffff;
  }
}

.sent-msg--input {
  position: relative;
  margin-top: auto;

  .form-group {
    background: #1B68DF;
    width: 80%;
    position: relative;
    border-radius: 8px;
    padding: 1px;
    display: flex;
    // margin: 15px 0px 10px;
    width: 100%;
    justify-content: flex-end;
  }

  .form-control {
    width: 90%;
    border: 0 !important;
    margin: 2px;
    padding-right: 30px;
  }
}

.doctor-video--screen {
  display: flex;
}

.doctor-video--screen > div {
  width: 33.33%;
}

.post-msg {
  position: absolute;
  top: 11px;
  right: 8px;
  cursor: pointer;
  color: #1B68DF;
}

.doctor-video--details {
  // position: absolute;
  bottom: 3%;
  left: 5%;
  /* right: -5%; */
  /* margin: -20px auto; */
  align-items: center;
  justify-items: center;
  padding: 8px;
  z-index: 1;
  // background-color: pink;
  max-width: 60vw;
  .doc {
    display: block;
    color: #a4a7b8;
  }
  .doc-name {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    font-family: "Montserrat", sans-serif;
  }
  .doc-meeting-time {
    border-radius: 5px;
    padding: 4px 3px;
    color: #455a53;
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.semi-circle {
  border-radius: 0 0 150px 150px;
  background-color: green;
}

.patient-video--screen {
  width: 130px;
  height: 200px;
  background-color: pink;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  border-radius: 8px;
}

// agora classes and id

#videos {
  width: auto !important;
  margin: 0 !important;
}
.App {
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
  display: block !important;
  height: auto !important;
  position: relative !important;
  background: #54e5c2;
}

// agora video screen page style end
