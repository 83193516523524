// mindmina
$grey-background: #f2f5fa;
$background-circles-size: 25rem;
$background-circles-resp-size: 12rem;
$top-right-circle-color: #1b68df;
$bottom-left-circle-color: #1b68df;
$blue-grey: rgba(230, 238, 248, 1);

$blue-background: #186be8;
$background-circles-ring-size: 26rem;
$background-circles-ring-resp-size: 13rem;
$top-right-base-circle-color: #186be8;
$bottom-left-base-circle-color: #186be8;
$top-right-ring-circle-color: #5797f5;
$bottom-left-ring-circle-color: #5797f5;

$gradient-grey: #f2f4fc;
$izi-logo-color: #1b68df;
$grey-text-color: #546e7a;
$shadow-grey: #efefef;
$purble-color: #d323c2;
$darkBlueColor: #7c7d88;
$ligh-green-color: #8bccb4;
$light-orange-color: #ebb69f;
$notification-item-background-color: #314668;
$ligh-teal-color: #77eace;
$selected-green-color: #1caf59;
$dark-indigo-color: #2b607d;
$box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
$fats-color: #00f49c;
$carbs-color: #ffe400;
$protein-color: #ffb400;
$greenish-color: #e0f792;
$price-orange: #ff7000;
$background-blue-gradient: #f2f5fa;
