
$black: #000;
$grey: #ccc;
$white: #fff;
$yellow: #ff0;
$green: #8bc34a;
$blue: #29d2e4;
$orange: #f87d09;
$dark-pink: #e91e63;
$light-purple: #efedff;
//Primary-color
$tooltip-base-text-color: $white;
$tooltip-base-bg-color: $black;
$tooltip-base-border-color: $black;
$main-background: $light-purple;
//Types
$tooltip-invert-bg-color: $grey;
$tooltip-success-bg-color: $green;
$tooltip-info-bg-color: $blue;
$tooltip-warning-bg-color: $orange;
$tooltip-danger-bg-color: $dark-pink;
//Features

.tooltip pre {
  background: $white;
  padding: 10px;
  border-radius: 4px;
  &:nth-child(2){
    margin-bottom: 40px;
  }
}

.preview-title {
  margin: 40px 0 20px;
  font-size: 24px;
  font-weight: normal;
}

.tooltip-content {
  max-width: 960px;
  margin: 0 auto;
  padding: 30px 60px;
  > span {
    font-size: 24px;
    display: block;
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}

[data-tooltip] {
  position: relative;
  &:before,
  &:after {
    opacity: 0;
    position: absolute;
    left: 50%;
    backface-visibility: hidden
  }
  &:before {
    border-style: solid;
    border-top-width: 4px;
    border-right-width: 4px;
    border-bottom-width: 4px;
    border-left-width: 4px;
    border-bottom-width: 0;
    content: '';
    top: -2px;
    width: 0;
    height: 0;
    transform: translate(-50%, calc(-50% - 6px));
    transition: opacity .1s cubic-bezier(.73, .01, 0, 1) 0s, transform .6s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) 0s;
    z-index: 110000
  }
  &:after {
    content: attr(data-tooltip);
    text-align: center;
    padding: 10px 10px 9px;
    font-size: 16px;
    border-radius: 8px;
    color: $tooltip-base-text-color;
    transition: opacity .3s cubic-bezier(.73, .01, 0, 1), transform .3s cubic-bezier(.73, .01, 0, 1), -webkit-transform .3s cubic-bezier(.73, .01, 0, 1);
    pointer-events: none;
    z-index: 999;
    white-space: nowrap;
    bottom: 100%;
    transform: translate(-50%, 12px);
    max-width: 320px;
    text-overflow: ellipsis;
    overflow: hidden
  }
}

[data-tooltip]:after {
  background: $tooltip-base-bg-color;
}

[data-tooltip]:before {
  border-top-color: $tooltip-base-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-base-border-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-base-border-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-base-border-color;
}

[data-tooltip] {
  &:focus,
  &:hover {
    &:before,
    &:after {
      opacity: 1;
    }
  }
  &:focus,
  &:hover {
    &:before {
      transition: opacity .1s cubic-bezier(.73, .01, 0, 1) .1s, transform .6s cubic-bezier(.73, .01, 0, 1) .1s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .1s;
      transform: translate(-50%, calc(-50% - 2px));
    }
    &:after {
      transform: translate(-50%, -6px);
    }
  }
}

[data-tooltip][data-tooltip-conf*=right]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-left-width: 0;
  left: auto;
  right: -6px;
  top: 50%;
  transform: translate(calc(-50% + 7px), -50%)
}

[data-tooltip][data-tooltip-conf*=right]:after {
  top: 50%;
  left: 100%;
  bottom: auto;
  -webkit-transform: translate(-12px, -50%);
  transform: translate(-12px, -50%)
}

[data-tooltip][data-tooltip-conf*=right]:focus:before,
[data-tooltip][data-tooltip-conf*=right]:hover:before {
  -webkit-transform: translate(calc(-50% + 3px), -50%);
  transform: translate(calc(-50% + 3px), -50%)
}

[data-tooltip][data-tooltip-conf*=right]:focus:after,
[data-tooltip][data-tooltip-conf*=right]:hover:after {
  -webkit-transform: translate(7px, -50%);
  transform: translate(7px, -50%)
}

[data-tooltip][data-tooltip-conf*=bottom]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-top-width: 0;
  top: auto;
  bottom: -6px;
  left: 50%;
  -webkit-transform: translate(-50%, calc(-50% + 6px));
  transform: translate(-50%, calc(-50% + 6px))
}

[data-tooltip][data-tooltip-conf*=bottom]:after {
  top: 100%;
  left: 50%;
  bottom: auto;
  -webkit-transform: translate(-50%, -12px);
  transform: translate(-50%, -12px)
}

[data-tooltip][data-tooltip-conf*=bottom]:focus:before,
[data-tooltip][data-tooltip-conf*=bottom]:hover:before {
  -webkit-transform: translate(-50%, calc(-50% + 2px));
  transform: translate(-50%, calc(-50% + 2px))
}

[data-tooltip][data-tooltip-conf*=bottom]:focus:after,
[data-tooltip][data-tooltip-conf*=bottom]:hover:after {
  -webkit-transform: translate(-50%, 6px);
  transform: translate(-50%, 6px)
}

[data-tooltip][data-tooltip-conf*=left]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 0;
  left: -2px;
  top: 50%;
  width: 0;
  height: 0;
  -webkit-transform: translate(calc(-50% - 8px), -50%);
  transform: translate(calc(-50% - 8px), -50%)
}

[data-tooltip][data-tooltip-conf*=left]:after {
  top: 50%;
  right: 100%;
  bottom: auto;
  left: auto;
  -webkit-transform: translate(12px, -50%);
  transform: translate(12px, -50%)
}

[data-tooltip][data-tooltip-conf*=left]:focus:after,
[data-tooltip][data-tooltip-conf*=left]:hover:after {
  -webkit-transform: translate(-7px, -50%);
  transform: translate(-7px, -50%)
}

[data-tooltip][data-tooltip-conf*=left]:focus:before,
[data-tooltip][data-tooltip-conf*=left]:hover:before {
  -webkit-transform: translate(calc(-50% - 3px), -50%);
  transform: translate(calc(-50% - 3px), -50%)
}

[data-tooltip][data-tooltip-conf*=multiline]:after {
  word-break: break-word;
  white-space: normal;
  min-width: 180px;
  text-overflow: clip
}

[data-tooltip][data-tooltip-conf*=delay]:before {
  -webkit-transition: opacity .2s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) 0s, transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) 0s, transform .3s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s
}

[data-tooltip][data-tooltip-conf*=delay]:after {
  -webkit-transition: opacity .3s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) 0s, transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) 0s, transform .3s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s
}

[data-tooltip][data-tooltip-conf*=delay]:focus:before,
[data-tooltip][data-tooltip-conf*=delay]:hover:before {
  -webkit-transition: opacity .2s cubic-bezier(.73, .01, 0, 1) .5s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .5s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) .5s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .5s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) .5s, transform .6s cubic-bezier(.73, .01, 0, 1) .5s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) .5s, transform .6s cubic-bezier(.73, .01, 0, 1) .5s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .5s
}

[data-tooltip][data-tooltip-conf*=delay]:focus:after,
[data-tooltip][data-tooltip-conf*=delay]:hover:after {
  -webkit-transition: opacity .3s cubic-bezier(.73, .01, 0, 1) .4s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) .4s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) .4s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) .4s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) .4s, transform .3s cubic-bezier(.73, .01, 0, 1) .4s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) .4s, transform .3s cubic-bezier(.73, .01, 0, 1) .4s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) .4s
}

[data-tooltip][data-tooltip-conf*=shadow]:after {
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, .1)
}

[data-tooltip][data-tooltip-conf*=no-fading]:after,
[data-tooltip][data-tooltip-conf*=no-fading]:before {
  -webkit-transition: none;
  transition: none
}

[data-tooltip][data-tooltip-conf*=no-arrow]:before {
  display: none
}

[data-tooltip][data-tooltip-conf*=square]:after {
  border-radius: 0
}

[data-tooltip][data-tooltip-conf*=invert]:after {
  color: $tooltip-base-text-color;
  background: $tooltip-invert-bg-color;
}

[data-tooltip][data-tooltip-conf*=invert]:before {
  border-top-color: $tooltip-invert-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-invert-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-invert-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-invert-bg-color;
}

[data-tooltip][data-tooltip-conf*=success]:after {
  background: $tooltip-success-bg-color;
}

[data-tooltip][data-tooltip-conf*=success]:before {
  border-top-color: $tooltip-success-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-success-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-success-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-success-bg-color;
}

[data-tooltip][data-tooltip-conf*=info]:after {
  background: $tooltip-info-bg-color;
}

[data-tooltip][data-tooltip-conf*=info]:before {
  border-top-color: $tooltip-info-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-info-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-info-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-info-bg-color;
}

[data-tooltip][data-tooltip-conf*=warning]:after {
  background: $tooltip-warning-bg-color;
}

[data-tooltip][data-tooltip-conf*=warning]:before {
  border-top-color: $tooltip-warning-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-warning-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-warning-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-warning-bg-color;
}

[data-tooltip][data-tooltip-conf*=danger]:after {
  background: $tooltip-danger-bg-color;
}

[data-tooltip][data-tooltip-conf*=danger]:before {
  border-top-color: $tooltip-danger-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-danger-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-danger-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-danger-bg-color;
}

[data-tooltip][data-tooltip=''] {
  &:after,
  &:before {
    display: none
  }
}
