// .ant-form-item-required,
// label[title="Start:"],
// label[title="Is Paid:"],
// label[title="Participants Mic:"],
// label[title="Attendees:"],
// label[title="Duration:"] {
//   font-size: 1rem !important;
// }

.practitioner-new-live-session-form-tab {
  label {
    font-size: 1rem !important;
  }
}

label[title="Session Promotion Photo"] {
  width: 300px !important;
  white-space: nowrap;
}

.key-points {
  max-height: 30rem;
  overflow-y: scroll;
  border: 1px solid #e1e1e1;
  border-radius: 20px;
  padding: 10px 20px;
  min-height: 200px;
}
.img-label {
  font-size: 16px;
  margin-top: 20px;
}
.bullet-point {
  color: #63a44f;
  font-size: 10px;
  padding-left: 3px;
  padding-right: 3px;
  border: 1px solid #63a44f;
  border-radius: 50%;
}

.fa-pen {
  margin-left: 15px;
  color: #1B68DF;
  font-size: 12px;
  cursor: pointer;
}

.button {
  background-color: #1B68DF !important;
  color: white;
  border: none;
  padding: 3px 25px !important;
  border-radius: 25px;
}
.itemsmt-3{
  margin-top: 1rem!important;
}
.btn-label{
  font-size: 1rem;
}
@media (max-width: 767px) {
  .practitioner-new-live-session-form-tab label {
    font-size: .8rem !important;
}
.ant-form-item .ant-form-item-label {
  padding: 0 !important;
}
.itemsmt-3{
  margin-top: 1px !important;
}
.btn-label{
  font-size: .8rem;
}
}