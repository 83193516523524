.blue-icon{
color: #1B68DF;
}

.fa-times-circle:hover{
  cursor: pointer;
}

.createNewButton{
  background-color: #1B68DF !important;
  color: white;
border: none;
  padding: 6px 25px !important;
border-radius: 25px;
}
.text-header-size{
  font-size: 1rem;
  font-weight: normal;
}

@media (max-width: 767px) {
  .createNewButton{
    background-color: #1B68DF !important;
    color: white;
    border: none;
    padding: 4px 2rem !important;
    /* width: 9rem; */
    border-radius: 50px;
    font-size: .8rem;
    text-align: justify;
  }
  .text-header-size{
    font-size: .8rem;
    font-weight: bold;
  }
  .ant-input{
    font-size: .8rem !important;
  }
}
