.buddy-details {
  font-family: "Montserrat";
  width:  100%;
  margin: 10px 0px 20px 20px;
  padding: 20px 20px 20px 20px;
  .fb-section-1 {
    display: flex;
    height: 540px;
    margin-bottom: 20px;
    .fb-details-container {
      width: calc(100% - 50%);
      margin-right: 20px;
      background: #fff;
      border-radius: 100%/0 0 30% 30%;
      border-top-left-radius: 10%;
      border-top-right-radius: 10%;
      .buddy-profile {
        display: flex;
        .profile-info {
          margin: 10px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .profile-pic {
            width: 175px;
            height: 175px;
            border-radius: 50%;
            border: 2px solid #1B68DF;
            object-fit: cover;
            object-position: bottom center;
          }
          .upload-img {
            width: 38px;
            height: 38px;
            position: relative;
            bottom: 60px;
            left: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgb(63, 131, 236);
            border-radius: 50%;
            svg {
              font-size: 25px;
              color: #fff;
            }
          }
        }
        .about-me {
          margin: 10px;
          padding: 10px;
          width: 100%;
          height: 230px;
          display: flex;
          flex-direction: column;
          color: #2b607d;
          textarea {
            resize: none;
            margin-top: 10px;
            color: rgb(43, 96, 125) !important;
            height: 150px;
            width: 100%;
            border-radius: 10px;
          }
        }
      }
      .all-interest {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: calc(100% - 20px);
        margin-top: 10px;
        height: 133px;
        overflow: overlay;
        .display-interest {
          background-color: rgb(53, 126, 199);
          color: #fff;
          height: 40px;
          text-align: center;
          display: flex;
          align-items: center;
          border-radius: 25px;
          margin: 2px 2px;
          font-size: .8rem;
          svg {
            position: relative;
            top: -14px;
            background: #fff;
            color: rgb(63, 131, 236);
            border-radius: 50%;
          }
          div {
            margin: 10px;
          }
        }
      }
      .add-interest {
        text-align: center;
        button {
          width: 325px;
          height: 70px;
          font-size: 16px;
          color: #000;
          font-weight: 600;
          border: 1px solid #eee;
          background: #fff;
          border-radius: 10px;
        }
        svg {
          color: rgb(63, 131, 236);
          background: rgba(63, 131, 236, 0.1);
          border-radius: 50%;
          font-size: 30px;
          margin-left: 20px;
          font-weight: bold;
        }
      }
    }
    .fb-gallery-container {
      background: #fff;
      width: calc(100% - 50%);
      border-radius: 5%;
    }
  }
  .fb-section-2 {
    display: flex;
    .fb-settings-container {
      width: calc(100% - 50%);
      margin-right: 20px;
      background: #fff;
      border-radius: 5%;
      height: 100% !important;
      .setting {
        margin: 10px;
        padding: 5px;
        font-size: 18px;
        font-weight: Bold;
        .is-adult {
          font-size: 16px;
          display: flex;
          font-weight: bold;
          margin-top: 10px;
          margin-left: 5px;
          .text {
            margin-right: 30px;
            padding-right: 20px;
          }
        }
      }
      .discover-settings {
        .d-setting {
          margin-top: 10px;
          margin-left: 20px;
          font-size: 18px;
          font-weight: bold;
        }
        .info {
          margin-left: 40px;
          .act {
            margin-right: 30px;
            font-weight: bold;
          }
          .act-item {
            margin-right: 30px;
            font-weight: bold;
            color: rgb(63, 63, 63);
          }
          .location {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .loc-text {
              font-weight: bold;
            }
            .loc-details {
              display: flex;
              align-items: center;
              .current-location {
                color: rgb(63, 131, 236);
                font-size: 16px;
              }
              .selected-location {
                font-size: 16px;
              }
            }

            .button-map {
              width: 50px;
              height: 40px;
              border: none;
              background: none;
              svg {
                background: rgba(63, 131, 236, 0.2);
                border-radius: 50%;
                font-size: 40px;
                color: rgb(63, 131, 236);
              }
            }
          }
        }
      }
      .distance {
        margin: 35px 20px 10px 35px;
        border-top: 1px solid #ddd;
        .details {
          display: flex;
          justify-content: space-between;
          label {
            font-weight: bold;
          }
        }
        .distance-slider {
          width: calc(100% - 5px);
        }
      }
      .g-text {
        font-size: 16px;
        font-weight: bold;
        margin: 20px 20px 10px 35px;
        border-top: 1px solid #ddd;
      }
      .gender {
        display: flex;
        font-size: 16px;
        font-weight: 500;
        .grid {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
        }
        .male,
        .female,
        .all {
          display: flex;
          margin-top: 10px;
          margin-right: 20px;
          font-size: 16px;
          color: rgb(63, 63, 63);
          .react-switch-checkbox:checked {
            .react-switch-label {
              background: none;
            }
          }
        }

        .gender-container {
          display: flex;
          width: 100%;

          .select-dropdown {
            width: 10rem;
            margin: 10px 0px;
            border: none !important;
            outline: none;
            padding-top: 3px;
            padding-right: 10px;
            padding-left: 22px;
            padding-bottom: 0px;
            border-radius: 50px;
            background: #fff;
            box-shadow: inset 0px 2px 6px #efefef, inset 19px 5px 11px #ffffff;
            font-size: .8rem;
            font-weight: normal;
            color: #575757 !important;
            .css-1s2u09g-control {
              border: none !important;
            }
            .css-1s2u09g-control:hover {
              border: none !important;
            }
            .css-1s2u09g-control::selection {
              border: none !important;
            }
            .css-1pahdxg-control {
              border: none !important;
              color: #fff !important;
              box-shadow: none;
            }
            .css-1pahdxg-control:hover {
              border: none !important;
            }
            .css-1pahdxg-control::selection {
              border: none !important;
            }
          }
        }
      }
      .age-slider {
        .age-details {
          margin: 15px 20px 10px 35px;
          border-top: 1px solid #ddd;
          display: flex;
          justify-content: space-between;
          label {
            font-weight: bold;
          }
        }
        .min-max-slider {
          left: 38px;
        }
      }
      .is-online {
        font-size: 16px;
        display: flex;
        font-weight: bold;
        margin-top: 10px;
        margin-left: 35px;
        margin-right: 20px;
        border-bottom: 1px solid #ddd;
        .text {
          margin-right: 30px;
          padding-right: 20px;
        }

        .react-switch-checkbox:checked {
          .react-switch-label {
            background: none;
          }
        }
      }
      .online-info-text {
        // border-top: 1px solid #ddd;
        color: #ccc;
        margin-top: 10px;
        margin-left: 35px;
        margin-right: 20px;
      }
    }
    //     *,
    // *::before,
    // *::after {
    //   box-sizing: border-box;
    // }

    // $primary: #e0e0e0;

    input[type="range"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &:focus {
        outline: none;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
      }
      &::-moz-range-thumb {
        border: none;
      }
    }

    .range-style {
      width: 400px;
      height: 60px;
      padding: 20px;
      @mixin track-style {
        display: flex;
        align-items: center;
        height: 20px;
        border-radius: 10px;
        box-shadow: inset -2px -2px 8px white,
          inset 2px 2px 8px rgba(black, 0.5);
      }
      @mixin thumb-style {
        position: relative;
        top: -50%;
        width: 40px;
        height: 40px;
        background-color: rgb(63, 131, 236);
        border-radius: 50%;
        box-shadow: -1px -1px 2px white, 1px 1px 2px rgba(black, 0.3);
      }
      &::-webkit-slider-runnable-track {
        @include track-style;
      }
      &::-webkit-slider-thumb {
        @include thumb-style;
      }
      &::-moz-range-track {
        @include track-style;
      }
      &::-moz-range-thumb {
        @include thumb-style;
      }
    }
    .fb-notifi-container {
      background: #fff;
      width: calc(100% - 50%);
      border-radius: 5%;
      padding: 30px;
      height: 38rem !important;
      .text {
        font-weight: bold;
      }
      .vaccinated {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
        label {
          margin-right: 30px;
          padding-right: 20px;
        }
      }
      .notifi-text {
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .new-matches,
      .messages,
      .message-likes,
      .super-likes,
      .in-app-vibrations,
      .in-app-sounds {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
        margin-left: 20px;
        text-transform: capitalize;
        label {
          margin-right: 30px;
          padding-right: 20px;
        }
      }
    }
  }
  .btn-group {
    display: flex;
    flex-direction: row-reverse;
    margin: 30px;
    .btn-apply,
    .btn-cancel {
      background: #1B68DF;
      border: none;
      border-radius: 20px;
      color: #fff !important;
      width: 120px;
      height: 50px;
      font-weight: bolder;
    }
    .btn-apply {
      margin-left: 20px;
    }
    .btn-apply:disabled {
      background-color: grey;
      cursor: no-drop;
    }
  }
}
.onboard-popup {
  .modal-dialog {
    width: 368px;
    height: 300px;
  }
  .modal-content {
    width: 360px !important;
    height: 300px !important;
  }
  .ok-btn {
    background: rgb(63, 131, 236);
    color: #fff;
    border-radius: 10px;
  }
  .modal-content .modal-header {
    display: flex !important;
  }
}
