.subscription-plan-details {
  .subscription-plan-details-card {
    padding: 1rem 1rem;
    margin: 1rem 1rem;
    background-color: white;
    border-radius: 10px;

    .subscription-price {
      display: flex;
      justify-content: center;
      color: $price-orange;

      .price {
        font-size: 4rem;
      }

      .validity {
        padding-top: 1rem;
      }
    }

    .plan-feature {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;

      .icon {
        color: $izi-logo-color;
        flex: 3;
        display: flex;
        justify-content: flex-end;
      }

      .text {
        flex: 6;
      }
    }

    .ant-btn-primary {
      width: 10rem;
      border-radius: 20px;
    }
  }
}
