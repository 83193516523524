@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
  .mealplan-container{
    width: 98% !important;
    margin: 0rem 0rem 0rem .2rem;
    /* padding: 20px 20px 20px 20px; */
    background: rgba(231, 243, 248, 0.85);
    border-radius: 0.5rem;
    /* border: 1px solid #ddd;*/
  }
  .mp-dashboard {
    .mp-create-section {
      font-size: 14px;
      padding: 0px !important;
    }
    .mp-search-section {
      padding: 0px !important;
      font-size: 14px;
      .select-search__input {
        border-radius: 2rem;
        padding: 10px 10px;
        font-size: 12px;
      }
      svg {
        height: 16px;
        width: 16px;
      }
    }
    .search-branded {
      margin-left: 10px !important;
      .res-book-field {
        width: 350px !important;
        .select-search__value {
          width: 350px !important;
        }
      }
    }
    .search-food-types {
      margin-left: 10px !important;
      .res-book-field {
        width: 110px !important;
      }
      .select-search__value {
        width: 110px !important;
      }
    }
    .search-food-category {
      margin-left: 1px !important;
      .res-book-field {
        width: 125px !important;
      }
      .select-search__value {
        width: 125px !important;
      }
    }
    .search-serving {
      margin-left: 1px !important;
      .res-book-field {
        width: 100px !important;
      }
      .select-search__value {
        width: 100px !important;
      }
    }
    .mp-list-items {
      padding: 0px !important;
      margin: 0px !important;
      margin-top: 10px !important;
    }
}
    .mealplan-spec {
    font-family: "Montserrat" !important;
    position: relative;
      .mp-px-4{
        padding-right: 1.5rem!important;
        padding-left: .5rem!important;
      }
      .mp-top-links{
        color: #1B68DF;
        border: 1px solid #1B68DF;
        border-radius: 50px;
        padding: 0 !important; 
        font-size: .9rem;
        width: 11rem;
        min-width: 11rem;
        max-width: 11rem;
        height:2rem;
        line-height: 1.5rem;
        cursor: pointer;
      }
      .mp-top-links-ml{
        margin-left:1rem;
      }
      .show-more{
        color: #1B68DF;
        border: 1px solid #1B68DF;
        border-radius: 50px;
        padding: 0.1rem 1.5rem 0.1rem 1.5rem!important;
        line-height: 1.5;
        font-size: 1rem;
        width: 11rem;
        margin-left: 7rem;
      }
      .show-less{
        color: #1B68DF;
        border: 1px solid #1B68DF;
        border-radius: 50px;
        padding: 0.1rem 1.5rem 0.1rem 1.5rem!important;
        line-height: 1.5;
        font-size: 1rem;
        width:12rem;
        margin-left: 0rem;
      }
      .showlabels{
        margin: .1rem;
      }
    }
    .mealplan-spec .btn-white-filter {
    background-color: white;
    box-shadow: 0px 0px 20px #4d4dff;
    border-radius: 50px;
    width: 4rem;
    color: darkblue;
    height: 2.4rem;
    font-size: 1rem;
    }
    .mealplan-spec .res-book-field .fa-search{
    font-size: 1.5rem;
    }
    .mealplan-spec .search-section {
    padding-left: 0rem !important;
    }
    .mealplan-spec .select-search__input{
    padding: 1.2rem 3rem !important;
    font-size: 12px;
    }
    .mealplan-spec .select-search {
    max-width: 17rem;
    width: 17rem;
    position: relative;
    font-family: "Montserrat" !important;
    box-sizing: border-box;
    }
    .mealplan-spec .mp-search-field {
      width: 18rem;
      margin-left: 0.5rem;
    }
    .mealplan-spec .mp-search-field .ant-input{
      padding: 1rem .5rem;
    }
    .mealplan-spec .mp-search-field .ant-input-group-addon{
      padding: 0 !important;
    }
    .mealplan-spec .mp-search-field .ant-btn-primary{
      background-color: transparent;
      border-color: transparent;
    }
    .mp-counter-align{
      padding-left: 1.5rem;
      padding-top: 0.3rem;
    }
    .mp-counter{
      width:9rem;
      .filter-selected {
        font-size: 9px;
        padding: 0px;
        .btn{
          padding: 1px !important;
          svg{
            width: 20px !important;
            height: 20px !important;
          }
        }
      }
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .mealplan-container {
    width: 98% !important;
    margin: 0rem 0rem 0rem 0.5rem;
    /* padding: 20px 20px 20px 20px; */
    background: rgb(231 243 248 / 85%);
    border-radius: 0.5rem;
  }
  .mealplan-spec {
    font-family: "Montserrat" !important;
    position: relative;
      .mp-top-links{
        color: #1B68DF;
        border: 1px solid #1B68DF;
        border-radius: 50px;
        padding: 0 !important; 
        font-size: 1.2rem;
        width: 16rem;
        min-width: 16rem;
        max-width: 16rem;
        height: 3rem;
        line-height: 2.5rem;
        cursor: pointer;
      }
      .mp-top-links-ml{
        margin-left:1rem;
      }
      .show-more{
        color: #1B68DF;
        border: 1px solid #1B68DF;
        border-radius: 50px;
        padding: 0.1rem 1.5rem 0.1rem 1.5rem!important;
        line-height: 1.5;
        font-size: 1.1rem;
        width: 14rem;
        margin-left: 7rem;
      }
      .show-less{
        color: #1B68DF;
        border: 1px solid #1B68DF;
        border-radius: 50px;
        padding: 0.1rem 1.5rem 0.1rem 1.5rem!important;
        line-height: 1.5;
        font-size: 1.1rem;
        width:14rem;
        margin-left: 0rem;
      }
      .showlabels{
        margin: .1rem;
      }
    }
  .mealplan-spec .btn-white-filter {
    background-color: white;
    box-shadow: 0px 0px 20px #4d4dff;
    border-radius: 50px;
    width: 6rem;
    color: darkblue;
    height: 3.7rem;
    font-size: 1rem;
  }
  .mealplan-spec .res-book-field .fa-search{
    font-size: 1.5rem;
  }
  .mealplan-spec .search-section {
    padding-left: 0rem !important;
  }
  .mealplan-spec .select-search__input{
    padding: 2rem 3rem !important;
    font-size: 12px;
  }
  .mealplan-spec .select-search {
    max-width: 32rem;
    width: 42rem;
    position: relative;
    font-family: "Montserrat" !important;
    box-sizing: border-box;
  }
  .mealplan-spec .mp-search-field {
    margin-left: 1.5rem;
    width: 40rem;
  }
  .mp-counter-align{
    padding-left: 2.5rem;
    padding-top: 0.3rem;
  }
  .mp-counter{
    .filter-selected {
      font-size: 12px;
      padding: 0px;
      .btn{
        padding: 1px !important;
        svg{
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }
}
