p {
  margin: 0px;
}

// button {
//   margin-top: 0px !important;
// }

.view {
  background-color: #1B68DF !important;
  color: white;
  border: none;
  padding: 3px 25px !important;
  border-radius: 25px;
  // margin-right: 80px !important;
}
.session-image-details-page{
  min-width:20rem;
  max-width: 20rem;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
  .session-image-details-page{
    min-width:18rem;
    max-width: 18rem;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .session-image-details-page{
    min-width:18rem;
    max-width: 18rem;
  }
}
