.practitioner-live-session-tab {
  .title {
    color: $darkBlueColor;
  }

  h4.title {
    margin-bottom: 0px;
    padding-bottom: 0.5rem;
  }

  .tab-button {
    cursor: pointer;

    &.selected {
      border-bottom: 4px solid $darkBlueColor;
    }
  }
  .description {
    border-radius: 1.2rem;
    resize: none;
    padding: 10px;
  }
  .add-info {
    border: 1px solid #ebebeb;
    padding: 10px 80px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .fa-plus-circle {
      color: #1c68df;
    }
  }
  .infoModal {
    width: 50vw;
  }
  .new-live-session-form {
    .ant-form-item {
      margin-bottom: 0rem;

      .live-session-input,
      .ant-picker,
      .submit-btn {
        border-radius: 3rem;
      }

      // .ant-form-item-label {
      //   label {
      //     font-size: 1.2rem;
      //   }
      // }

      .ant-picker {
        width: 20rem;
      }
    }

    .ant-switch {
      background-color: rgb(235, 235, 235);

      .ant-switch-handle::before {
        background-color: $bottom-left-circle-color;
      }

      &.ant-switch-checked {
        background-color: $bottom-left-circle-color;
        .ant-switch-handle::before {
          background-color: white;
        }
      }
    }
  }

  .practitioner-live-sessions-list {
    .section-title {
      color: grey;
    }

    .upcoming-session-item,
    .today-session-item {
      background-color: rgb(244, 244, 244);
      padding: 0.5rem 0.5rem;
      display: flex;
      flex-direction: column;
    }

    .fa-clock {
      font-size: 2rem;
      color: $dark-indigo-color;
      width: 4%;
      margin-right: 0.3rem;
    }

    .session-title {
      width: 30%;
    }

    .session-members-number {
      width: 20%;
      color: $izi-logo-color;
    }

    .session-paid {
      width: 5%;
      color: $izi-logo-color;
    }

    .btn-view,
    .approved {
      width: 11rem;
      text-align: center;
    }

    .btn-view {
      background-color: $izi-logo-color;
    }

    .btn,
    .approved {
      border-radius: 2rem;
      color: white;
      padding: 0.3rem 1.5rem;
    }

    .today-session-item {
      .session-duration {
        width: 40%;
        color: $izi-logo-color;
      }

      .session-time,
      .session-date {
        color: $izi-logo-color;
      }

      .btn-generate-link {
        background-color: $purble-color;
      }

      .approved {
        background-color: $bottom-left-circle-color;
      }
    }

    .session-time,
    .session-date-time {
      margin-left: 2.4rem;
    }

    .upcoming-session-item {
      .session-date-time {
        color: $izi-logo-color;
      }

      .session-date-time {
        flex: 1;
      }

      .btn-flex {
        flex: 3;

        .space {
          flex: 3;
        }

        .btn {
          flex: 1;
          margin-right: 6rem;
        }
      }
    }
  }
}
