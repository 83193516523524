.map-modal {
  .modal-dialog {
    font-family: "Montserrat";
    width: 45rem;
    .modal-content {
      width: 45rem;
      height: 40rem;
      .modal-header {
        display: flex !important;
        .close{
          padding: 0 !important;
          margin: 0 !important;
        }
        .modal-title {
          font-weight: normal;
        }
      }
      .modal-body {
        width: 100%;
        .map-view-container {
          position: relative;
        .map-search-box{
          display: flex;
          align-items: center;
          .remove-locationName{
            font-size: 22px;
          }
        }
          .select-dropdown {
            width: 100%;
            margin: 10px 10px;
            border: none !important;
            outline: none;
            height: 40px;
            padding-top: 11px;
            padding-right: 10px;
            padding-left: 20px;
            padding-bottom: 13px;
            border-radius: 50px;
            background: #fff;
            box-shadow: inset 0px 2px 6px #efefef, inset 19px 5px 11px #ffffff;
            font-size: .8rem;
            font-weight: normal;
            color: #575757 !important;
            .css-1s2u09g-control {
              border: none !important;
              margin-left: 20px;
              margin-top: -10px;
            }
            .css-1s2u09g-control:hover {
              border: none !important;
              margin-left: 20px;
              margin-top: -10px;
            }
            .css-1s2u09g-control::selection {
              border: none !important;
              margin-left: 20px;
              margin-top: -10px;
            }
            .css-1pahdxg-control {
              border: none !important;
              color: #fff !important;
              box-shadow: none;
              margin-left: 20px;
              margin-top: -10px;
            }
            .css-1pahdxg-control:hover {
              border: none !important;
            }
            .css-1pahdxg-control::selection {
              border: none !important;
            }
          }
          .dropdown-place {
            position: absolute !important;
            top: 38px;
            align-items: center;
            display: flex;
            list-style: none;
            position: relative;
            border: none;
            outline: none;
            padding-top: 17px;
            padding-right: 10px;
            padding-left: 20px;
            padding-bottom: 13px;
            border-radius: 13px;
            background: #fff;
            box-shadow: inset 0px 0px 7px #efefef, inset -8px -8px 8px #ffffff;
            font-size: 14px;
            font-weight: bold;
            color: #575757 !important;
            margin-left: 98px;
            width: 437px;
            bottom: 1px;
            z-index: 1000;
          }
         
          .location-name {
            box-shadow: inset 0 0 10px #eee !important;
            border: 1px solid #eee !important;
            width: 456px;
            height: 40px;
            margin-left: 16px;
            border-radius: 20px;
            font-weight: 300 !important;
          }
          .apply-button {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 10px;
            margin-right: 10px;
            position: absolute;
            right: 0px;
            top: 456px;
            .btn-apply {
              background: rgb(63, 131, 236);
              border: none;
              border-radius: 20px;
              color: #fff;
              width: 90px;
              height: 35px;
              font-weight: bolder;
            }
          }
        }
        .activity {
          display: flex;
          align-items: center;
          width: 100%;
          .icon-search {
            top: 37px;
            position: absolute;
            left: 104px;
            z-index: 1;
            font-size: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          label {
            font-size: 16px;
            font-weight: bold;
          }
          .dropdown {
            width: 100%;
            bottom: 10px;
            left: 24px;
            position: relative;
            button {
              width: 80%;
              background: #fff;
              border-radius: 50px !important;
              box-shadow: inset 8px 8px 8px #efefef, inset -8px -8px 8px #ffffff !important;
              font-weight: 300 !important;
              font-weight: 300 !important;
            }
            .dropdown-toggle::after {
              margin-left: 58% !important;
            }
            .dropdown-menu.show {
              position: absolute;
              inset: auto !important;
              margin: 0px;
              transform: none !important;
              width: 80%;
              border-radius: 10px;
              overflow-x: hidden;
              height: 400px;
              .dropdown-item {
                margin-left: 20px;
              }
            }
          }
        }
        .error-message {
          font-size: 14px;
          color: red;
          font-weight: 500;
          text-align: center;
          height: 22px;
        }
        .show-profile {
          display: flex;
          align-items: center;
          color: #1B68DF;
          font-size: 14px;
          position: relative;
          flex-direction: row-reverse;
          .round {
            position: relative;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: -5px;
          }
          
          .round label {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 50%;
            cursor: pointer;
            height: 20px;
            left: 0;
            position: absolute;
            top: 0;
            width: 20px;
          }
          
          .round label:after {
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
            content: "";
            height: 5px;
            left: 4px;
            opacity: 0;
            position: absolute;
            top: 4px;
            transform: rotate(-45deg);
            width: 10px;
          }
          
          .round input[type="checkbox"] {
            visibility: hidden;
          }
          
          .round input[type="checkbox"]:checked + label {
            background-color: #66bb6a;
            border-color: #66bb6a;
          }
          
          .round input[type="checkbox"]:checked + label:after {
            opacity: 1;
          }         
        }
        .location-text {
          font-weight: bold;
        }
      }
    }
  }
}
