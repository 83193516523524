@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
  .matched-buddy-container {
    margin: 0px;
    .match-heading {
      font-size: 20px;
    }
    .match-text {
      text-align: center;
      font-size: 14px !important;
    }

    .match-details {
      margin: 0px;
    }
  }
  .matched-buddy-container .match-details .buddy-pictures .user-pic,
  .matched-buddy-container .match-details .buddy-pictures .buddy-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top center;
  }
  .matched-buddy-container .match-details .buddy-pictures .circle {
    width: 50px;
    height: 50px;
    left: 77px;
    bottom: 25px;
  }
  .matched-buddy-container .match-details .buddy-pictures .circle img {
    width: 54px !important;
    height: 40px;
  }
  .matched-buddy-container .match-details .buddy-pictures {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .matched-buddy-container .match-details .btn-items .btn-message {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
  }
  .matched-buddy-container .match-details .swipe {
    padding: 0px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .matched-buddy-container {
      margin: 0px;
      .match-heading {
        font-size: 20px;
      }
      .match-text {
        text-align: center;
        font-size: 14px !important;
      }
  
      .match-details {
        margin: 0px;
      }
    }
    .matched-buddy-container .match-details .buddy-pictures .user-pic,
    .matched-buddy-container .match-details .buddy-pictures .buddy-pic {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      object-position: top center;
    }
    .matched-buddy-container .match-details .buddy-pictures .circle {
      width: 50px;
      height: 50px;
      left: 77px;
      bottom: 25px;
    }
    .matched-buddy-container .match-details .buddy-pictures .circle img {
      width: 54px !important;
      height: 40px;
    }
    .matched-buddy-container .match-details .buddy-pictures {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .matched-buddy-container .match-details .btn-items .btn-message {
      padding: 0px;
      margin: 0px;
      font-size: 14px;
    }
    .matched-buddy-container .match-details .swipe {
      padding: 0px;
      margin-bottom: 20px;
      text-align: center;
      font-size: 14px;
    }
  
}