.view-matches-container {
  font-family: "Montserrat";
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .view-match-layout {
    width: calc(100% - 15%) !important;
    margin: 10px 0px 20px 20px;
    padding: 20px 20px 20px 20px;
    background: #fff;
    border-radius: 2%;
    border: 1px solid #ddd;
    .headings {
      display: flex;
      justify-content: space-between;
      .settings {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          font-size: 30px;
          color: #1B68DF;
          margin-right: 10px;
        }
      }
      .live-chat {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          font-size: 30px;
          color: #1B68DF;
          margin-right: 10px;
        }
      }
    }
    .btn-container {
      text-align: center;
      margin-top: 15px;
      margin-bottom: 10px;

      .btn-matches {
        background: rgb(63, 131, 236);
        border: none;
        border-radius: 20px;
        color: #fff;
        width: 200px;
        height: 55px;
        font-weight: bolder;
      }
    }
    .pic-with-button {
      width: 100%;
      justify-content: center;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      .display-pic {
        width: 40%;
        height: 425px;
      }
      .icons-group {
        display: flex;
        justify-content: space-between;
        width: 25%;
        position: relative;
        bottom: 90px;
        .icon-back-star {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background-color: #fff;
          border-radius: 50%;
          cursor: pointer;
          position: relative;
          top: 27px;
        }
        .star {
          font-size: 30px;
          color: #1B68DF;
        }
        .icon-back-undo {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background-color: #fff;
          border-radius: 50%;
          cursor: pointer;
          position: relative;
          top: 27px;
          right: 15px;
        }
        .icon-back-undo-disabled {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background-color: #fff;
          border-radius: 50%;
          cursor: no-drop;
          position: relative;
          top: 27px;
          right: 15px;
        }
        .undo {
          transform: rotate(30deg);
          font-size: 30px;
          color: #ffdf00;
        }

        .icon-back-reject {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          background-color: #fff;
          border-radius: 50%;
          cursor: pointer;
        }
        .reject {
          font-size: 42px;
          color: #1B68DF;
          opacity: 0.9;
        }
        .icon-back-like {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          background-color: #fff;
          bottom: 40px;
          border-radius: 50%;
        }
        .like {
          font-size: 35px;
          color: #37e6c7;
          opacity: 0.9;

          cursor: pointer;
        }
      }
    }
    .person-details {
      margin: 5px;
      color: #2b607d;
      font-weight: bold;
    }
    .activity {
      margin: 5px;
      color: #2b607d;
      font-weight: bold;
      opacity: 0.4;
    }
    .about-info {
      margin: 5px;
      color: #2b607d;
      font-weight: bold;
      div {
        font-weight: 500;
      }
    }
    .interest-text {
      margin: 5px;
      color: #2b607d;
      font-weight: bold;
    }
    .interest-details {
      margin: 5px;
      color: #2b607d;
      font-weight: bold;
      display: flex;
      flex-flow: row wrap;
      width: calc(100% - 20px);
      margin-top: 10px;
      height: 100px;
      overflow-y: scroll;

      .display-interest {
        background-color: rgb(53, 126, 199);
        color: #fff;
        height: 40px;
        text-align: center;
        display: flex;
        align-items: center;
        border-radius: 25px;
        margin: 1px 5px;
        font-size: .8rem;
        div {
          margin: 10px;
        }
      }
     
    }
    ::-webkit-scrollbar {
      width: 2px !important;
    }
    ::-webkit-scrollbar-thumb {
      background-image: linear-gradient(#bbb, #aaa);
      border-radius: 3px;
    }
  }
}
