.add-interest {
  font-family: "Montserrat";
  .modal-dialog {
    width: 500px;
    height: 500px;
    .modal-content {
      width: 500px;
      height: 500px;
      .modal-header {
        display: flex !important;
        align-items: center;
        padding-bottom: 0 !important;
        .modal-title {
          font-weight: normal;
        }
        .close {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
      .modal-body {
        width: 100%;
        padding: 0px 20px;
        .search-icon{
          display: flex;
          align-items: center;
          margin-right: 1rem;
          svg{
            font-size: 20px;
            margin-right:1rem;
          }
          .select-an-activity{
            width: 100%;
          }
        }
        .select-dropdown {
          margin: 20px 0px;
          border: none !important;
          outline: none;
          height: 40px;
          padding-top: 11px;
          padding-right: 10px;
          padding-left: 20px;
          padding-bottom: 13px;
          border-radius: 50px;
          background: #fff;
          box-shadow: inset 0px 2px 6px #efefef, inset 19px 5px 11px #ffffff;
          font-size: .8rem;
          font-weight: normal;
          color: #575757 !important;
          .css-1s2u09g-control {
            border: none !important;
            margin-top: -8px;
          }
          .css-1s2u09g-control:hover{
            border: none !important;
          }
          .css-1s2u09g-control::selection{
            border: none !important;
          }
          .css-1pahdxg-control{
            border: none !important;
            color: #fff !important;
            box-shadow: none;
          }
          .css-1pahdxg-control:hover{
            border: none !important;
          }
          .css-1pahdxg-control::selection{
            border: none !important;
            margin-top: -8px;
          }
          .css-14el2xx-placeholder{
            text-align: center;
          }
        }

        .show-interest {
          ul {
            overflow-x: hidden;
            list-style-type: none;
            margin-top: 10px;
            height: 280px;
            li {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
              font-size: .8rem;
              div {
                border-radius: 20px;
                padding: 10px;
                font-weight: normal;
                color:#fff;
                border-radius: 50px;
              }
              svg {
                font-size: 24px;
                color: rgb(53, 126, 199);
              }
            }
          }
        }
      }
      .modal-footer {
        button {
          background: #1B68DF;
          border: none;
          border-radius: 20px;
          color: #fff;
          width: 90px;
          height: 35px;
          font-weight: normal;
        }
        button:hover {
          box-shadow: 5px 5px 5px #ccc;
        }
      }
    }
  }
}
.add-interest
  .modal-dialog
  .modal-content
  .modal-body
  .dropdown
  .dropdown-toggle::after {
  margin-left: 0 !important;
}
.add-interest
  .modal-dialog
  .modal-content
  .modal-body
  .dropdown
  .dropdown-menu.show
  .dropdown-item {
  margin-left: 0px !important;
}
::-webkit-scrollbar {
  width: 4px;
  height: 50px;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#bbb, #aaa);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#1B68DF, #1B68DF);
}
