.signup-user-name {
  .auth-base-layout-card {
    gap: 1rem;

    .btns-row {
      .ant-btn {
        border-radius: 50px;
      }
    }
  }
}
