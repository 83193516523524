.walkin-confirmation {
  .walkin-consultation-title {
    color: $izi-logo-color;
    font-weight: 600;
  }

  .doctor-card {
    .doctor-name {
      margin-right: 5rem;
    }
  }

  .queue-card {
    display: flex;
    flex-direction: column;

    .user-msg {
      color: $purble-color;
      font-weight: 600;
    }

    .queue {
      display: flex;
      justify-content: center;

      .queue-person {
        height: 10rem;
        margin: 0px 1rem;
      }
    }
  }

  .queue-count {
    margin-top: 2rem;
  }

  .text {
    text-align: center;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  .meet-doctor-btn {
    width: 15rem;
    border-radius: 10px;
    height: 3rem;
  }
}
