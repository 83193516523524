.dispute-center{
    &-row{

    }   
    &-col1{
        position: relative;
        margin: 5px;
        background-color: #fbfcfe;
        border-radius: 5px;
        margin-left: 0px;

        .support-tickets-heading{
            font-size: 18px;
            color: #546e7a;
            letter-spacing: 1px;
            margin-top: 15px;
        }

        .ticket-id{
            font-size: 14px;
            color: #565961;
            margin-bottom: 4px;
        }
        .line-div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 90%;
            border-top: 1px solid #edeff1;
            border-bottom: 1px solid #edeff1;
            p{
                margin-top: 1rem;
                margin-bottom: 1rem;
                color: #4b4f58;
                font-weight: 500;
            }
        }
        .complaint-1{
            margin: 20px 0px;
            &-div{
                display: flex;
                align-items: center;
            }
            &--heading{
                font-size: 14px;
                color: #4687ed;
                margin: 0;
                margin-left: 10px;
            }
            &--para{
                margin-bottom: 0;
                font-size: 14px;
                color: #585c64;
                font-weight: 500;
                margin-left: 10px;
            }
            &--description{
                padding-left: 90px;
            }
            .para1{
                color: #0f2c48;
                margin-bottom: 0;
                font-weight: 600;
                font-size: 15px;
            }
            .para2{
                margin: 0;
                color: #0f2c48;
                font-weight: 600;
                font-size: 15px;
            }
            .para3{
                margin-bottom: 10px;
                color: #0f2c48;
                font-weight: 600;
                font-size: 15px;
            }
            .para4{
                color: #64676f;
                margin-bottom: 10px;
                font-weight: 600;
                font-size: 15px;
            }
        }

        .line-div-2{
            width: 90%;
            border-top: 1px solid #edeff1;
            // border-bottom: 1px solid #edeff1;
            display: flex;
            align-items: center;
            padding: 10px 0;
        }
        .para5{
            color: #868b97;
            margin: 0;
            font-weight: 600;
            font-size: 15px;
            padding-left: 90px
        }
        .border-thick-line{
            border-bottom: 2px solid #7a7f8c;
            padding-bottom: 25px;
        }
        .comment-textarea{
            width: 100%;
            height: 185px;
            font-weight: 600;
            padding: 15px;
            border: 2px solid #f3f5f6;
            background: #f9fafe;
            outline: none;
        }
    }
    &-col2{
        margin: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        padding: 15px 0;

        &--heading{
            color: #4687ed;
            margin: 10px 0;
            font-size: 20px;
            margin-left: 10px;
        }
        .timing{
            font-size: 12px;
            color:#c4c7ca;
        }
        .book-doc-btn{
            background-color: #1B68DF;
            color: #fff;
            border: none;
            border-radius: 5px;
            padding: 6px;
            width: 88%;
            font-size: 14px;
            margin-top: 35px;
        }
        .mark-solve-btn{
            background-color: #00d664;
            color: #fff;
            border: none;
            border-radius: 3px;
            padding: 5px;
            width: 88%;
            font-size: 14px;
            margin-top: 15px;
        }
        .submit-btn{
            background-color: #1B68DF;
            color: #fff;
            border: none;
            border-radius: 2px;
            padding: 6px;
            width: 88%;
            font-size: 14px;
            margin-top: 35px;
        }
    }
    &-col3{
            margin: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #fff;
            padding: 15px 0;
            .col3--heading{
                font-size: 14px;
                color: #4687ed;
                margin: 10px 0 30px;
                text-align: center;
            }
    }
}

.rounded-circle--profile{
    border-radius: 50% !important;
    background-color: #1B68DF;
    width: 37px;
    height: 37px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.blue-color-profile{
    background-color: #1B68DF;
    width: 60px;
    height: 60px;
}

.orange-color-profile{
    background-color: #f4ba63;
    width: 60px;
    height: 60px;
}
.greenish-color-profile{
    background-color: #1c8b9a;
    width: 60px;
    height: 60px;
}

.neon-color-profile{
    background-color:#91f463;
    width: 60px;
    height: 60px;
}

.pink-color-profile{
    background-color: #f394cd;
    width: 60px;
    height: 60px;
}

.yellow-color-profile{
    background-color:#e3fe3a;
    width: 60px;
    height: 60px;
}

.darkblue-color-profile{
    background-color: #1b5081;
    width: 60px;
    height: 60px;
}

@media (min-width: 768px)
{
    .dispute-center .col-md-8 {
        flex: 0 0 66.666667%;
        max-width: 64.666667%;
    }
    .dispute-center .col-md-2 {
        flex: 0 0 16.666667%;
        max-width: 15.666667%;
    }
}

@media only screen and (max-width: 768px)  and (min-width: 577px)
{
    .dispute-center .col-sm-6 {
        flex: 0 0 50%;
        max-width: 48%;
    }
}

@media (max-width: 576px){
    .dispute-center-col2, .dispute-center-col3{
        margin: 0px !important;
    }
}

@media (max-width: 768px){
    .complaint-1--description, .dispute-center-col1 .para5{
        padding-left: 0px !important;
    }
    .dispute-center-col1 .line-div{
        flex-direction: column;
    }
    .status-div{
        position: static !important;
    }
}

.open-btn{
    background-color: #ff9800;
    border: none;
    border-radius: 3px;
    padding: 4px 15px;
    color: white;
    font-size: 12px;
}

.status-div{
    font-weight: 600;
    color: #6a707e;
    position: absolute;
    top: 25px;
    right: 10px;
}