.join-for-consultation {
  .doctor-info-card {
    .doctor-numbers {
      display: flex;

      .fa-user-friends {
        font-size: 3rem;
        margin-right: 1rem;
      }

      .patients-number,
      .patients-text {
        font-size: 1rem;
      }

      .patients-number {
        font-weight: 600;
      }
    }

    .fa-medal {
      font-size: 3rem;
    }
    .quality{
      color:#1B68DF;
    }
  }

  .page-title {
    font-weight: 600;
  }
}

.join-call-btn {
  box-shadow: none;
  width: 100%;
  height: 3rem;
  border-radius: 10px;
}

.patient-appointment-details-card {
  .fa-clock,
  .fa-dollar-sign {
    font-size: 1.5rem;
  }

  .title-text {
    font-weight: 600;
  }

  .visit-time {
    font-weight: 600;
  }

  textarea {
    width: 100%;
    height: 8rem;
  }

  .voice-record,
  .medical-report {
    display: flex;
    align-items: center;

    .fa-play-circle,
    .fa-file-pdf {
      font-size: 2rem;
    }

    .fa-play-circle {
      color: rgb(4, 174, 4);
    }

    .fa-file-pdf {
      color: red;
    }

    .text {
      margin-bottom: 0px;
      font-weight: 600;
      margin-right: 2rem;
    }
  }
}
