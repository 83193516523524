.customize-filter-doctor{
    scrollbar-width: auto;
    scrollbar-color: #fff transparent;
  	&::-webkit-scrollbar {
      width: 4px;
      background-color: #B8B8B8;
      &:horizontal {
        height: 8px;
      }
    }
    &::-webkit-scrollbar-track {
      border: 0px #fff solid;
      border-radius: 10px;
      -webkit-box-shadow: 0 0 6px #ffffff inset;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      border: 1px solid #b0b0b0;
      border-radius: 16px;
      &:hover {
        background-color: #b0b0b0;
        border: 1px solid #b0b0b0;
      }
      &:active {
        background-color: #b0b0b0;
        border: 1px solid #b0b0b0;
      }
    }
  }