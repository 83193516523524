input[type="range"] {
    --minRangePercent: 0%;
    --maxRangePercent: 0%;
    height: 1rem;
    //idth: calc(100% - 5px);
  }
  input[type="range"]:invalid {
    box-shadow: none;
  }
  
  
  .min-max-slider {
    position: relative;
    width: 250px;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .min-max-slider::focus {
    outline: none;
  }
  
  input::focus {
    outline: none;
  }
  
  .min-max-slider > label {
    display: none;
  }
  
  .min-max-slider > input {
    cursor: pointer;
    position: absolute;
  }
  
  /* webkit specific styling */
  .min-max-slider > input {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none !important;
    background: transparent;
  }
  .min-max-slider > input.min {
    background-image: linear-gradient(
      to right,
      silver 0%,
      silver var(--minRangePercent),
      orange var(--minRangePercent),
      orange 100%
    );
    border-radius: 10px 0px 0px 10px;
  }
  
  .min-max-slider > input.max {
    background-image: linear-gradient(
      to right,
      orange 0%,
      orange var(--maxRangePercent),
      silver var(--maxRangePercent),
      silver 100%
    );
    border-radius: 0px 10px 10px 0px;
  }
  
  .min-max-slider > input::-webkit-slider-runnable-track,
  .min-max-slider > input::-moz-range-track,
  .min-max-slider > input::-ms-track {
    box-sizing: border-box;
    border: none;
    background: orange;
    border-radius: 8px;
    height: 10px;
    background-color: transparent;
    background-image: linear-gradient(orange, orange),
      linear-gradient(orange, orange);
    background-size: var(--sx) 10px, calc(100% - var(--sx)) 4px;
    background-position: left center, right center;
    background-repeat: no-repeat;
  }
  
  .min-max-slider > input::focus {
    outline: none;
  }
  
  .min-max-slider > input.max::-moz-range-progress {
    background: orange;
    border-radius: 4px;
  }
  
  .min-max-slider > input.min::-moz-range-progress {
    height: 0.6em;
    background: silver;
    border-radius: 4px;
  }
  
  input[type="range"]::-webkit-slider-thumb{
    position: relative;
    top: -50%;
    width: 40px;
    height: 40px;
    background-color: rgb(63, 131, 236);
    border-radius: 50%;
    box-shadow: -1px -1px 2px white, 1px 1px 2px rgba(black, 0.3);
  }
  input[type="range"]::-moz-range-thumb {
    // -webkit-appearance: none; /* Override default look */
    // -moz-appearance: none;
    // appearance: none;
    // width: 20px; /* Set a specific slider handle width */
    // height: 20px; /* Slider handle height */
    // background: orange; /* Green background */
    // cursor: pointer; /* Cursor on hover */
    // border: none;
    // color: 1px solid orange;
    // border-radius: 50%;
    //position: relative;
    //top: -50%;
    //width: 40px;
    //height: 40px;
    //background-color: rgb(63, 131, 236);
    //border-radius: 50%;
    //box-shadow: -1px -1px 2px white, 1px 1px 2px rgba(black, 0.3);
  }
  
  .min-max-slider > input::-webkit-slider-runnable-track {
    cursor: pointer;
  }
  