.doctor-dashboard-home {
  .doctor-appointment-countdown {
    // width: 100%;
    // height: 60%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    width: 45%;
    /* height: 60%; */
    margin-right: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    p {
      color: #757773;
      text-align: center;
      font-weight: 600;
    }
  }

  .doctor-dashboard-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    justify-content: center;
    width: 100%;

    .doctor-img {
      width: 7rem;
      height: 7rem;
      object-fit: cover;
      object-position: top center;
      border-radius: 50%;
      border: 1px solid $izi-logo-color;
      padding: 0.5rem;
      margin-top: 15px;
    }

    .bio {
      text-align: center;

      img {
        width: 100%;
        border-radius: 0.7rem;
      }
    }
  }

  .upcoming-appointments-card {
    padding: 10px !important;
    align-items: center;
    max-height: 130px;
    overflow-y: auto;
    margin-top: 10px !important;
    .scheduled-appointment-item{
      & > div{
        min-width: 220px;
        white-space: nowrap;
      }
    }
    .scheduled-appointments-heading {
      color: grey;
      font-size: 14px;
      margin-bottom: 1px;
    }
    .clock-icon {
      color: #2b607d;
      width: 30px;
    }
    .patient-name {
      font-weight: 700;
     width: 40%;
    text-overflow: "----"; 
    }
    .start-time {
      color: #1B68DF;
      font-weight: 600;
      width: 150px;
    }
    .date {
      color: #1B68DF;
      font-weight: 600;
      width: 150px;
    }
    .status {
      color: #13c770;
    }
    .accept-btn {
      border: none;
      color: #fff;
      background-color: #37705b;
      border: none;
      font-size: 14px;
      width: 90px;
      border-radius: 8px;
      padding: 3px;
      margin-right: 10px;
    }
    
    .reject-btn {
      border: none;
      color: #fff;
      background-color: #b9552a;
      border: none;
      font-size: 14px;
      width: 90px;
      border-radius: 8px;
      padding: 3px;
    }

    .patient {
      display: flex;
      justify-content: space-between;
      margin-top: 0.5rem;

      .patient-name {
        font-weight: 600;
        size: 0.7rem;
      }
    }
  }

  .next-patient {
    // display: flex;
    // justify-content: space-between;
    width: 100%;
    padding: 8px !important;
    overflow-y: auto;

    .title-1 {
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 5px;
    }

    .queue-limit {
      color: #dc3545;
      font-weight: 600;
      font-size: 15px;
    }

    .title-2 {
      color: #9da09a;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0px;
      letter-spacing: 0.5px;
    }

    .title-3 {
      color: #70726e;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0px;
      letter-spacing: 0.5px;
    }

    .patient-name {
      font-weight: 600;
    }

    .appointment-reason {
      color: grey;
      margin-bottom: 0px;
      margin-top: auto;
    }

    .appointment-status {
      color: orange;
      font-weight: 600;
      margin-bottom: 0px;
    }

    .appointment-type {
      color: green;
      font-weight: 600;
      margin-bottom: 0px;
    }

    .patient-img {
      width: 7rem;
      height: 7rem;
      border-radius: 50%;
    }

    .patients-next-arr {
      .col1 {
        color: #0f0d6e;
        // width: 30%;
        min-width: 200px;
        font-weight: 600;
        margin-bottom: 0px;
      }
      .col2 {
        color: #0f0d6e;
        // width: 30%;
        min-width: 200px;
        font-weight: 600;
        margin-bottom: 0px;
      }
      .col3 {
        color: #0f0d6e;
        // width: 40%;
        min-width: 200px;
        font-weight: 600;
        margin-bottom: 0px;
      }

      .ind-patient {
        display: flex;
        margin-top: 2px;
        .patient-col1 {
          display: flex;
          align-items: center;
          color: #1B68DF;
          min-width: 200px;

          .patient-icon {
            color: #1B68DF;
            margin-right: 10px;
          }
          .patient-name--heading {
            color: #1B68DF;
            font-weight: 600;
            margin-bottom: 0;
          }
        }
        .patient-col2 {
          // width: 30%;
          font-weight: 600;
          color: #1B68DF;
          min-width: 200px;
        }
        .patient-col3 {
          // width: 40%;
          font-weight: 600;
          color: #1B68DF;
          min-width: 200px;
        }

        .accept-btn {
          border: none;
          color: #fff;
          background-color: #37705b;
          border: none;
          font-size: 14px;
          width: 90px;
          border-radius: 8px;
          padding: 4px;
          margin-right: 10px;
        }
        .btn-disabled{
          filter: grayscale(0.7);
        }
        .reject-btn {
          border: none;
          color: #fff;
          background-color: #b9552a;
          border: none;
          font-size: 14px;
          width: 90px;
          border-radius: 8px;
          padding: 4px;
          margin-right: 5px;
        }
      }
    }
  }

  .appointments-left,
  .total-appointments {
    border-radius: 10px;
    box-shadow: 0px 0px 5px #bbb;
    // padding: 1rem 1rem;
    padding: 10px;
    font-weight: 700;
    font-size: 1.4rem;
  }

  .appointments-left {
    background-color: #ddf9ea;
    display: flex;
    justify-content: space-between;

    .number-left,
    .percentage-left {
      color: rgb(1, 107, 1);
    }
  }

  .online-status-button {
    border-radius: 30px;
    width: 12rem;
    height: 4rem;
    color: white;
    // background-color: green;
    background-color: #2da88a;
    font-size: 30px;
    text-transform: uppercase;

    &.is-online {
      // background-color: red;
      background-color: #b52e03;
    }
  }

  .total-appointments {
    background-color: #fdf3f3;
    display: flex;
    flex-direction: column;

    .total-number {
      color: green;
    }

    .total-percentage {
      color: orange;
    }
  }

  .appointments-overview {
    // height: 16rem;
    height: 27rem;
    position: relative;

    .chart-watermark-cover {
      background-color: white;
      bottom: 1.5rem;
      left: 0.5rem;
    }

    .appointments-overview-chart {
      // height: 80%;
      height: 90%;
    }

    .title {
      text-align: center;
      font-weight: 600;
    }
  }
}
