.stripe-payment-checkout {
  .btn-secondary-circle-border,
  .btn-primary {
    width: 10rem;
    height: 3rem;
  }

  .card-card {
    border: 1px solid rgb(94, 94, 94);
    padding: 0.5rem;
  }
}
