.doctor-join-for-consultation {
  .join-consultation-patient-info-card {
    .fa-user,
    .fa-dollar-sign {
      font-size: 2rem;
    }

    .title-text {
      font-weight: 600;
    }
  }
}
