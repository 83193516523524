.forgot-password {
  .btn-submit {
    width: 10rem;
    border-radius: 20px;
  }
}

.reset-password-modal {
  .title {
    text-align: left;
    width: 100%;
  }

  .info-text {
    color: $izi-logo-color;
    font-weight: 600;
  }

  .modal-header {
    display: flex !important;
  }
}
