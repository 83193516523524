@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
.create-recipe {
    .form-item-suffix {
        width:20rem;
        .input-trailing {
            //margin-bottom: 0.7rem;
            margin-top: 3rem;
            margin-bottom:0;
          }
    }
    
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .create-recipe {
        .form-item-suffix {
            width:25rem;
          .input-trailing {
            margin-bottom: 0;
            margin-top: 0.5rem;
          }
        }
      }
}