.phone-signin-verify-otp {
  .auth-base-layout-card {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .user-phone-number {
      color: $izi-logo-color;
    }

    .btn-verify {
      width: 14rem;
      border-radius: 30px;
    }

    .check-icon {
      font-size: 20rem;
      svg {
        width: 10rem;
        height: 10rem;
        color: $izi-logo-color;
      }
    }
  }
}
