.live-session {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;

  .live-session-audiance-list {
    border-radius: 10px;
    height: 7%;
    min-height: 55px;

    &.expand {
      height: auto;
      flex: 1;
    }

    .toggle-list-btn {
      font-size: 1.5rem;
      padding: 0px;
      color: $izi-logo-color;
    }

    .mute-all-btn {
      background-color: lighten($color: $izi-logo-color, $amount: 30);
      width: 10rem;
      height: 2rem;
      border-radius: 1.5rem;
      padding: 0px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  video::-webkit-media-controls {
    display: none;
  }

  .video-call-card {

    .video {
      // display: grid;
      // grid-template-columns: repeat(50, minmax(20rem, 1fr));
      flex-direction: row;
      height: 100%;

      .remote-video {
        // width: 20rem;
        // flex: 1;
        max-height: 100%;

        div {
          border-radius: 0px;
        }
      }

      .two-or-less-videos-view {
        flex-wrap: nowrap;

        .remote-video {
          flex: 1;
        }

        &.has-more-than-2-videos {
          flex-direction: column;
          flex-wrap: wrap;

          .remote-video {
            flex: none;
            height: 50%;
            width: 33%;
          }
        }
      }

      .five-or-more-videos-view {
        width: 100%;

        .h-list-item {
          // width: 33%;

          .remote-video {
            height: 50%;
          }
        }
      }
    }
  }

  .live-session-video-card {
    position: relative;
    overflow: hidden;
    // padding-bottom: 140px;

    .video {
      padding: 0px;
      overflow-x: scroll;
    }

    .row {
      flex: 1 20%;
    }

    .btn-toggle-audiance-list {
      border-radius: 50%;
      background-color: rgb(240, 240, 240);
      box-shadow: 0px 0px 10px rgb(240, 240, 240);
      padding: 0px;
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      right: 8px;
      top: 8px;
      bottom: 0px;
      z-index: 3;
    }

    .call-controles {
      .btn {
        border-radius: 15px;
        box-shadow: 0px 0px 5px white;

        color: $izi-logo-color;
      }
    }
  }

  .card {
    height: 7%;
    margin-top: 1rem;
    transition: flex ease-out 0.2s;
    border: none;

    &.expand {
      height: auto;
      flex: 1;
    }

    .toggle-list-btn {
      font-size: 1.5rem;
      padding: 0px;
      color: #1B68DF;
    }

    .sent-msg--input {
      width: 100%;
    }
  }

  .doc-patient-chat {
    max-height: 70%;
    min-height: 55px;
  }

  // .doc-patient-chat {

  //   .toggle-list-btn {
  //     font-size: 1.5rem;
  //     padding: 0px;
  //     color: #1B68DF;
  //   }
  // }

  .live-session-audiance-list {
    // position: absolute;
    // top: 0px;
    // bottom: 0px;
    // right: -13rem;
    background-color: white;
    z-index: 2;
    // border: 1px solid $bottom-left-circle-color;
    // width: 12rem;
    display: flex;
    flex-direction: column;
    transition: flex ease-out 0.2s;

    // &.show {
    //   right: 0px;
    // }

    .items-list {
      display: flex;
      flex-direction: column;
      overflow: scroll;

      .audiance-list-item {
        padding: 0px 0.5rem;
        display: flex;
        align-items: center;
        margin-top: 1rem;

        .name {
          margin-bottom: 0px;
          color: grey;
          font-size: 0.9rem;
        }

        .user-img {
          width: 1.8rem;
          height: 1.8rem;
          border-radius: 50%;
          margin-right: 0.5rem;
        }

        .btn-mute {
          box-shadow: none;
          padding: 0px;
          width: 1.4rem;
          height: 1.4rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .fas {
            font-size: 0.6rem;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .video-call-card {
    .video {
      .two-or-less-videos-view {
        flex-wrap: nowrap;
      
        .remote-video {
          flex: 1;
        }
      
        &.has-more-than-2-videos {
          flex-direction: row;
          flex-wrap: wrap;
      
          .remote-video {
            flex: none;
            height: 50%;
            width: 33%;
          }
        }
      }
    }
  }
}
