@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
  .basic-food-tiles {
    .recipe-heading {
      font-size: 14px;
    }

    .select-search__input {
      border-radius: 2rem;
      padding: 20px 10px;
    }
    .search-cuisine-types {
      .res-book-field {
        width: 200px;
      }
      .select-search__value {
        width: 200px;
      }
    }
    .food-item-card {
      width: 10rem;
      height: 16rem;
      font-size: 14px;
      .food-item-img {
        height: 10rem;
      }
    }
    .list-container {
        overflow-x: hidden;
        width: 26rem;
        .loading-container { width: 100px; }
    }
    .scroll-snap-parent-x { scroll-snap-type: block; } 
    .list-container { overflow-x: scroll; } 
    .list-container::-webkit-scrollbar { height: 5px !important; } /* Track */ 
    .list-container::-webkit-scrollbar-track { background: #f1f1f1; } /* Handle */ 
    .list-container::-webkit-scrollbar-thumb { background: rgb(245, 248, 254); } /* Handle on hover */ 
    .list-container::-webkit-scrollbar-thumb:hover { background: #555; } 
    .scrollBtn { display: none; }
  }
}