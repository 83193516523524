.branded-food-tiles {
    .mp-food-item-header{
        .recipe-heading{
            font-weight: bold;
        }
    }
    .select-search__input{
        border-radius: 2rem;
        padding: 20px 10px;
      }
    .search-cuisine-types{
        .res-book-field {
            width: 200px;
        }
        .select-search__value {
            width: 200px;
          }
    }
  .food-item-card {
    width: 13rem;
    height: 15rem;
    padding: 0px;
    align-items: flex-start;
    .food-item-img{
        height: 10rem;
    }
  }
}
