.select-subscription-plan {
  display: flex;
  flex-direction: column;

  .select-subscription-plan-card {
    padding: 2rem 2rem;
    margin: 1rem 1rem;
    background-color: white;
    border-radius: 10px;
    flex: 1;

    .price-cards-list {
      display: flex;
      gap: 2rem;
      justify-content: center;
      flex-wrap: wrap;

      .subscription-price-card {
        padding: 1rem 1rem;
        display: flex;
        flex-direction: column;
        background: linear-gradient(45deg, white, $background-blue-gradient);
        border-radius: 20px;
        box-shadow: $box-shadow;
        width: 22rem;
        aspect-ratio: 1;

        .line {
          width: 100%;
          height: 1px;
          background-color: #ddd;
          margin: 1.5rem 0px;
        }

        .price-validity {
          .price {
            display: flex;
            justify-content: center;
            color: $price-orange;
            font-size: 2.5rem;
          }

          .validity {
            padding-top: 0.5rem;
            color: $price-orange;
          }
        }

        .ant-btn-primary {
          width: 10rem;
          height: 40px;
          border-radius: 20px;
        }
      }
    }
  }
}
