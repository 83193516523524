.recipe-section {
  .recipe-heading {
    font-weight: bold;
  }

  .select-search__input {
    border-radius: 2rem;
    padding: 20px 10px;
  }
  .search-cuisine-types {
    .res-book-field {
      width: 200px;
    }
    .select-search__value {
      width: 200px;
    }
  }
  .recipe-card {
    width: 13rem;
    height: 16rem;
    padding: 0px;
    align-items: flex-start;
    .recipe-img {
      height: 10rem;
    }
    .recipe-footer {
      padding: 0px;
      justify-content: flex-end;
    }
  }
}
.recipe-image{
  width:100%;
  min-height:10rem;
  max-height:10rem;
  min-width:12rem;
}
.recipes-tiles {
  .list-container {
      overflow-x: hidden;
      width: 100%;
      .loading-container { width: 100px; }
  }
  .scroll-snap-parent-x { scroll-snap-type: block; } 
  .list-container { overflow-x: scroll; } 
  .list-container::-webkit-scrollbar { height: 5px !important; } /* Track */ 
  .list-container::-webkit-scrollbar-track { background: #f1f1f1; } /* Handle */ 
  .list-container::-webkit-scrollbar-thumb { background: rgb(245, 248, 254); } /* Handle on hover */ 
  .list-container::-webkit-scrollbar-thumb:hover { background: #555; } 
  .scrollBtn { display: none; }
}
