.payment-info {
  font-family: "Montserrat";
  .payment-tab-btn {
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 3px solid transparent;
    font-size: 1.5rem;
    transition: border 0.24s ease-in-out;

    &.selected {
      border-bottom: 3px solid $selected-green-color;
    }
  }

  .font-style-custom-payment {
    td,
    th {
      padding: 0px 0.5rem;
    }
  }

  .card-payment-tab {
    height: 70vh;
  }

  .btn-primary {
    box-shadow: none;
    border-radius: 10px;
    width: 14rem;
  }

  //   .membership-payment-tab {
  //   }
  .payment-status-card {
    .mm-radio-button {
      display: flex;
      align-items: center;

      .label {
        color: grey;
      }

      .radio {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        margin-right: 0.5rem;
        background-color: rgb(168, 168, 168);
        display: flex;
        align-items: center;
        justify-content: center;

        &.selected {
          background-color: #28a745;
        }

        .far {
          color: white;
          font-size: 1.3rem;
        }
      }
    }
    .status {
      font-size: 36px;
    }
    // .sub-heading{
    //   font-size: 18px;
    // }
    .success {
      color: #1b68df;
    }
    .failed {
      color: #d30000;
      .mm-radio-button .radio.selected {
        background-color: #d30000 !important;
      }
    }
    .border-line {
      height: 1px;
      min-width: 100%;
      width: max-content;
      background-color: #1b68df;
    }
    table * {
      color: #2a71c3;
    }
    .accept-btn {
      border: none;
      color: #fff;
      background-color: #1b68df;
      border: none;
      font-size: 14px;
      width: max-content;
      min-width: 140px;
      padding: 5px 10px;
      border-radius: 15px;
      white-space: nowrap;
    }
  }
}

.payment-card {
  background-color: white;
  padding: 1rem 1rem;
  max-width: 1000px;
  margin: 0px auto;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
  border-radius: 10px;

  &.card {
    padding-left: 0px;
    padding-right: 0px;
  }

  .payment-service {
    width: 100%;
    max-width: 100px;
  }
  .mm-card {
    padding: 0px;
  }
  .description-content + .field-content {
    border-left: 3px solid #7be3fb;
  }
  .payment-container {
    max-width: 600px;
    .card-card {
      width: 100%;
      max-width: 100px;
      border: 1px solid #999a !important;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
      gap: 10px;
      padding: 10px !important;
    }
  }
  .payment-field {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    .neumorphic-input {
      min-width: 100% !important;
    }
    .field-placeholder-img {
      position: absolute;
      bottom: 10px;
      right: 25px;
      img {
        width: 35px;
      }
    }
  }
  .p-Field {
    margin: 10px 0 !important;
  }
  .payment-btn {
    width: 150px;
    background-color: #1b68df;
    outline: none;
    border: none;
    color: #fff;
    height: 50px;
    border-radius: 50px;
    box-shadow: 2px 10px 48px -18px #1b68df;
    white-space: nowrap;
    &.cancel {
      background-color: #b0b0b0;
      box-shadow: 2px 10px 48px -18px #b0b0b0;
    }
  }
  @media (max-width: 1000px) {
    & {
      gap: 20px;
    }
    .field-content {
      padding-bottom: 40px !important;
    }
    .description-content + .field-content {
      border-left: none;
      // border-bottom: 3px solid #7be3fb;
    }
    .payment-container {
      max-width: 100% !important;
    }
    .neumorphic-input {
      min-width: 100px !important;
    }
    // .field-placeholder-img{
    //  display:none !important;
    // }
  }
}
