@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
    .branded-food-tiles {
      .recipe-heading {
        font-size: 14px;
      }
  
      .select-search__input {
        border-radius: 2rem;
        padding: 20px 10px;
      }
      .search-cuisine-types {
        .res-book-field {
          width: 200px;
        }
        .select-search__value {
          width: 200px;
        }
      }
      .food-item-card {
        width: 10rem;
        height: 16rem;
        font-size: 14px;
        .food-item-img {
          height: 10rem;
        }
      }
    }
  }
  