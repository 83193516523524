@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
    .recipe_inputs {
        justify-content: space-between;

        .ant-form-item-label,
        .ant-form-item-control {
            width: 50%;
            flex: none;
            // flex-grow: 1;
            // flex-shrink: 1;
        }
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .recipe_inputs  {
        justify-content: space-between;

        .ant-form-item-label,
        .ant-form-item-control {
            width: 50%;
            flex: none;
            // flex-grow: 1;
            // flex-shrink: 1;
        }
    }

}