.create-recipe {
  .create-recipe-card {
    padding: 1rem 1rem;
    margin: 1rem 1rem;
    background-color: white;
    border-radius: 10px;

    .ant-input-affix-wrapper {
      border-radius: 20px;
    }
  }

  .ant-form-item-label {
    display: flex;
    align-items: center;

    label {
      margin-bottom: 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .ant-input,
  .ant-input-number-input,
  .ant-input-number {
    border-radius: 20px;
  }

  .recipe-image-placeholder {
    font-size: 6rem;
  }

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    gap: 1rem;

    .ant-input-number {
      width: 100%;
    }

    .input-trailing {
      width: 5rem;
    }
  }

  .block-btn {
    height: auto;
    background-color: white;
    border-radius: 10px;
    border: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .icon {
      font-size: 2rem;
    }
  }

  .ingredients-list {
    height: 300px;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0.5rem 0.5rem;
  }

  .cooking-instructions-list {
    height: 250px;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0.5rem 0.5rem;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  .form-item-suffix {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
    min-width: 35rem;
    max-width: 35rem;
    .input-trailing{
      margin-top: .5rem;
    }
    .ant-row {
      margin-bottom: 0px;
      flex: 1;
    }

    .input-trailing {
      margin-bottom: 1rem;
    }
  }

  .emirates-id-upload {
    height: 200px;
    min-height: 200px;
    aspect-ratio: 5 / 3;
  }
}

.cooking-instruction-list-item {
  display: flex;
  align-items: flex-start;
  gap: 0.7rem;
  margin-bottom: 1rem;

  &.link {
    .text {
      cursor: pointer;
      color: $izi-logo-color;
      text-decoration: underline;
    }
  }

  .number {
    border: 1px solid $selected-green-color;
    color: $selected-green-color;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    flex: 1;
    max-width: 72%;
  }
}

.recipe-totals {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .title {
    font-weight: 500;
  }

  .totals-numbers {
    flex: 2;
    max-width: 450px;

    .carbs {
      color: $carbs-color;
    }

    .protein {
      color: $protein-color;
    }

    .fats {
      color: $fats-color;
    }

    .nutrition-number {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      & > div {
        flex: 1;
      }
    }
  }

  .recipe-totals-pie-chart {
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;

    // #recipe-totals-pie-chart {
    //   width: 10rem;
    //   height: 1rem;
    // }
  }
}

.ingredient-list-item {
  display: flex;
  align-items: center;
  gap: 1rem;

  .img {
    width: 5rem;
    height: 5rem;
  }

  .ingredient-list-item-content {
    flex: 1;
    font-size: 1rem;

    .name {
      font-weight: 500;
    }
  }

  .remove-btn {
    border: 1px solid $izi-logo-color;
    color: $izi-logo-color;
    box-shadow: $box-shadow;
  }
}

.create-recipe-add-ingredient-drawer {
  .ant-drawer-content-wrapper {
    width: 550px !important;

    .drawer-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem 0rem;
      height: 100%;

      .ingredients-list {
        flex: 1;
        overflow-y: scroll;
        padding: 0rem 1rem;

        .drawer-ingredient-list-item {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-bottom: 1rem;

          .ant-row {
            margin: 0px;
          }

          .ing-img {
            width: 3rem;
            height: 3rem;
            box-shadow: $box-shadow;
          }

          .ing-name {
            flex: 1;
          }

          .ant-input-number,
          .ant-select {
            width: 8rem;
          }

          .ant-input-number {
            border-radius: 20px;
          }

          ::placeholder {
            color: black;
            opacity: 1;
          }

          .add-ing-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $izi-logo-color;
            font-size: 1.5rem;
            // border: none;
          }
        }

        .added-ing {
          .quantity {
            flex: 1;
          }
        }
      }

      .ant-input-affix-wrapper {
        border-radius: 20px;
      }
    }
  }
}

.add-cooking-instruction-modal {
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .ant-input {
      border-radius: 20px;
    }

    .ant-btn-primary {
      width: 7rem;
      border-radius: 10px;
    }
  }
}

@media (max-width: 560px) {
  .create-recipe-add-ingredient-drawer {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}
