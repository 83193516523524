@import "variables";
@import "dashboard_home";
@import "dashboard_home_rsp";
@import "dashboard_book_consultation";
@import "doctor_dashboard_home";
@import "customer_confirm_consultation";
@import "provide_consultation_info";
@import "walkin_confirmation";
@import "dashboard_prescription";
@import "create_prescription";
@import "video_screen";
@import "client_join_for_consultation";
@import "doctor_join_for_consultation";
@import "slot_management";
@import "content_manager";
@import "voucher";
@import "practitioner";
@import "corporates";
@import "permissions";
@import "dispute_center";
@import "super_admin_dashboard";
@import "live_session";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap");
@import "payment_info";
@import "clinic";
@import "_clinic_calendar_screen";
@import "forgot_password";
@import "react-big-calendar/lib/sass/styles";
@import "react-big-calendar/lib/addons/dragAndDrop/styles";
@import "_tooltip.scss";
@import "_customize_scroll.scss";
@import "_search_select.scss";
@import "_combo_card.scss";
@import "practitioner_live_session_tab";
@import "practitioner_create_live";
@import "fitness_buddy_onboarding";
@import "_switch";
@import "age_slider";
@import "fitness_buddy_discover_settings_modal";
@import "fitness_buddy_view_matches";
@import "fitness_buddy_add_interest_modal";
@import "fitness_buddy_matched_profile";
@import "fitness_buddy_live_chat";
@import "stripe_payment_checkout";
@import "customer_calendar";
@import "upload_prescription";
@import "responsivestyles_fitness_view_matches";
@import "responsiveStyles_fitness_live-chat.scss";
@import "responsivestyles_book_consulatation.scss";
@import "responsivestyles_fitness_matched_profile.scss";
@import "resposivestyles_fitness_settings.scss";
@import "responsiveStyles_VerifypatientDetails.scss";
@import "./CommonControls/top_menu_styles";
@import "./CommonControls/top_menu_resp_styles";
@import "./CommonControls/skeleton";
@import "./CommonControls/skeleton_resp";
@import "route_not_found";
@import "splash";
@import "practitioner_book_consultation";
@import "practitioner_book_consultation_resp";
//Meal Plan Starts Here
@import "MealPlans/Recipe/recipe";
@import "MealPlans/Recipe/recipe_resp";
@import "MealPlans/Shared/mp_basic";
@import "MealPlans/Shared/mp_basic_resp.scss";
@import "MealPlans/MealPlanDashboard/meal_plan_dash";
@import "MealPlans/MealPlanDashboard/meal_plan_dash_resp";
@import "MealPlans/Recipe/meal_plan_recipe_tiles";
@import "MealPlans/Recipe/meal_plan_recipe_tiles_resp";
@import "MealPlans/Food/mp_basic_food_tiles";
@import "MealPlans/Food/mp_basic_food_tiles_resp";
@import "MealPlans/Food/mp_branded_food_tiles";
@import "MealPlans/Food/mp_branded_food_tiles_resp.scss";
@import "MealPlans/Recipe/create_recipe";
@import "MealPlans/Recipe/create_recipe_resp";
//Meal Plan Ends Here
@import "home_top_doctors";
@import "./LandingPages/landing_styles";
@import "./LandingPages/landing_resp_styles";
@import "primeicons/primeicons.css";
@import "primereact/resources/themes/saga-blue/theme.css";
@import "primereact/resources/primereact.css";
@import "passwordless_signin";
@import "phone_signin";
@import "phone_signin_verify_otp";
@import "./_signup_user_name.scss";
@import "./_email_signin.scss";
@import "./_view_recipe_details.scss";
@import "./_meal_plan_onboarding.scss";
@import "./_subscription_plan_details.scss";
@import "./_select_subscription_plan.scss";
@import "./_book_dietician_appointment.scss";

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Montserrat" !important;
}
:root {
  --pending: #ddae05;
  --ongoing: #f89615;
  --failed: #da2552;
  --completed: #02880e;
  --progress: #48a6f3;
  --hold: #e002bf;
  --upcoming: #7d04ee;
  --cancelled: #6c6b6b;
  --skiped: #0468da;
}
.footer-container {
  width: 100% !important;
  margin: 0;
  padding: 20px 20px 20px 20px;
  background: #fff;
  border-radius: 1rem;
  border: 1px solid #ddd;
  p{
    font-size: 1rem;
  }
}
.izi-container {
  width: 95% !important;
  margin: 1rem 2% 2% 3%;
  padding: 20px 20px 20px 20px;
  background: #fff;
  border-radius: 1rem;
  border: 1px solid #ddd;
}
.auth-base-layout {
  background-color: $grey-background;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  //overflow-y: scroll;

  .top-right-circle,
  .bottom-left-circle {
    position: fixed;
    z-index: 0;
  }

  .layout-container {
    width: 100vw;
    height: auto;
    max-width: 100vw;
    overflow-x: hidden;
    margin: 0px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    // padding-top: 4rem;
    padding-top: 2rem;
  }

  .welcome-text {
    color: $grey-text-color;
    margin: 30px 0 10px;
    // padding-top: 20px;
  }

  .top-right-circle,
  .bottom-left-circle {
    position: fixed;
    width: $background-circles-size;
    height: $background-circles-size;
    border-radius: 50%;
    z-index: 0;
  }

  .top-right-circle {
    background-color: $top-right-circle-color;
    top: -200px;
    right: -200px;
  }

  .bottom-left-circle {
    background-color: $bottom-left-circle-color;
    bottom: -200px;
    left: -200px;
  }
  .logo-m {
    width: 200px;
  }

  .logo {
    width: 250px;
  }
  .align-quick-launch {
    width: 50rem;
  }

  .auth-base-layout-card {
    // padding-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-image: linear-gradient(to top left, $gradient-grey, white);
    // width: 45vw;
    border-radius: 20px;
    box-shadow: 5px 5px 10px #eee;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .auth-buttons {
      .auth-button {
        border: none;
        width: 4rem;
        height: 4rem;
        background-color: $blue-grey;
        border: none;
        border-radius: 2rem;
        box-shadow: 10px 10px 20px $shadow-grey;

        .fab {
          font-size: 1.5rem;
        }

        .fa-facebook-f,
        .fa-twitter,
        .fa-google {
          color: $izi-logo-color;
        }
      }
    }

    ::placeholder {
      color: $grey-text-color;
      font-weight: 600;
    }
  }
}

.neumorphic-input {
  border: none;
  outline: none;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  border-radius: 50px;
  background: #fff;
  box-shadow: inset 8px 8px 8px $shadow-grey, inset -8px -8px 8px #ffffff;
  font-size: 14px;
  font-weight: bold;
  color: #575757 !important;
}
.text-area-radius {
  border-radius: 10px !important;
}
/****************
VERTICAL TIMELINE ( BOOTSTRAP 4)
****************/
.todo-timeline{
  .timeline-text{
      display: flex;
      justify-content: space-between;

      .timeline-number{
          margin-right: 60px;
      }
  }
  .todo-title{
      font-size: 50px;
      color: #1b68df; 
      font-weight: bold;
      white-space: nowrap;
  }
  .v-timeline{
      margin-top: 30px;
      ul{
          position: relative;
      }
      li{
          list-style-type: none;
          padding: 0 0 30px;
          position: relative;
          overflow: hidden;
          .top-bold-label{
              font-size: 1.4rem;
              font-weight: bold;
              margin-bottom: .5rem;
          }
          .mid-paragraph{
              font-size: 1.4rem;
          }
          .bottom-link{
              color: #1b68df;
              font-weight: bold;
              font-size: 1.4rem;
          }
      }
      .pl-30{
          padding-left: 30px;
      }
      .v-timeline-icon{
        &:before{
          content: ' ';
          height: 27px;
          position: absolute;
          top: 0;
          left: 0;
          width: 27px;
          border-radius: 50%;
          background: #1b68df;
        }
          &:after {
              content: ' ';
              border-left: 2px solid #1b68df;
              display: inline-block;
              position: absolute;
              left: 12px;
              height: 400px;
              z-index: 400;
              top: 27px;
          }
          &.v-last{
              &:before{
                  background: #1b68df;
              }
              &:after{
                  height: 0;
              }
          }
          &.v-not{
              &:before{
                  color: #1b68df; 
              }
          }
          &.v-line-color{
              &:after{
                  border-left: 2px dashed #1b68df;
              }
          }
      }
  }
}

.container {
  position: relative;
  z-index: 1;
}
.screen-min-height {
  min-height: 50rem !important;
  justify-content: start !important;
}
.btn-primary {
  background-color: $izi-logo-color;
  box-shadow: 0px 0px 20px lighten($izi-logo-color, 15%);
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-radius: 50px !important;
  // margin-top: 1rem;
}
.btn-secondary-circle-border {
  border-radius: 50px;
  box-shadow: 0px 0px 20px lighten(grey, 15%);
}
.font-style-custom-payment {
  font-size: 1rem;
}
.margin-top-set {
  margin-top: 1rem !important;
}

.text-logo-color {
  color: $izi-logo-color;
}
.ant-drawer-body {
  padding: 0 !important;
}
.side_drawer {
  .ant-drawer-body {
    padding: 0 !important;
  }
  .mm-nav {
    overflow-y: auto;
    width: 230px;
    height: 100vh;
    position: absolute;
    background-color: transparent;
    transition: all 0.2s;
    flex-shrink: 0;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 0px 5px #aaa;

    .logo {
      width: 100%;
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;

      img {
        object-fit: contain;
      }

      .m {
        width: 6rem;
        transition: width 0.2s;
      }

      .mm {
        width: 60%;
      }
    }

    .nav-link {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.75);
      text-decoration: none;
      border-radius: 5px;
      margin-bottom: 0.5rem;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      justify-content: flex-start;
      align-items: center;
      height: 3rem;
      text-overflow: clip;
      font-family: "Montserrat";
      font-size: 16px;
      z-index: 2 !important;
      &:not(.active):not(.nav-fields):hover {
        background-color: rgba($izi-logo-color, 0.1);
      }

      &.active {
        background-color: $izi-logo-color;
        color: white;
      }

      .nav-label {
        text-overflow: clip;
        font-weight: 500;
        z-index: 2 !important;
        justify-content: left;
      }

      .nav-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        z-index: 2 !important;
      }
    }

    .region-dropdown {
      max-width: 30px;
      min-width: 50px;

      &.expanded {
        max-width: 120px;
        min-width: 80px;
      }
    }
  }
}
// .ant-drawer-body,
// .ant-drawer-wrapper-body {
//   overflow: hidden !important;
// }
// .ant-drawer .ant-drawer-content {
//   overflow: hidden !important;
// }
.live-session-image-holder{
  width:30rem;
}
.page-container {
  position: relative;
  z-index: 1;
  height: 100vh;
  overflow-y: auto !important;
  .home-logo {
    width: 90px;
    object-fit: contain;
    cursor: pointer;
  }

  .mm-nav {
    z-index: 2;
    overflow-y: auto;
    width: 250px;
    height: 100vh;
    //position: absolute;
    background-color: #fff;
    transition: all 0.2s;
    flex-shrink: 0;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 0px 5px #aaa;
    .logo {
      width: 100%;
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;

      img {
        object-fit: contain;
      }

      .m {
        width: 6rem;
        transition: width 0.2s;
      }

      .mm {
        width: 60%;
      }
    }
    .nav-link {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.75);
      text-decoration: none;
      border-radius: 5px;
      margin-bottom: 0.3rem;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      justify-content: flex-start;
      align-items: center;
      height: 2.5rem;
      text-overflow: clip;
      font-family: "Montserrat";
      font-size: 16px;
      z-index: 2 !important;
      &:not(.active):not(.nav-fields):hover {
        background-color: rgba($izi-logo-color, 0.1);
        border-radius: 50px;
      }

      &.active {
        background-color: $izi-logo-color;
        color: white;
        border-radius: 50px;
      }

      .nav-label {
        text-overflow: clip;
        font-weight: 500;
        text-align: left;
        justify-content: left;
      }

      .nav-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
      }
    }
  }
  .appointments-card-style {
    background-color: white;
    border-radius: 10px;
    min-height: 44rem;
  }
  .nav-collapsed {
    width: 70px;
    padding: 1rem 0.5rem;

    .nav-link {
      z-index: 2 !important;
      justify-content: center;
      .nav-label {
        white-space: nowrap !important;
        display: none;
      }

      .nav-icon-container {
        margin-right: 0rem !important;
      }
    }

    .logo {
      .m {
        width: 3rem;
      }

      .mm {
        display: none;
      }
    }
  }

  .main {
    height: 100%;
    overflow-x: hidden;
    // padding-top: 1rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: flex;
    flex-direction: column;
  }
}

.br-5 {
  border-radius: 5px;
}

.ml-auto {
  margin-left: auto;
}

.w-90 {
  width: 80%;
}

.shadow-none {
  box-shadow: none;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}
.nav-divider {
  @extend .divider;
  height: 1.5px !important;
  z-index: 2 !important;
}
.pl-3 {
  padding-left: 1rem;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.view-all {
  font-weight: 500;
  font-size: 0.8rem !important;
}
.section-header {
  padding-right: 1.5rem;
  min-width: 18rem;

  .title {
    margin: 0px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1rem !important;
  }

  .btn {
    margin-left: auto;
  }
}

.h-list {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  .h-list-item {
    display: inline-block;
  }
}
.verification-pending-btn {
  width: 22rem;
}
.video-card {
  position: relative;
  background-color: white;
  width: 9rem !important;
  border-radius: 20px;
  aspect-ratio: 9 / 16;
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  flex-basis: 36% !important;
  height: 16rem !important;
  min-width: 14rem;
  margin-left: 1.2rem;

  .duration {
    background-color: #f9c02e;
    position: absolute;
    color: white;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 0.8rem;
    padding: 0.4rem;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-family: Montserrat;
    font-weight: 500;
  }
  .video-card-thumbnail {
    height: 9rem;
    width: 100%;
    .thumb-img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  .timing {
    background-color: #0069d9;
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 0 10px;
    padding: 5px 10px;
    color: white;
    font-size: 0.8rem;
  }
  .live-details-button {
    background-color: #1b69df00 !important;
    color: #1b68df;
    border: 1px solid #1b68df;
    padding: 3px 25px !important;
    border-radius: 25px;
    font-size: 15px;
  }
  .session-type {
    color: #001648;
    font-size: 15px;
  }
  .video-card-headline {
    height: 35% !important;
    width: 100%;
    //overflow: hidden;
    //text-overflow: ellipsis;
    p {
      margin-bottom: 0px;
      font-weight: 500 !important;
    }
  }

  .clampText {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .title {
    font-weight: 600;
    font-size: 0.8rem;
  }

  .category {
    font-size: 0.8rem;
  }
}

.video-card-tiles {
  position: relative;
  background-color: white;
  width: 100%;
  border-radius: 9px;
  aspect-ratio: 9 / 16;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  /* flex-basis: 36% !important; */
  min-width: 220px;
  min-height: 400px;
  max-width: 350px;
  .duration {
    background-color: #f9c02e;
    position: absolute;
    color: white;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 0.8rem;
    padding: 0.4rem;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-family: Montserrat;
    font-weight: 500;
  }

  .video-card-thumbnail {
    height: 9rem;
    width: 100%;
    .thumb-img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }
  .video-card-headline {
    height: 15%;
    //overflow: hidden;
    //text-overflow: ellipsis;
    p {
      margin-bottom: 0px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 0.8rem;
  }

  .category {
    font-size: 0.8rem;
  }
}

.grid-layout {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  overflow: hidden scroll;
}
.content-card {
  width: 100%;
  height: 100%;
  min-height: min-content;
  // height: 12rem !important;
  max-width: 25rem;
  // height: 11rem;
  position: relative;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3));
  border-radius: 10px;
  font-family: "Montserrat";
  .d-flex {
    height: 100%;
  }

  &.session-img {
    aspect-ratio: 4 / 3;
  }

  .content {
    position: relative;
    padding: 1rem 1rem;
    display: unset !important;
    flex-direction: column;
    width: 65%;
    color: white;
    font-family: Montserrat;
    .category-name {
      color: #1b68df;
      margin-bottom: 0.4rem;
      font-size: 16px;
      font-weight: 600;
    }
    .category-name-yel {
      color: #f6bd27 !important;
    }

    .title {
      color: white;
      font-size: 30px;
      font-weight: 400;
    }

    .description {
      color: white;
      white-space: break-spaces;
      font-size: 15px;
      font-weight: 400;
    }

    .btn {
      padding: 0px 40px;
      width: 3rem;
      height: 2rem;
      font-size: 0.9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .pay {
      background-color: #f6bd27;
      padding: 0 55px;
      border: none !important;
    }
  }

  .item-img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    object-fit: cover;
    z-index: 0;
    filter: brightness(50%);
  }
}

.profile-btn {
  width: 3rem;
  height: auto;
  border: 1px solid $izi-logo-color;
  border-radius: 50%;
  margin-top: 0.4rem !important;
  border: 10px solid orange;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: top center;
  }
}
.user-profile-btn {
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 1rem;
  border: 1px solid $izi-logo-color;
  border-radius: 50%;
  margin-top: 0.4rem !important;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: top center;
  }
}
.paid {
  color: #67dcbc !important;
}
.notifications-and-profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .profile-doctor-img {
    width: 6rem;
    height: 6rem;
    border: 2px solid #1b68df;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: contain;
    }
  }
}

.notifications-btn {
  color: $izi-logo-color;
  position: relative;
  height: min-content;
  .notifications-count {
    position: absolute;
    background-color: rgb(255, 21, 21);
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    top: -10px;
    left: -10px;
    z-index: 1;
  }

  .far {
    font-size: 2rem;
  }
}

.ml-4 {
  margin-left: 1.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.search-input {
  background-color: white;
  display: flex;
  //   justify-content: space-between;
  align-items: center;
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 0.2rem 1rem;
  flex: 1;
  max-width: min-content;

  .far,
  .fas {
    color: $izi-logo-color;
  }

  input {
    border: none;
    border-radius: 0px;
    padding: 0.7rem 1rem;
    border-radius: 15px;
    outline: none;
    &:focus-visible,
    &:focus {
      border: none;
    }
  }
}
.use-location {
  bottom: -27px;
  right: 10px;
  color: #1b68df;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}
.more-filters {
  background: #fff;
  // border: 2px solid #ddd;
  // box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15) !important;
  //padding: 0.7rem 1rem !important;
  min-width: 240px;
  width: 240px;
  max-height: 36px !important;
  padding: 26px 10px !important;
  border-radius: 50px !important;
  .fas {
    font-size: 30px;
  }
  .more {
    color: #1b68df;
    font-weight: 500;
    font-size: 18px;
  }
}

.mm-card {
  background-color: rgb(255 255 255 / 70%);
  //box-shadow: 0px 0px 5px #bbb;
  padding: 1rem 1rem;
  border-radius: 10px;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mr-5 {
  margin-right: 2rem;
}

.mr-2 {
  margin-right: 0.7rem;
}

.mr-1 {
  margin-right: 0.4rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-auto {
  margin-right: auto;
}

.chart-watermark-cover {
  position: absolute;
  width: 4.5rem;
  height: 1.5rem;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.footer {
  bottom: 0 !important;
}
.custom-card-styling-pract {
  border: 5px solid #ebebeb !important;
  width: 14rem !important;
}
.doctor-card {
  margin-top: 1rem;

  .doctor-img {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 5px #ccc;
  }

  .fa-star {
    color: orange;
  }

  .doctor-name {
    font-weight: 600;
    margin-bottom: 0.1rem;
    margin-right: 0.6rem;
  }
  .top-doc-style {
    color: #666;
    font-size: 0.8rem;
  }

  p {
    margin: 0px;
    font-size: 0.8rem;
    color: grey;
  }
}
.glossy-style {
  background-color: rgba(255, 255, 255, 0.7);
}
.card-gap-set {
  width: auto !important;
  gap: 20px;
  height: max-content;
}
.card-tiles-font {
  font-size: 17px;
  font-weight: normal;
}
.custom-tile-card {
  gap: 40px;
  max-width: 100%;
}
.fees-align {
  margin-top: 1.4rem;
  font-weight: 600;
  font-size: 14px;
  color: #01096e;
}
.points-green-tag {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid rgb(95, 156, 2);
  color: rgb(95, 156, 2);
}
.fa-stack-extend {
  width: 1rem !important;
  height: 1rem !important;
  background-color: #1b68df;
  border-radius: 50px;
  margin-left: 1rem;
}
.backbutton-insight {
  width: 3rem;
  height: 3rem;
  background-color: #e7faad;
  border-radius: 50%;
  text-align: center !important;
}
.online-tag-align {
  gap: 5px;
  top: 10px;
  right: 20px;
  font-size: 0.8rem;
}
.quick-book {
  border-radius: 50px;
  color: #1b68df;
  border: 1px solid #1b68df;
  padding: 4px 15px;
  font-size: 0.8rem;
  background-color: #1b69df00;
}
.online-green-tag {
  width: 10px;
  height: 10px;
  background-color: #3fec84;
  border-radius: 50%;
  font-size: 0.8rem;
}
.big-online-green-tag {
  width: 30px;
  height: 30px;
  background-color: #3fec84;
  border-radius: 50%;
}
.online-text-tag {
  color: #3fec84 !important;
  display: block;
}
.offline-tag {
  @extend .online-green-tag;
  background-color: #75746d !important;
}
.away-tag {
  @extend .online-green-tag;
  background-color: #fffb02 !important;
}
.away-text-tag {
  @extend .online-text-tag;
  color: #2c2c2c !important;
}
.offline-text-tag {
  @extend .online-text-tag;
  color: #888888 !important;
}
.side-menu {
  position: fixed;
  transition: right 0.3s;
  width: 20rem;
  top: 0rem;
  right: -21rem;
  bottom: 0rem;
  background-color: white;
  box-shadow: 0px 0px 5px #aaa;
  z-index: 10;
  display: flex;
  flex-direction: column;

  .close-btn {
    margin: 1rem 1rem;
  }

  .content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 0;
    transform: scale(0.9);
  }

  &.open {
    right: 0px;
    padding: 0 !important;
  }

  .title {
    margin-bottom: 2rem;
  }
}

.notifications-side-menu {
  width: 25rem;
  right: -26rem;

  .content {
    padding: 1rem;

    .notification-side-menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-radius: 15px;
      box-shadow: 0px 0px 5px #ccc;
      padding: 1rem;
      margin-bottom: 5px;

      .details {
        font-weight: 600;
        margin-bottom: 0px;
      }

      .trailing {
        width: 20%;
        height: 100%;
        margin-left: 0.5rem;

        .date {
          color: grey;
          font-size: 0.8rem;
          text-align: center;
          white-space: nowrap;
          font-weight: 500;
        }
      }
    }
  }
}
// consultation page styles start

//for iphone

@media (min-width: 768px) {
  .consultation .col-md-6 {
    width: 45%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .consultation-section-1 {
    margin: 10px 0 20px;
  }
  .consultation-section-2 {
    margin: 10px 0 20px;
  }
}

.consultation-heading {
  font-weight: 600;
  font-size: 18px;
  margin-left: 20px;
}

.consultation-section-1 {
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  border-radius: 12px;
  padding-bottom: 1.5rem;
  //border: 3px solid #eaebee;
}
.doctor-prescription--heading {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin-top: 15px;
  margin-left: 22px;
}
.prescription-details {
  // background-color: pink;
  width: 90%;
  margin: auto;
  margin-top: 15px;
}
.pres-download-btn {
  color: #a5a8b9;
  margin-top: 20px;
  font-weight: 500;
}
.bg-peach {
  background-color: #fff7f3;
}
.pres-slip {
  margin-top: 20px;
  background-color: #fff7f3;
  border-bottom: 1px solid #c7c7c7;
}
.pres-code {
  font-size: 65px !important;
  font-weight: 500 !important;
}
.pres-detail--row {
  display: flex;
  margin-bottom: 1px;
  // flex-direction: row;
}
.doc-sign {
  width: 8rem;
  height: 8rem;
}
.pres-medicines {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 1000px) {
  .pres-key {
    width: 100%;
  }
  .pres-detail--row {
    flex-direction: column !important;
  }
}

@media (max-width: 1000px) {
  .pres-slip--heading,
  .pres-slip-address {
    width: 90% !important;
  }
}
.pres-slip--heading {
  font-size: 20px;
  font-weight: 600;
  width: 75%;
  margin: 25px auto 10px;
  text-align: center;
}
.bg-green {
  background-color: #c3e0b8;
}
.pres-slip-address {
  font-size: 18px;
  font-weight: 500;
  width: 60%;
  margin: auto;
  text-align: center;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 20px;
}
.pres-key {
  font-size: 16.5px;
  font-weight: 600;
  width: 40%;
}
.pres-value {
  // font-family: "Montserrat", sans-serif;
  display: inline-block;
  color: #3a848f;
  font-weight: 600;
  padding: 0 2px;
}
.consultation-section-2 {
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  border-radius: 12px;
  padding-bottom: 70px;
}

.doctor-details {
  // background-color: pink;
  width: 70%;
  margin: auto;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .doctor-details {
    width: 100%;
  }
}

.doctor-details--heading {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.dark-blue {
  color: $izi-logo-color;
}

.doctor-form--input {
  height: 100px;
  border-radius: 12px;
  width: 96%;
  border: 1px solid #aaa;
}

.doctor-details-text {
  display: flex;
  justify-content: space-between;
  color: #aaa;
  width: 96%;
  margin: auto;
  font-weight: 500;
}

.doctor-details-text-1 {
  font-weight: 600;
  letter-spacing: 0.4px;
  font-size: 18px;
}
.doctor-details-text-2 {
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.doctor-details-question {
  color: #aaa;
  width: 96%;
  margin: 25px auto 10px;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.4px;
  line-height: 1.2;
}
.doctor-form--input::placeholder {
  font-size: 14px;
  padding: 5px 10px;
}

.doctor-form-radio {
  font-weight: 600;
  width: 85%;
  margin: auto;
  margin-bottom: 65px;
}

.custom-btn {
  width: 80%;
  margin-top: 10px;
  border-radius: 10px;
  font-weight: 600;
  background-color: #1b68df !important;
}

.custom-btn-2 {
  width: 52%;
  margin-top: 10px;
  border-radius: 10px;
  font-weight: 600;
  background-color: #1b68df !important;
}

.doctor-form-radio {
  color: #aaa;
}

// consultation page styles end

.pie-chart {
  height: 80%;
  position: relative;

  .chart-watermark-cover {
    background-color: $grey-background;
  }

  .chart-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
    z-index: 1;

    .chart-background-cirlce {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: 100rem;
      aspect-ratio: 1;
      border-radius: 50%;
      padding: 0rem 2rem;

      .btn {
        box-shadow: none;
        width: 65%;
      }
    }

    .appointment-text {
      font-weight: 600;
      margin-bottom: 0.5rem;
      text-align: center;
    }

    .appointment-time {
      margin-bottom: 0.5rem;
      font-weight: bold;
      text-align: center;
    }

    .btn {
      border-radius: 1rem;
      width: 11rem;
      height: 3rem;
    }
  }

  #countdown-chart {
    height: 100%;
  }
}

.client-sign-in {
  .btns {
    font-size: 1rem;
    width: 20rem;
  }
}

.notification__item {
  background-color: transparent;
  border-radius: 10px;
}

.notification-item {
  padding: 1rem 1.5rem;
  color: white;
  width: 100rem;
  border-radius: 5px;
  background-color: $notification-item-background-color;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #ccc;
  position: relative;
  box-shadow: 0px 0px 10px #eee;

  .meet-doctor-notificaiton-text {
    color: $top-right-circle-color;
    font-weight: bold;
  }

  .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    color: white;
  }
}

.vid {
  border: none;
}

.accept-reject-notification-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  width: 100%;

  .info-row {
    width: 100%;
    padding: 0px 7rem;
    display: flex;
    justify-content: space-between;

    .value {
      font-weight: bold;
    }
  }

  .accept-reject-notification-btns {
    display: flex;
    justify-content: space-around;
    padding-top: 1rem;
    width: 100%;

    .btn-accept {
      background-color: $ligh-green-color;
    }

    .btn-reject {
      background-color: $light-orange-color;
    }

    .btn {
      border-radius: 5px;
      box-shadow: none;
      color: white;
      width: 10rem;
    }
  }
}

.loading-overlay {
  position: fixed;
  left: 0px;
  right: 10px;
  bottom: 0px;
  top: 0px;
  z-index: 10;
}

.filters-side-menu {
  .title {
    font-weight: 600;
    font-size: 16px;
  }
  .selectfield {
    * {
      color: #ddd;
    }
    .ant-select {
      outline: none !important;
    }
    .ant-select-selector {
      border: 1px solid #ddd !important;
      padding: 20px !important;
      border-radius: 50px !important;
    }
  }
  .btn {
    width: 7rem;
    box-shadow: none;
    border-radius: 8px;
    height: 2rem;
    padding: 0px;
  }

  .filter-checkbox {
    margin-bottom: 0.5rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .checkbox {
      width: 1.2rem;
      height: 1.2rem;
      background-color: rgb(150, 150, 150);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .far {
        color: white;
        font-size: 0.7rem;
      }
    }

    &.selected {
      .checkbox {
        background-color: $izi-logo-color;
      }
    }
  }
}

.required-field-star {
  color: red;
}

.password-field-container {
  position: relative;

  .neumorphic-input {
    width: 100%;
  }

  .show-password-btn {
    position: absolute;
    top: 0.3rem;
    right: 0.5rem;
    // bottom: 0px;
    color: grey;
  }
}

.modal-content {
  .btn {
    width: 6rem;
    // height: 2.2rem;
    // padding: 0px;
    padding: 5px;
    border-radius: 10px;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
  }
}

.dropdown-menu.show {
  transform: translate(-140px, 50px) !important;
}

.notification-container--top-center {
  max-width: 500px;
}

.card {
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}
.super-admin-doctors-list {
  gap: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: fit-content;
  flex-wrap: wrap;
  width: 800px;
  min-width: auto;
  margin-right: 50px;
  position: relative;
  padding: 50px 0;
}
.super-admin-col-1 .flex-col {
  gap: 0 !important;
  grid-gap: 0 !important;
  justify-content: space-between;
}
.super-admin-col-1 .card--custom {
  text-align: center;
}
.super-admin-col-1 .title {
  font-weight: 500;
  color: #999;
}
.row {
  width: 100%;
}
.corporate-table .items {
  padding: 12px 15px;
  justify-content: space-between !important;
  padding-left: 40px;
  padding-right: 40px;
}
.corporate-table .items * {
  flex: 0;
}
.corporate-table .prior-low,
.corporate-table .prior-high,
.corporate-table .prior-medium {
  min-width: 70px;
}
.tab_name {
  white-space: nowrap;
  font-weight: 700;
}
.corporate-table .super-admin--card {
  overflow: hidden;
  max-width: 800px;
  min-width: 800px;
  max-width: 1000px;
  margin-left: 5px;
}

.user_img {
  width: 100px;
  height: 100px;
  position: relative;
}
.user_img img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
}
.user_img div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  background-color: #58f9b6;
  right: -50px;
  border: 4px solid #fff;
}
.card_img {
  width: 100px;
  height: 80px;
}
.buttons button {
  outline: none;
  border: none;
  background-color: var(--hover);
  color: #fff;
  padding: 8px 40px;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
  white-space: nowrap;
}
.pract_card {
  padding: 10px;
  border-radius: 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  max-width: max-content;
}
.table_sub {
  font-size: 15px;
  color: #888585eb;
}
.stars {
  fill: #eea212;
  font-size: 16px;
}
.display {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.super-admin-col-1 .content {
  padding: 50px;
  gap: 20px;
}
.super-admin-col-1 .content > div:nth-child(2) {
  min-width: max-content;
  gap: 30px;
}
@media only screen and (max-width: 1200px) {
  .super-admin-col-1 .content > div:nth-child(2) {
    flex-wrap: wrap;
    justify-content: center;
  }
  .super-admin-col-1 .content > div:nth-child(2) {
    min-width: min-content !important;
  }
}
.super-admin-col-1 .loader {
  width: 300px;
}
.super-admin-col-1 .small {
  width: 250px;
}
.super-admin-col-1 .content > div:nth-child(1) {
  max-width: 600px;
}
.pract {
  position: absolute;
  top: -50px;
  left: 10px;
}
@media only screen and (max-width: 1860px) {
  .corporate-table .super-admin--card,
  .super-admin-doctors-list {
    transform: scale(0.95);
  }

  .splist {
    justify-content: center !important;
  }
  .prt-two {
    flex-wrap: inherit;
    transform: scale(0.8);
    justify-content: center;
    padding: 0 !important;
  }
  .content {
    transform: scale(0.78);
    flex-wrap: inherit !important;
    gap: 30px !important;
  }
  .super-admin-col-1 .content > div:nth-child(1) {
    grid-gap: 15px;
    gap: 15px;
  }
  .super-admin-col-1 .content {
    margin-top: -30px;
    padding: 0;
  }
  .corporate-table {
    justify-content: center !important;
  }
}
@media only screen and (max-width: 1500px) {
  .super-admin-col-1 {
    flex-wrap: wrap !important;
  }
  .prt-two {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 1000px) {
  .corporate-table .super-admin--card {
    overflow: hidden;
    min-width: 500px;
    margin-left: 5px;
  }
  .content {
    transform: scale(0.65);
  }
}
.page-container .mm-nav {
  overflow-x: hidden !important;
}

.add_new_link {
  color: #4f86dc;
  font-weight: 700;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 5px;
  cursor: pointer;
}
.add_new_link svg {
  width: 12px;
}
.relbtns {
  position: absolute;
  right: 25px;
  top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
  grid-gap: 15px;
  gap: 10px;
  font-weight: 600;
  font-size: 15px;
}
.save {
  color: #4f86dc;
  cursor: pointer;
}
.discard {
  color: red;
  cursor: pointer;
}
.search {
  font-weight: 600;
  font-size: 13px;
  color: #4f86dc;
  cursor: pointer;
}
.relbtnp {
  position: relative;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  transform: scale(0.7);
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.switch div {
  z-index: 999;
  margin-left: 70px;
  font-weight: bold;
  font-size: 18px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #56c596;
}

input:focus + .slider {
  box-shadow: 0 0 1px #56c596;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.select-img input {
  height: 30px;
}
.select-img > div {
  gap: 10px;
}
.select-img > * {
  flex: 1;
}
.image_picker {
  display: grid;
  grid-template-columns: auto auto auto;
  overflow: auto;
  height: auto;
  width: 380px;
  overflow-x: hidden;
  max-height: 240px;
  grid-gap: 10px;
  padding: 10px 0;
  background-color: #f7f3f3;
  min-height: 240px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 15px;
}
.image_picker .responsive .thumbnail {
  height: 110px !important;
  width: 110px !important;
}
.back-btn {
  position: absolute;
  left: -25px;
  top: 25px;
}

@media (max-width: 1000px) {
  .back-btn {
    top: 25px;
  }
}
.agreeNdisagree .radio {
  width: 25px !important;
  height: 25px !important;
  padding: 15px !important;
}
.tagarea .form-group {
  position: relative;
}
.tags {
  top: 50%;
  left: 20px;
  transform: translate(0%, -50%);
  gap: 10px;
  margin-top: 10px;
  overflow-y: scroll;
  max-width: 500px;
  flex-wrap: wrap;
  max-height: 30px;
}
.tag {
  background-color: #c4c4c4;
  font-size: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 3px 0 3px;
  font-weight: 500;
  max-width: max-content;
  color: #141443;
  i {
    font-size: 12px;
    cursor: pointer;
    color: red;
  }
}
.tagarea {
  position: relative;
  .active {
    background-color: #cce375;
  }
  .align-btn {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1b68df;
    padding: 20px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    * {
      font-size: 24px;
    }
  }
}
.videolist {
  display: grid;
  grid-template-columns: auto auto auto;
  overflow: auto;
  height: auto;
  width: 380px;
  overflow-x: hidden;
  max-height: 240px;
  grid-gap: 10px;
  padding: 10px 0;
  background-color: #f7f3f3;
  min-height: 240px;
  margin-top: -5px;
  justify-content: space-evenly;
}
.smallvideolist {
  height: 110px !important;
  min-height: 110px !important;
}
.selected-video {
  position: absolute;
  top: 29%;
  font-size: 40px;
  color: white;
  background: white;
  left: 24%;
}

.not_avail_class {
  cursor: crosshair;
  background-color: #a82a03 !important;
  color: #fff !important;
  pointer-events: none;
  border-color: #000 !important;
}

.pervideo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 15px;
}

.pervideo:hover {
  border: 2px solid #008cba;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .responsive {
    flex: 0 0 auto !important;
    width: 75% !important;
  }
}

.none-msg {
  font-size: 25px;
}

.none-msg {
  font-size: 25px;
}

.strt-call {
  outline: none;
  border: none;
  font-weight: 700;
  color: #fff;
  background: #1b68df;
  border-radius: 7px;
  width: auto;
  padding: 5px 15px;
  border-radius: 12px;
}
.Page-title {
  font-size: 2.5rem;
  font-family: Montserrat;
  font-weight: 600;
  white-space: nowrap;
}
.Page-title-custom {
  font-size: 1.5rem;
  font-family: Montserrat;
  font-weight: 600;
  white-space: nowrap;
  margin-top: 0.5rem;
}
.tab-title-custom {
  font-size: 1rem;
  font-family: Montserrat;
  font-weight: 600;
  white-space: nowrap;
}
.settings-custom-gap {
  gap: 2rem !important;
}
.not-found-card {
  min-height: 20rem;
  width: 30% !important;
}
.general-settings-cog {
  margin-top: 5px;
  font-size: 2rem !important;
}
.title-border {
  width: 100%;
  background: #1b68df;
  height: 5px;
  border-radius: 50px;
}
.days {
  font-size: 20px;
  color: #bfbfbf;
  font-family: Montserrat;
}
.fitness-duration {
  background-color: rgb(0, 105, 217);
  color: rgb(255, 255, 255);
  top: 0px;
  right: 0px;
  padding: 5px 15px;
  border-radius: 15px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.CircularProgressbar-path {
  stroke: #ffb946;
}
.CircularProgressbar-trail {
  stroke: #2ed47a;
}
.card-tags {
  font-size: 1.4vw;
  font-weight: 600;
}
.most-popular-scroll {
  // height: 500px;
  overflow-y: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}
.most-popular-scroll_new {
  height: 500px;
  overflow-y: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}
.scroll-snap-parent-x {
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}
.doctor-tile-gap {
  gap: 12px;
  max-width: 100%;
  margin-left: 1rem;
}
.card-list-gap {
  gap: 20px;
  max-width: 800px;
}
.scroll-snap {
  width: 100%;
  scroll-snap-align: center;
}
.scroll-snap-start {
  width: 100%;
  scroll-snap-align: start;
}
.most-upcoming-img {
  width: 120px;
  height: 120px;
  border-radius: 50px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}
.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.h-min {
  height: min-content;
}
.w-min {
  width: min-content;
}
.most-popular-tags {
  color: black;
  flex-grow: 1;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  &.active,
  &:hover {
    background-color: #f6bd27;
    color: #fff;
  }
}
.medcard {
  width: 250px;
  height: 400px;
  font-family: Montserrat;
  .texts {
    position: absolute;
    top: 30px;
    width: 100%;
    .title {
      font-weight: 700;
      color: #ffce22;
    }
    .subtitle {
      color: black;
      font-size: 10px;
    }
  }

  .background {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 25px;
  }
}
.MedDocCard {
  min-width: max-content;
  overflow: hidden;
  font-family: Montserrat;
  gap: 20px;
  .most-upcoming-img {
    max-width: 80px;
    width: 100%;
    height: 80px;
  }
  .tag {
    background-color: #eff8f1;
    border-radius: 10px;
    padding: 10px;
    min-width: 100%;
    gap: 10px;
    font-weight: 400;
  }
  .text {
    font-size: 14px;
    white-space: nowrap;
  }

  .info-parent {
    white-space: nowrap;
    font-size: 12px;
    .info {
      font-weight: 600;
    }
  }
  .book-btn {
    color: #fff;
    font-weight: 600;
    width: min-content;
    background-color: #ffce22;
    padding: 5px 10px;
    border-radius: 5px;
  }
}
.MedTrendCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: Montserrat;
  gap: 20px;
  .most-upcoming-img {
    width: 50px;
    height: 50px;
  }
  .info-parent {
    white-space: nowrap;
    font-size: 14px;
    .info {
      font-weight: 600;
    }
  }
  .icon-btn {
    gap: 10px;
    color: #fff;
    .green,
    .orange {
      background-color: #00b37c;
      padding: 0px 6px;
      border-radius: 5px;
      cursor: pointer;
    }
    .orange {
      background-color: #ffb946;
    }
    * {
      font-size: 20px;
    }
  }
}
.fitnesscard {
  & > div {
    justify-content: space-around;
  }
  i {
    font-size: 40px;
  }
}
.tabs {
  width: 100%;
  height: min-content;
  .tab {
    cursor: pointer;
    flex: 1;
    font-size: 1.5rem;
    &.active {
      span {
        font-weight: 700;
        color: #000 !important;
      }
    }
    &:not(.active) {
      color: rgb(179, 179, 179);
    }
  }
}
.time-slot-appointment {
  border: 1px solid #22274c;
  border-radius: 8px;
  background-color: #fff;
  min-width: 90px;
  .content {
    padding: 0 3px;
  }
  .header {
    background-color: #22274c;
    width: 100%;
    height: 5px;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.join-btn {
  color: #fff;
  background-color: #4a83e7;
  width: 100px;
  height: 25px;
  cursor: pointer;
}
.download-btn-appointment {
  background-color: #4a83e7;
  color: #fff !important;
  font-size: 15px;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  max-width: max-content;
}
.appointment-bold {
  font-weight: normal;
  min-width: 180px;
  font-size: 0.9rem;
}
.appointment-content {
  font-size: 0.9rem;
}
.appointment-item {
  width: 100%;
  min-width: max-content;
}
.warning-appointment {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vw;
  font-weight: bold;
}
.transform-tab {
  transform: scale(1);
}
.transform-med {
  justify-content: center;
  transform: scale(1);
}
@media only screen and (max-width: 1400px) {
  .transform-tab {
    transform: scale(0.9);
  }
  .transform-med {
    transform: scale(0.95);
  }
}
@media only screen and (max-width: 1300px) {
  .transform-tab {
    transform: scale(0.8);
    margin-top: -10%;
  }
  .transform-med {
    transform: scale(0.8);
  }
}
@media only screen and (max-width: 1100px) {
  .transform-tab {
    transform: scale(0.65);
    margin-top: -15%;
  }
  .transform-med {
    transform: scale(0.7);
  }
}
@media only screen and (max-width: 900px) {
  .transform-tab {
    transform: scale(0.6);
    margin-top: -15%;
  }
  .transform-med {
    transform: scale(0.6);
  }
}
.custom-scroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 50px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #1b68df;
  }
}
.modal_custom {
  .modal-content {
    width: max-content;
  }
  .modal.fade {
    transform: translate(-50%, 10px) !important;
  }
}
.summary-table {
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
}
.crop-btn {
  background-color: #1b68df;
  color: #fff;
  border-radius: 20px;
  width: min-content;
  padding: 0 10px;
  cursor: pointer;
}

.recipe {
  .section-head {
    font-size: 20px;
    color: #0069d9;
    font-weight: 600;
  }
  .section-content {
    font-size: 18px;
    font-weight: bold;
    padding: 5px 10px;
  }
}
.left {
  top: 40%;
  left: 0;
}
.right {
  top: 40%;
  right: 0;
}
.top {
  top: 0;
}
.bottom {
  bottom: 0px;
}
.warning-appointment {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vw;
}
.crop-btn {
  width: max-content;
  padding: 0 20px;
  background-color: #1b68df;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}
.align-btn {
  background-color: #fff;
  color: #1b68df;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  transition: all 0.5s ease;
  &:hover {
    background-color: rgb(247, 247, 247);
  }
  & > * {
    font-size: 25px;
  }
}
.banner {
  position: absolute;
  height: max-content;
  width: max-content;
  @media (max-width: 400px) {
    & {
      min-width: 100%;
    }
  }
  @media (max-width: 768px) {
    & {
      min-width: 250px;
    }
  }
  @media (min-width: 768px) {
    & {
      min-width: 400px;
    }
  }
  color: #fff;
  background-color: transparent;
  font-size: 15px;
  z-index: 2;
  padding: 10px;
  text-align: initial;
  .banner-header {
    text-align: start;
    font-size: 20px;
    margin-left: 2rem;
    font-size: 3rem;
    font-weight: bold;
  }
  .banner-text {
    margin-left: 20px;
    margin-top: 10px;
    margin-left: 2rem;
    font-size: 2rem;
    font-weight: 500;
  }
  .banner-description {
    margin-left: 20px;
    margin-top: 5px;
    margin-left: 2rem;
    font-size: 1.5rem;
  }
  .view-btn {
    background-color: #1b68df;
    color: #fff;
    border-radius: 20px;
    width: 80px;
    padding: 3px;
    margin-left: 2rem;
    margin-top: 1rem;
  }
}

.dashboard-icon-text-align {
  margin-top: 10%;
}
.paylinks-header {
  width: 4rem !important;
  padding-left: 1rem !important;
}
.paylinks-header-logo {
  width: 8rem !important;
  padding-left: 1rem !important;
}
.paylinks-logo {
  width: 8rem !important;
  padding-left: 1rem !important;
  padding-top: 1rem !important;
  //padding: 1rem 1rem;
}
.Box {
  //padding: 0 !important;
  background: rgb(255, 255, 255);
  //position: relative;
  //bottom: 0;
  width: 100%;
  margin-top: auto;
  font-family: "Montserrat" !important;
  z-index: 1;
}
@media (max-width: 1000px) {
  .Box {
    padding: 0px 0px;
  }
}
.custom-footer-link {
  color: rgb(77, 79, 80);
  font-size: 14px;
  text-decoration: none;
  padding: 0.2rem !important;
  &:hover {
    color: rgb(0, 169, 247);
    transition: 200ms ease-in;
  }
}
.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
}
.BottomContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //max-width: 1000px;
  margin: 0 auto;
  background-color: #ffffff;
  color: white !important;
}
.Column {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 0px;
}
.FooterLink {
  color: rgb(77, 79, 80);
  margin-bottom: 0.2rem;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    color: rgb(0, 169, 247);
    transition: 200ms ease-in;
  }
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #b8b8b8;
  z-index: 10001;
  -webkit-transform: translate3d(0, 0, 0);
  &:horizontal {
    height: 8px;
  }
}
::-webkit-scrollbar-track {
  border: 0px #fff solid;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 6px #ffffff inset;
}
::-webkit-scrollbar-thumb {
  background-color: #fff;
  border: 1px solid #b0b0b0;
  border-radius: 16px;
  &:hover {
    background-color: #b0b0b0;
    border: 1px solid #b0b0b0;
  }
  &:active {
    background-color: #b0b0b0;
    border: 1px solid #b0b0b0;
  }
}
::-webkit-scrollbar-thumb:hover {
  background: #1b68df;
  z-index: 1;
}

.FooterLinkSmall {
  color: rgb(77, 79, 80);
  margin-bottom: 1rem;
  font-size: 9px;
  text-decoration: none;
}
.FooterStoreButtons {
  cursor: pointer;
  object-fit: contain;
  width: 7rem !important;
}
.topBarButtonsAlign {
  margin-top: 0.5rem !important;
}
.FooterLinkBottom {
  color: rgb(0, 1, 65);
  margin-bottom: 0rem;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    color: rgb(0, 80, 253);
    transition: 200ms ease-in;
  }
  a {
    color: rgb(0, 1, 87);
  }
}
.FooterHeading {
  font-size: 24px;
  color: #fff;
  margin-bottom: 2rem;
  font-weight: bold;
}
.location-bold {
  font-size: 20px;
}
.logo-style {
  position: "relative";
  top: "35px";
  font-size: "25px";
  max-width: 10% !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .logo-style {
    position: "relative";
    top: "35px";
    font-size: "25px";
    max-width: 20% !important;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  // .logo-style{ position: "relative"; top: "35px"; font-size: "25px" ; max-width: 20% !important; }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  //.logo-style{ position: "relative"; top: "35px"; font-size: "25px" ; max-width: 20% !important; }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
  .logo-style {
    position: "relative";
    top: "35px";
    font-size: "25px";
    max-width: 20% !important;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
}
/**********
  iPad 3
  **********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  .logo-style {
    position: "relative";
    top: "35px";
    font-size: "25px";
    max-width: 20% !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .logo-style {
    position: "relative";
    top: "35px";
    font-size: "25px";
    max-width: 20% !important;
  }
}
/* iPhone 6 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  .logo-style {
    position: "relative";
    top: "35px";
    font-size: "25px";
    max-width: 20% !important;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}
.history-notifications {
  display: flex;
  justify-content: center;
  gap: 20px;
  background: #1b68df;
  width: 100%;
  white-space: nowrap;
  color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  max-width: min-content;
  align-self: start;
  margin-left: 30px;
  cursor: pointer;
  font-size: 18px;
}
.modal_bkg {
  background: #000000bf;
  display: flex !important;
  justify-content: center;
  align-items: center;
  .modal-content {
    background: #fff !important;
    padding-bottom: 50px;
  }
  .modal-dialog.modal-lg {
    width: 100vw;
  }
}
.direction-ltr {
  direction: ltr;
  text-align: left;
}
.direction-rtl {
  direction: rtl;
  text-align: right;
}
.cursor-pointer {
  cursor: pointer;
}
.setting-card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background: #fff !important;
  min-width: 60vw;
  width: auto;
  max-width: 100vw;
  min-height: 400px;
  .setting-heading {
    width: min-content;
  }
  .setting-label {
    font-weight: 600;
    flex: 2;
    text-align: start;
    white-space: nowrap;
  }
  .setting-select > * {
    width: 100%;
  }
  .setting-input {
    flex: 2;
  }

  .setting-btn {
    background-color: #1b68df;
    width: 100%;
    border-radius: 5px;
    color: #fff;
    max-width: 300px;
  }
  .btn-gray {
    background-color: #686a6c;
  }
  .settings-profile-container {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;
    width: auto;
    height: auto;
    .settings-profile {
      height: 300px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    // .row {
    // }
  }
}
.custom-profile-setting-card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background: #fff !important;
  min-width: 60vw;
  width: auto;
  max-width: 100vw;
  min-height: 400px;
  .custom-profile-setting-heading {
    width: min-content;
  }
  .custom-profile-setting-label {
    font-weight: 600;
    min-width: 160px;
    width: auto;
    text-align: start;
    white-space: nowrap;
  }
  .custom-profile-setting-select > * {
    min-width: 21rem !important;
  }
  .custom-profile-setting-input > * {
    min-width: 250px !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .custom-profile-setting-btn {
    background-color: #1b68df;
    width: 150px;
    border-radius: 50px;
    color: #fff;
  }
  .btn-gray {
    background-color: #686a6c;
  }
  .custom-settings-profile-container {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;
    min-width: 350px;
    max-width: 350px;
    width: auto;
    height: auto;
    .custom-settings-profile {
      height: 300px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    // .row {
    // }
  }
}
// CSS NOT IMPORTING
.emirates-id-image-field {
  width: 100%;
  .emirates-id-image-field-title {
    color: $izi-logo-color;
  }

  .emirates-id-image-label {
    position: relative;
    cursor: pointer;
    background-color: white;
    width: 95%;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #ccc;
    height: 12rem;
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .selected-img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      top: 0px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .emiratesID-input {
    display: none;
  }
}
.gender-select-field {
  display: flex;

  .field-label {
    width: 40%;
    font-weight: bold;
  }

  .check-box-container {
    display: flex;
  }
}

.mm-radio-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.disabled {
    .radio {
      opacity: 0.5;
    }
  }

  .label {
    color: rgb(54, 54, 54);
  }

  .radio {
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    min-height: 2.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    background-color: rgb(168, 168, 168);
    display: flex;
    align-items: center;
    justify-content: center;

    &.selected {
      background-color: $izi-logo-color;
    }

    .far {
      color: white;
      font-size: 1.3rem;
    }
  }
}

.check-box-container {
  display: flex;
}

.profile-settings-button-gap {
  gap: 40px;
}
.view_appointment {
  color: #1b68df !important;
  font-weight: 500;
  cursor: pointer;
  * {
    white-space: nowrap;
    font-size: 25px;
  }
}
.text-headlines {
  color: #1b68df !important;
  font-weight: 500;
  font-size: 20px;
  white-space: nowrap;
}
.doctor-card {
  display: flex;
  // flex-direction: column;
  //align-items: center;
  width: 100%;
  // max-width: 18rem;
  p {
    margin-bottom: 0px;
  }
  .fees-amount {
    font-size: 0.8rem;
    right: 0 !important;
  }
  .doctor-name {
    // width  margin-top: 0.5rem;
    font-weight: 500;
    color: #022360;
    white-space: pre-line;
  }
  .clampProfession {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .doctor-speciality-icon {
    color: #477da4 !important;
  }
  .doctor-speciality {
    color: #477da4;
    font-weight: 500;
    font-size: 0.8rem;
  }

  /* .fas {
    color: orange;
  } */

  .btn-primary {
    box-shadow: none;
    border-radius: 10px;
    width: 75%;
  }
  .prime-button-style {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
    border-radius: 50px !important;
    margin-top: 1rem !important;
  }

  .price {
    white-space: nowrap;
  }
  .rate {
    font-size: 12px;
    font-weight: bold;
  }
  .exp {
    font-size: 14px;
    font-weight: 500;
  }
  .card-link {
    white-space: nowrap;
    color: #1b68df;
    font-weight: normal;
    font-size: 0.8rem;
  }
  .no-slots-available {
    font-size: 0.8rem;
  }
  .day-card {
    //width: 160px;
    max-width: 160px;
    width: 100%;
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #ddd;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    &.active {
      background-color: #1b68df;
      color: #fff;
      border: none;
    }
    &.active {
      background-color: #1b68df;
      color: #fff;
      border: none;
    }
  }
  .day-slots {
    background: #fdfeff;
    border: 2px solid #1b68df;
    color: #000000;
    border-radius: 50px;
    text-align: center;
    padding: 6px 5px;
    font-size: 0.7rem;
    font-weight: normal;
    cursor: pointer;
    white-space: nowrap;
    height: max-content;
    flex: 1;
    max-width: 90px;
    &.selected {
      background: #f2f2f2;
      border: 2px solid #77d2fa;
      color: #77d2fa;
    }
  }
  .consultation {
    color: #477da4;
    font-weight: 500;
    font-size: 16px;
    .fas {
      color: #1b68df;
    }
  }
  .confirm {
    background: #2abefd;
    border-radius: 12px;
    white-space: nowrap;
    // color: #fff;
    padding: 15px 10px;
    cursor: pointer;
    height: max-content;
  }
  .about-doctor {
    font-size: 14px;
    * {
      color: rgba(0, 0, 0, 0.85) !important;
      font-weight: 500;
    }

    .far {
      font-size: 2rem;
      margin: 0px auto;
    }
  }
  .downArrow {
    .fas {
      cursor: pointer;
      color: #ddd !important;
      border: 1px solid #ddd;
      padding: 5px 8px;
      border-radius: 50%;
    }
  }
  .working-time {
    .head {
      color: rgba(0, 0, 0, 0.85) !important;
      font-weight: 700;
    }
    .tail {
      font-weight: 500;
    }
  }
  .qualifications {
    .head {
      color: rgba(0, 0, 0, 0.85) !important;
      font-weight: 700;
    }
    *:not(.head) {
      color: #1b68df !important;
    }
  }
  .blue-icons {
    * {
      color: #1b68df !important;
    }
  }
  .insurance-covered {
    background-color: #fac96e;
    color: #021d48;
    padding: 5px 10px;
    height: min-content;
    border-radius: 30px;
    white-space: pre-wrap;
    font-size: 0.8rem;
    span {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .insurance-not-covered {
    background-color: #8dc0fe;
    color: #021d48;
    padding: 5px 10px;
    height: min-content;
    border-radius: 30px;
    white-space: pre-wrap;
    font-size: 0.8rem;
    span {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .clampText {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
.payment-tag {
  width: 100px;
  border-radius: 50px !important;
  background-color: #f1f1f1;
  color: #272933;
  height: min-content;
  width: min-content;
  white-space: nowrap;
  padding: 5px;
}

.menuToggle {
  cursor: pointer;
  .fas {
    font-size: 35px;
    color: #1b68df;
    z-index: 2;
  }
}

.sign-up-otp-modal {
  .modal-dialog {
    width: 500px;
    max-width: 500px;
  }
  .neumorphic-input {
    box-shadow: inset 1px 2px 8px #efefef, inset -8px -8px 8px #ffffff;
    border-radius: 10px;
  }
  .modal-content {
    width: 500px;
    height: 500px;
  }
}
.signup-header-text {
  font-size: 1rem;
}
.scrollBtn {
  background-color: rgba(0, 0, 0, 0.089);
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 3px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-in;
  opacity: 0.4;
}
.scrollBtn:hover {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
}
.leftScroll {
  position: absolute;
  left: 0%;
}
.rightScroll {
  position: absolute;
  right: 0%;
}
.addSearchStyles {
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  background-color: rgb(255, 255, 255);
  background-color: white;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 26px 10px;
}
.filter-apply-btn {
  outline: none;
  border: none;
  width: 120px;
  background: #1b68df;
  color: #fff;
  border-radius: 12px;
  padding: 10px;
  font-size: 15px;
}
.filter-cancel-btn {
  @extend .filter-apply-btn;
  background: #9ba1aa;
}
.correctpadding {
  .select-search__input {
    padding: 26px 40px !important;
  }
}
.warning-text {
  color: #750007 !important;
}
.chat-img {
  object-fit: cover;
  width: 40px;
  height: 40px;
}

.filter-counter {
  background-color: #dbfef8;
  color: #35b3d9;
  padding: 0px 10px;
  border-radius: 30px;
  white-space: pre-wrap;
  width: 100%;
  position: absolute;
  bottom: -35px;
}
.nav-bottom-left-circle {
  width: 28rem;
  height: 22rem;
  bottom: -200px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  left: -100px;
  background-color: #1b68df;
  position: absolute;
  border-radius: 50%;
  z-index: -1;
}

.region-flag-img {
  width: 1.5rem;
  height: 1rem;
}
.location-more-filter {
  & * {
    border: 0 !important;
  }
  & *:focus {
    outline: 0 !important;
  }
  width: 100%;
  height: 36px;
  padding: 0 40px 0 16px;
  background: #fff;
  background-color: rgb(255, 255, 255);
  background-color: white;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
  background-color: white;
  display: flex;
  align-items: center;
  border: 2px solid #ddd;
  border-radius: 5px;
  padding: 26px 10px;
  .ant-select-arrow {
    display: none;
  }
}

.page-container .mm-nav {
  overflow-y: scroll;
  overflow-x: hidden;
}

.ant-picker-suffix {
  display: block !important;
}

input,
textarea {
  color: black !important;
}

.dashboard-live-session {
  .content-card {
    .content {
      .btn {
        width: 8rem;
        padding: 0.1rem 0.2rem;
      }
    }
  }
}

.h-85 {
  height: 85%;
}

.calendar-event-border {
  border: 1px dotted deepskyblue;
}

// .initial-height-set {
//   height: 19rem !important;
// }

.customer-dashboard-item-card {
  height: 16rem;
}
.generalSettings {
  .region-dropdown {
    width: 21rem;
  }
}
.hidden-top-menuitem {
  display: none !important;
}
.doctor-cards-list-pad {
  padding: 1rem;
  overflow-x: hidden;
}
.quicklinks-align {
  background-color: rgb(0 0 0 / 0%);
  margin-bottom: 2rem;
}
.p-multiselect {
  border-radius: 50px;
  min-width: 19rem;
  max-width: 19rem;
  font-family: "Montserrat", sans-serif;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 15%);
  align-items: center;
  -webkit-appearance: none;
  background: #fff;
  background-color: #fff;
  display: flex;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  outline: none;
  padding: 26px 10px;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
}
.topMenuRegion {
  .region-dropdown {
    width: 10rem;
    min-width: 10rem !important;
    margin-right: 1rem;
    margin-top: 0.5rem;
    color: #002f78;
    font-weight: bold;
    .ant-select-selector {
      border-radius: 50px !important;
      height: 50px;
      padding-top: 10px;
    }
  }
}
.topMenuLanguage {
  .language-dropdown {
    width: 10rem;
    min-width: 10rem !important;
    margin-right: 1rem;
    margin-top: 0.5rem;
    color: #002f78;
    font-weight: bold;
    .ant-select-selector {
      border-radius: 50px !important;
      height: 50px;
      padding-top: 10px;
    }
  }
}
.filter-side-menu {
  .region-dropdown {
    width: 100%;

    .ant-select-selector {
      border-radius: 50px !important;
      height: 56px;
      padding-top: 12px;
      border: none !important;
      box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 15%);
    }

    .ant-select-arrow {
      font-size: 1.6rem;
      color: $izi-logo-color;
      width: auto;
      height: auto;
      top: 40%;
      right: 20px;
    }
  }
}

.book-header,
.notifications-and-profile {
  .profile-btn {
    width: 3rem;
    height: 3rem;
  }
}

#prifle-dropdown {
  width: 3rem;
  height: 3rem;
}

.mobile-app-propmt-modal {
  .app-store-link-btn,
  .play-store-link-btn {
    width: 100%;
    display: flex;
    justify-items: center;

    a {
      width: 100%;
      text-align: center;

      img {
        width: 100%;
        max-width: 250px;
      }
    }
  }

  .close-btn {
    border-radius: 10px;
  }
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.ant-btn-primary {
  background-color: $izi-logo-color;
  border-color: $izi-logo-color;
}

.customer-session-details {
  .backbutton-insight {
    .btn {
      padding: 0.3rem;
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.btn {
  &:focus {
    box-shadow: none;
  }
}

.border-circle-input {
  border-radius: 40px;
  border: 1px solid $shadow-grey;
  padding: 0.5rem 1rem;
}

@media (max-width: 780px) {
  .customer-session-details {
    .backbutton-insight {
      .btn {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.number-input-remove-arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.text-primary {
  color: $izi-logo-color;
}
