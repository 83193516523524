.slot-management .col-12 {
  width: 98.5% !important;
}

.slot-arrangement {
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  border-radius: 12px;
  border: 3px solid #eaebee;
  padding: 5px 30px;
  margin-top: 15px;
}

.slot-heading {
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 15px;
  letter-spacing: -0.3px;
}

.day-duration {
  font-weight: 500 !important;
  display: inline-block;
  // margin-left: 10px;
  color: #181a26;
}

.slot-paragraphs {
  display: flex;
}

@media (max-width: 934px) {
  .day-duration {
    margin: 10px 0;
  }
}

@media (max-width: 800px) {
  .slot-paragraphs {
    flex-direction: column !important;
  }
  .slot-para {
    width: 100% !important;
  }
}

.slot-para {
  color: #595d7a;
  font-weight: 600;
  margin: 10px 0;
  font-size: 15px;
  width: 50%;
}

.dropdown .btn-primary {
  font-family: "Montserrat", sans-serif;
  margin-top: 25px;
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: none !important;
  border: 0px !important;
  outline: none;
  padding: 0px !important;
  font-weight: 600 !important;
}

.start-btn {
  border: 1px solid #e2e2e2;
  border-radius: 7px;
  color: #757576;
  background-color: #fff;
  margin: 0px 5px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.end-btn {
  border: 1px solid #e2e2e2;
  border-radius: 7px;
  color: #757576;
  background-color: #fff;
  margin: 0px 5px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.view-slots-btn {
  background-color: #1B68DF;
  color: #fff;
  border: 0px;
  padding: 5px 9px;
  border-radius: 8px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

// styles for table

.ant-table-wrapper {
  font-weight: 600;
}

.ant-table-thead > tr > th {
  font-weight: 600 !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  background: #fff !important;
  border: 0 !important;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background: #fff !important;
}

.ant-table-cell {
  padding: 16px 10px !important;
}

// styles for ant picker input

// .ant-picker-input > input {
//   font-weight: 600 !important;
// }

.ant-picker-suffix {
  display: none !important;
}

.modal-content {
  border-radius: 12px;
  border: 3px solid #eaebee;
}

.slot-details {
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  padding: 5px 30px;
  border-radius: 5px;
  &--heading {
    font-weight: 600;
    margin-top: 5px;
  }
  padding-bottom: 70px;
}

.slot-booking {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  &-text {
    width: 50%;
  }
  &-date {
    width: 50%;
  }
}

.btn-morning,
.btn-evening {
  border: 0;
  background-color: #fff;
  border-radius: 5px;
  width: 80%;
  padding: 7px 13px;
  text-align: left;
}

.btn-morning:hover,
.btn-evening:hover {
  background-color: #1B68DF;
  color: #fff;
}

.save-changes-btn {
  margin-top: 20px;
  margin-right: 40px;
  border-radius: 8px !important;
  padding: 7px 28px;
  font-weight: 600;
  background-color: #1B68DF !important;
  border: 0px;
  color: #fff;
  margin-bottom: 5px;
}

.allocated-time-btn {
  border-radius: 8px !important;
  padding: 7px 28px;
  font-weight: 600;
  background-color: #1B68DF !important;
  border: 0px;
  color: white;
  margin-left: 10px;
}

.p-slot-details {
  margin-top: 30px;
  font-weight: 600;
  font-size: 16px;
}

.big-btn {
  background-color: #eaf0fc;
  color: #000;
  border: 3px solid #eaebee;
  border-radius: 9px;
  margin: 20px 0;
  font-weight: 600;
  // padding: 30px 0;
  padding: 15px 10px 15px 0;
  cursor: pointer;
  width: 85%;
  font-size: 15px;
}

.time-btn {
  background-color: #7ea0d3;
  color: white;
  border-radius: 5px;
  border: 0;
  width: 85px;
  margin: 3px 5px;
  padding: 5px;
}

.mark-btn {
  background-color: #1B68DF;
  color: white;
  border-radius: 5px;
  border: 0;
  padding: 5px 5px;
  margin: 3px;
}
