.practitioner{
    
}

.prac-input{
    background-color: transparent;
    border: 1px solid black;
    outline: none;
    padding-top: 9px;
    padding-right: 10px;
    padding-left: 17px;
    padding-bottom: 9px;
    font-size: 12px;
    border-radius: 30px
}

.practitioners--col-1{
    font-family: 'Montserrat';
    .heading--dropdowns{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .dropdowns{
            display: flex;
            justify-content: space-between;
            width: 50%;
        }
    }
}

.practitioners--col-2{
    font-family: 'Montserrat';
    .onboard-prac--btn{
        border: none;
        color: #fff;
        background-color: #1B68DF;
        padding: 6px 10px;
        border-radius: 4px;
        margin-bottom: 30px;
    }

    .summary-col{
        background-color: #fff;
        &--headings{
            padding: 2px;
            padding-left: 5px;
        }
        .summary-col--block{
            display: flex;
            justify-content: space-around;
            margin-bottom: 10px;
        }
        .prac-name{
            margin: 0;
        }
        .prac-num{
            text-align: center;
            color: #8064f4;
            margin: 0;
            padding: 0;
            font-size: 25px;
            font-weight: 500;
        }
        .rounded-circle--casset{
            border-radius: 50% !important;
            background-color: #f3f3f3;
            width: 55px;
            height: 55px;
            color: grey;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .submit--btn{
        border: none;
        color: #fff;
        background-color: #1B68DF;
        width: 50%;
        padding: 5px 19px;
        margin: 15px;
        border-radius: 4px;
    }
}


.time-slot{
    font-weight: 500;
    .time-slot--row{
        display: flex;
        margin: 10px 10px;
        .time-slot--col1, .time-slot--col2{
            width: 50%;
        }
    }
    .time-slot--input{
        width: 100%;
        background-color: transparent;
        border: 1px solid black;
        outline: none;
        padding-top: 9px;
        padding-right: 10px;
        padding-left: 17px;
        padding-bottom: 9px;
        font-size: 12px;
        border-radius: 30px;
    }
}

.practitioner-modal{
    font-family: 'Montserrat';
    position: relative;
    .practitioner-modal--heading{
        text-align: center;
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 50px;
        font-size: 20px;
    }
}

.practitioner-form{
    width: 65%;
    margin: auto;
    label{
        font-weight: 600;
    }
    .cls-3{
        margin-bottom: 40px;
    }
    .label-4{
        width: 45%;
    }
    .browse-btn{
        color: #fff;
        background-color: #1B68DF;
        border: none;
        padding: 3px 11px;
        border-radius: 4px;
        height: 35px;
    }
    .upload--btn{
        color: #fff;
        background-color: #1B68DF;
        border: none;
        padding: 3px 11px;
        border-radius: 4px;
        height: 35px;
        width: 200px;
        margin-left: 35%;
    }
    .prac-modal-input{
        border: none;
        outline: none;
        padding-top: 8px;
        padding-right: 6px;
        padding-left: 12px;
        padding-bottom: 8px;
        border-radius: 15px;
        box-shadow: inset 8px 8px 8px #efefef, inset -8px -8px 8px #ffffff;
    }
}

.excel-icon{
    position: absolute;
    top: 15px;
    right: 25px;
}

.excel-pattern::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
            45deg, #000 0, #000 40px, 
            #fff 40px, #fff 80px);
}

.excel-pattern::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
        -45deg, #000 0, #000 40px, 
        #fff 40px, #fff 80px);
    mix-blend-mode: difference;
}

.download-temp--btn{
    color: #fff;
    background-color: #1B68DF;
    border: none;
    border-radius: 2px;
    margin: 4px 0;
    padding: 3px;
}

@media (max-width: 992px){
    .heading--dropdowns{
        flex-direction: column;
    }
    .practitioners--col-1 .heading--dropdowns .dropdowns {
        display: flex;
        justify-content: space-between;
        width: 200px;
        flex-direction: column;
        select{
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 992px){
    .practitioner-form{
        width: 95%;
    }
    .browse-btn{
        margin-bottom: 12px;
    }
    .voucher-fields{
        display: flex;
        flex-direction: column;
    }
    .practitioner-form .label-4{
        width: 100%;
        text-align: center;
    }
    .excel-icon{
        position: static;
    }
    .practitioner-modal--heading{
        margin-bottom: 20px !important;
    }
}