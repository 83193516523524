@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
  .live-chat-container {
    margin-bottom: 1rem;
  }
  .live-chat-container .live-chat-layout {
    margin: 0px;
    padding: 0px;
    width: 90% !important;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .live-chat-container .live-chat-layout .split-screen .left-window {
    border: none;
    width: 100%;
    height: auto;
    max-width: 100%;
  }
  .live-chat-container .live-chat-layout .split-screen .right-window {
    width: 100%;
    max-width: 100%;
    position: relative;
    bottom: 15px;
  }
  .live-chat-container
    .live-chat-layout
    .split-screen
    .left-window
    .grp-messages {
    display: none;
  }
  .live-chat-container {
    ::-webkit-scrollbar {
      width: 2px !important;
    }
    ::-webkit-scrollbar-thumb {
      background-image: linear-gradient(#bbb, #aaa);
      border-radius: 3px;
    }
  }
  .live-chat-container .live-chat-layout .split-screen {
    height: auto;
    flex-direction: column;
  }
  .live-chat-container
    .live-chat-layout
    .split-screen
    .right-window
    .sent-msg--input
    .form-grp {
    margin: 0px;
    border: 0px;
    padding: 0px;
  }
  .nm-profile .profile-btn {
    border: 2px solid #1B68DF;
    border-radius: 50%;
    margin-top: 4px !important;
    margin-left: 15px;
    width: 60%;
    height: auto;
  }

  .live-chat-container .live-chat-layout .headings .settings .title-lc,
  .live-chat-container .live-chat-layout .headings .settings svg {
    font-size: 14px;
  }
  .live-chat-container .live-chat-layout .split-screen .left-window input {
    width: 100%;
  }
  .sender-name {
    display: none;
  }
  .live-chat-container
    .live-chat-layout
    .split-screen
    .right-window
    .chat-header
    .user-profile
    .profile-container
    .profile-btn {
    width: 40px;
    height: 40px;
  }
  .live-chat-container
    .live-chat-layout
    .split-screen
    .right-window
    .chat-header
    .user-profile
    .profile-container
    .online-status {
    width: 13px;
    height: 13px;
    left: 40px;
    top: 20px;
  }
  .live-chat-container .left-messages {
    display: none !important;
  }
  .live-chat-container .right-messages {
    display: block !important;
    margin-right: 25px;
    text-transform: capitalize;
    font-weight: 500;
  }
  .live-chat-container
    .live-chat-layout
    .split-screen
    .right-window
    .chat-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .live-chat-container
    .live-chat-layout
    .split-screen
    .left-window
    .nw-matches-grp
    .profile-container
    .pc-name,
  .live-chat-container .right-messages,
  .live-chat-container .live-chat-layout .split-screen .left-window label {
    font-size: 14px;
  }
  .live-chat-container .live-chat-layout .split-screen .left-window input{
    ::placeholder{
      font-size: 14px;
    }
  }
  .live-chat-container .hide-pagination{
    display: none;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .live-chat-container {
    margin-bottom: 1rem;
  }
  .live-chat-container .live-chat-layout {
    margin: 0px;
    padding: 0px;
    width: 90% !important;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .live-chat-container .live-chat-layout .split-screen .left-window {
    border: none;
    width: 100%;
    height: auto;
    max-width: 100%;
  }
  .live-chat-container .live-chat-layout .split-screen .right-window {
    width: 100%;
    max-width: 100%;
    position: relative;
    bottom: 15px;
  }
  .live-chat-container
    .live-chat-layout
    .split-screen
    .left-window
    .grp-messages {
    display: none;
  }
  .live-chat-container {
    ::-webkit-scrollbar {
      width: 2px !important;
    }
    ::-webkit-scrollbar-thumb {
      background-image: linear-gradient(#bbb, #aaa);
      border-radius: 3px;
    }
  }
  .live-chat-container .live-chat-layout .split-screen {
    height: auto;
    flex-direction: column;
  }
  .live-chat-container
    .live-chat-layout
    .split-screen
    .right-window
    .sent-msg--input
    .form-grp {
    margin: 0px;
    border: 0px;
    padding: 0px;
  }
  .nm-profile .profile-btn {
    border: 2px solid #1B68DF;
    border-radius: 50%;
    margin-top: 4px !important;
    margin-left: 15px;
    width: 60%;
    height: auto;
  }

  .live-chat-container .live-chat-layout .headings .settings .title-lc,
  .live-chat-container .live-chat-layout .headings .settings svg {
    font-size: 14px;
  }
  .live-chat-container .live-chat-layout .split-screen .left-window input {
    width: 100%;
  }
  .sender-name {
    display: none;
  }
  .live-chat-container
    .live-chat-layout
    .split-screen
    .right-window
    .chat-header
    .user-profile
    .profile-container
    .profile-btn {
    width: 40px;
    height: 40px;
  }
  .live-chat-container
    .live-chat-layout
    .split-screen
    .right-window
    .chat-header
    .user-profile
    .profile-container
    .online-status {
    width: 13px;
    height: 13px;
    left: 40px;
    top: 20px;
  }
  .live-chat-container .left-messages {
    display: none !important;
  }
  .live-chat-container .right-messages {
    display: block !important;
    margin-right: 25px;
    text-transform: capitalize;
    font-weight: 500;
  }
  .live-chat-container
    .live-chat-layout
    .split-screen
    .right-window
    .chat-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .live-chat-container .hide-pagination{
    display: none;
  }
}
