@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
  ::-webkit-scrollbar {
    height: 0px !important;
  }
  .dash-icon-btn-group {
    font-size: 0.8rem !important;
    margin-top: 1rem !important;
    overflow-x: scroll !important;
    width: 16rem;

    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 7rem !important;

      button {
        width: 5.5rem !important;
        height: 5.5rem !important;

        .dashboard-icons-settings {
          width: 3.5rem;
        }
        .dashboard-icons-settings-mp {
          width: 2.5rem;
          // padding: 0rem !important;
        }
      }
    }
  }
  .dml-5,
  .dmx-5 {
    margin-left: 0.5rem !important;
  }
  .mr-5,
  .mx-5 {
    margin-right: 0.5rem !important;
  }
  small {
    font-size: 60% !important;
  }
  .top-doc-style {
    font-size: 0.6rem;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  ::-webkit-scrollbar {
    height: 0px !important;
  }
  .dash-icon-btn-group {
    font-size: 0.8rem !important;
    margin-top: 1rem !important;
    overflow-x: scroll !important;
    width: 16rem;
    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 7rem !important;

      button {
        width: 5.5rem !important;
        height: 5.5rem !important;

        .dashboard-icons-settings {
          width: 3.5rem;
        }
        .dashboard-icons-settings-mp {
          width: 2.5rem;
          // padding: 0rem !important;
        }
      }
    }
  }
}
