.mealplan-container {
  width: 98% !important;
  margin: 0rem 0rem 0rem 1rem;
  /* padding: 20px 20px 20px 20px; */
  background: rgba(231, 243, 248, 0.85);
  border-radius: 0.5rem;
  /* border: 1px solid #ddd;*/
}
.mp-dashboard {
  .mp-create-section {
    .browse-food {
      font-weight: 500;
    }
    .create-food-items {
      .create-buttons {
        color: #1b68df;
        cursor: pointer;
      }
    }
  }
  .mp-search-section {
    .select-search__input {
      border-radius: 2rem;
      padding: 20px 10px;
    }
    .search-branded {
      .res-book-field {
        width: 600px;
        .select-search__value {
          width: 600px;
        }
      }
    }
    .search-food-types {
      .res-book-field {
        width: 150px;
      }
      .select-search__value {
        width: 150px;
      }
    }
    .search-food-category {
      .res-book-field {
        width: 150px;
      }
      .select-search__value {
        width: 150px;
      }
    }
    .search-serving {
      .res-book-field {
        width: 150px;
      }
      .select-search__value {
        width: 150px;
      }
    }
  }
}
.mealplan-spec {
  font-family: "Montserrat" !important;
  position: relative;
  .mp-px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .mp-top-links {
    color: #1b68df;
    border: 1px solid #1b68df;
    border-radius: 50px;
    padding: 0.1rem 1.5rem 0.1rem 1.5rem !important;
    line-height: 2.5;
    width: 18rem;
    min-width: 18rem;
    max-width: 18rem;
    height: 3rem;
    cursor: pointer;
  }
  .mp-top-links-ml {
    margin-left: 2rem;
  }
  .show-more {
    color: #1b68df;
    border: 1px solid #1b68df;
    border-radius: 50px;
    padding: 0.1rem 1.5rem 0.1rem 1.5rem !important;
    line-height: 1.5;
  }
  .show-less {
    color: #1b68df;
    border: 1px solid #1b68df;
    border-radius: 50px;
    padding: 0.1rem 1.5rem 0.1rem 1.5rem !important;
    line-height: 1.5;
  }
  .showlabels {
    margin: 0.1rem;
  }
  .btn-white-filter {
    background-color: white;
    box-shadow: 0px 0px 20px #4d4dff;
    border-radius: 50px;
    width: 6rem;
    color: darkblue;
    height: 3.5rem;
    font-size: 1rem;
  }
  .mp-search-field {
    margin-left: 1.5rem;
    width: 50rem;
    .ant-input {
      display: block;
      height: 36px;
      width: 100%;
      padding: 0 40px 0 16px;
      background: #fff;
      background-color: rgb(255, 255, 255);
      border: 1px solid transparent;
      box-shadow: none;
      border-radius: 3px;
      outline: none;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      text-align: left;
      text-overflow: ellipsis;
      line-height: 36px;
      -webkit-appearance: none;
      background-color: white;
      display: flex;
      align-items: center;
      border-radius: 50px;
      padding: 26px 10px;
    }
    .ant-input-search .ant-input-affix-wrapper {
      border-top-left-radius: 50px !important;
      border-bottom-left-radius: 50px !important;
    }
    .ant-input-group {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 11px;
      font-variant: tabular-nums;
      line-height: 0.5715;
      list-style: none;
      font-feature-settings: "tnum", "tnum";
      position: relative;
      display: table;
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      border-radius: 50px;
    }
    .ant-input-group-addon {
      position: relative;
      padding: 0 1rem !important;
      color: rgba(0, 0, 0, 0.85);
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      background-color: #1b68df;
      border: 1px solid #d9d9d9;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      transition: all 0.3s;
    }
    .ant-input-affix-wrapper-lg {
      padding: 2.5px 10px;
      font-size: 16px;
      border-top-left-radius: 50px !important;
      border-bottom-left-radius: 50px !important;
    }
    .ant-btn-primary {
      font-size: 22px;
      line-height: 2px;
    }
  }
}
.mp-counter-align {
  padding-left: 2.5rem;
  padding-top: 0.3rem;
}
.mp-counter {
  .filter-selected {
    font-size: 13px;
    padding: 0px;
    .btn {
      padding: 1px !important;
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}

.recipe-card-tile {
  position: relative;
  cursor: pointer;

  .edit-recipe-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: $izi-logo-color;
    border-radius: 50%;
    color: white;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
