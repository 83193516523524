
.content-manager-heading{
    margin-top: 50px;
    margin-bottom: 25px;
    h4{
        font-size: 20px;
        letter-spacing: 0.4px;
    }
}


.content-manager-card{
    background-color: #fff;
    border-radius: 7px;
    width: 90%;
    margin-left: 0px;
    font-family: "Montserrat", sans-serif;
    padding-bottom: 15px;

    .card-heading{
        color: #464a53;
        font-size: 20px;
        margin: 20px 0;
        font-weight: 600;
    }
}

.storage-card{
    // background: rgb(33,147,120);
    // background: linear-gradient(212deg, rgba(218,245,110,1) 0%, rgba(33,147,120,1) 100%);
    background: linear-gradient(45deg, #219478 0%, #1fcba1 22%, #1B68DF 81%);
    border-radius: 5px;

    &-heading{
        font-size: 19px;
        font-weight: 600;
        color: #fff;

        padding: 34px 15px 27px;    
    }

    .progress-block{
        width: 90%;
        margin-left: 16px;
        .progress{
            border-radius: 9px;
            height: 2rem;
        }
        .progress-bar{
            background-color: #fff;
            border-radius: 9px;
        }
    }

    .storage-details{
        display: flex;
        justify-content: space-between;
        color: #fff;
        font-weight: 600;
        margin-top: 10px;
    }
}

.documents-block{
    width: 90%;
    margin-left: 16px;
    color: #fff;
    display: flex;
    font-weight: 700;
    justify-content: space-between;
    padding: 40px 0;
    font-size: 17px;

    &--img{
        display: flex;
    }
}

.rounded-circle{
    border-radius: 50%!important;
    background-color: white;
    width: 37px;
    height: 37px;
    color: #20bf98;
    display: flex;
    justify-content: center;
    align-items: center;
}

.document-detail{
    padding: 8px 10px 10px 5px;
}

.file-uploads-block{
    padding-top: 25px;
    .file-uploads-icon{
        display: flex;
        justify-content: center;
        padding: 10px;
    }

    .file-uploads-heading{
        color: #6c7280;
        font-size: 15px;
        text-align: center;
        font-weight: 600;
        padding-top: 3px;
    }
}

sup{
    font-size: 18px;
    color: red;
    top: -2px;
}

.create-btn{
    border-radius: 25px !important;
    background-color: #1B68DF;
    font-weight: 700;
    padding: 6px 0;
    width: 13rem !important;
}

.card-btn-block{
    margin-top: 25px;
    display: flex;
    .card-btn{
        border: 0;
        background-color: #4f86dc;
        color: #fff;
        border-radius: 19px;
        font-size: 16px;
        font-weight: 700;
        padding: 8px 11px;
        margin: 14px 12px;
    }
}

@media (max-width: 800px){
    .card-btn-block{
        flex-direction: column;
    }
   
}

.document-block--img{
    display: flex;
}

@media (max-width: 850px){
    .documents-block{
        flex-direction: column;
    }
    .document-block--img{
        margin-top: 15px;
    }
}

.single-feature-modal{
    
    background-color: #fff;
    font-family: 'Montserrat';
    padding: 10px 60px;
    border-radius: 5px;

    .single-feature-heading {
        color: #464a53;
        text-align: center !important;
        font-size: 22px;
        font-weight: 700;
        padding: 15px 0;
    }

    .form-label{
         color: #464a53;
         font-weight: 700;
         font-size: 14px;
    }

    .form-control{
        border-radius: 25px;
    }

    .single-feature-input{
        height: 100px;
        border-radius: 25px;
        width: 100%;
        border: 1px solid #ced4da;
    }
}

@media (min-width: 992px){
    .modal-lg, .modal-xl {
        max-width: 910px;
    }
    .image-thumbnails-block{
        margin-top: 27px;
    }
}

.upload-btn-img{
    border-radius: 25px !important;
    background-color: #1B68DF;
    font-weight: 700;
    padding: 6px 0;
    width: 13rem !important;
}

 .upload-Div{
        margin-top: 160px;
}

@media (max-width: 992px){
    .feature-col-2{
        margin-top: 30px;
    }
    .upload-Div{
        margin-top: 25px !important;
    }
}



// .modal-header{
//     display: block !important;
// }

.modal-content{
    border: none;
}

.close{
    position: absolute !important;
    right: 20px !important;
    top: 20px !important;
}

.image_picker{
    display: grid;
    grid-auto-columns: 3;
    grid-template-columns: auto auto auto;
    overflow:hidden !important;
}


@media (max-width: 450px){
    .image_picker{
        display: grid;
        grid-auto-columns: 2;
        grid-template-columns: auto auto;
    }
}

.image_picker .responsive{
    margin: 0px !important;
}

.image_picker .responsive .thumbnail{
    border: 0;
    border-radius: 10px;
    height: 125px !important;
    width: 125px !important;
    padding: 3px !important;
}


// image preview uploads
.emirates-id-image-field {
    .image-preview{
        background-color: #ebe9e9;
        position: relative;
        // cursor: pointer;
        // width: 100%;
        width: 109%;
        border-radius: 36px;
        box-shadow: 0px 0px 5px #ccc;
        height: 310px;
        padding: 1rem;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    
        .selected-img{
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            top: 0px;
            object-fit: cover;
            border-radius: 10px;
        }
    }
}

.content-textarea{
    outline: none;
    padding: 6px;
}

.ant-select-selector{
    border-radius: 25px !important;
    padding: 10px;
    // margin-bottom: 15px;
}


.plus-icons--block{
    // background-color: pink;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.plus-line{
    width: 200px;
    height: 2px;
    // background-color: #8fa7b8;
    margin: 1px 15px 0px;
}

.red-plus{
    color: #cb6161;
    cursor: pointer;
}

.green-plus{
    color: #54bf99;
    cursor: pointer;
}

.features-header{
    color: #4b4f58;
    display: flex;
    font-weight: 700;
    justify-content: space-between;
    margin-bottom: 30px;
    font-size: 14px;
}

@media (max-width: 992px){
    .features-header{
        display: none;
    }
}


.cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dropzone{
    // flex: 1;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // padding: 20px;
    // border-width: 2px;
    // border-radius: 2px;
    // border-color: #eeeeee;
    // border-style: dashed;
    // background-color: #fafafa;
    // color: #bdbdbd;
    // outline: none;
    // transition: border .24s ease-in-out;


    background-color: #ebe9e9;
    position: relative;
    width: 109%;
    border-radius: 36px;
    box-shadow: 0px 0px 5px #ccc;
    height: 310px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p{
        color: #179de8;
        font-weight: 700;
        margin-bottom: 5px;
    }
    .upload--icon{
        font-size: 82px;
        color: #179de8;
        margin: 10px 0 0px;
    }

    .or-block{
        
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        .or-line{
            width: 40%;
            border-bottom: 3px solid #a5a5a5;
        }
        .or-text{
            color: #179de8;
            font-weight: 700;
            margin: 0 10px;
        }
    }
}

.browse-btn{
    border: 2px solid #2079d2;
    border-radius: 25px;
    color: #179de8;
    font-size: 14px;
    font-weight: 600;
    width: 195px;
    height: 33px;
    margin-top: 25px;
}



.dropbox-files {
    background-color: #f5f3f3;
    border-radius: 20px;
    width: 100%;
    margin: 15px 0;
    padding: 10px 5px 10px 0;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    .table{
        color: #464a53;
        tbody{
            tr:nth-child(odd) {
                background-color: #fff;
            }
        }
        tr{
            font-weight: 600;
            font-size: 14px;
        }
        td{
            span{
                flex:0.5;
            }
        }

    }
    .table td, .table th{
        border: 0 !important;
    }
    .delete--icon{
        color: #e70e26;
        margin: 0 10px;
        cursor: pointer;
    }
}

