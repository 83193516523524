.comboCard {
  height: max-content;
  .content-card {
    max-height: 13rem !important;
    min-width: 28rem !important;
    .content {
      display: flex;
      justify-content: center;
      width: 65%;
      color: white;
      transform: none !important;
      z-index: 1;
      flex-direction: column;
      gap: 0 !important;
      transform: none !important;
      padding: 20px !important;
      .btn {
        height: 1.2rem !important;
      }
    }
  }
  .video-card {
    height: 12rem;
    width: 10rem !important;
    border-bottom: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    // border-bottom-left-radius: 0 !important;
    // border-bottom-right-radius: 0;
    border-radius: 8px !important;
    .duration {
      border-radius: 15px !important;
      border-top-right-radius: 0 !important;
    }
    .video-card-thumbnail {
      height: 80% !important;
    }
    title {
      font-size: 1.2rem !important;
    }
    .activity {
      font-size: 0.8rem !important;
    }
  }
}
