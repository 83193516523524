@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap");
:root {
  --icons: #0b2236;
  --hover: #1B68DF;
  --body: #f2f5fa;
}
.super-admin{

    &--header{
        display: flex;
        align-items: center;
    }

    .admin-btns{
        margin: 0 10px;
        
        .bk-doct, .send-msg{
            background-color: #1B68DF;
            color: #fff;
            border: none;
            border-radius: 4px;
            font-family: 'Montserrat';
            width: 185px;
            margin: 0 10px;
            padding: 5px;
            font-weight: 500;
        }
    }

    // .super-admin-col-1{
    //         .new-block{
    //             background-color: #fff;
    //             width: 230px;
    //             height: 150px;
    //             border-radius: 5px;
    //             &--heading{
    //                 color: #82838b;
    //                 text-align: center;
    //                 font-size: 16px;
    //                 padding: 10px;
    //                 margin-bottom: 20px;
    //             }
    //         }
    //         .enquiries-block{
    //             background-color: #fff;
    //             width: 45%;
    //             height: 150px;
    //             border-radius: 5px;
    //             &--heading{
    //                 color: #82838b;
    //                 text-align: center;
    //                 font-size: 16px;
    //                 padding: 10px;
    //             }
    //         }
            
    //         .revenue-block{
    //             background-color: #fff;
    //             border-radius: 5px;
    //             position: relative;
    //             height: 500px;
    //             &--heading{
    //                 color: #82838b;
    //                 font-size: 16px;
    //                 padding: 10px;
    //                 margin-top: 15px;
    //             }
                
    //             .left-block{
    //                 display: inline-block;
    //                 padding-left: 10px;
    //                 h4{
    //                     color: #82838b;
    //                     font-size: 16px;
    //                     margin-bottom: 1px;
    //                 }
    //                 .products, .services{
    //                     width: 100px;
    //                 }
    //             }
                
    //             .right-block{
    //                 display: inline-block;
    //                 padding-left: 10px;
    //                 position: absolute;
    //                 right: 8%;
    //                 top: 30px;

    //                 h4{
    //                     color: #160039;
    //                     margin: 0px 5px;
    //                 }
    //                 .light-blue--circle{
    //                     background-color: #47cacc;
    //                     width: 35px;
    //                     height: 35px;
    //                     border-radius: 50%;
    //                     // margin-right: 5px;
    //                     margin: 2px;
    //                 }

    //                 .light-orange--circle{
    //                     background-color: #ffbe88;
    //                     width: 35px;
    //                     height: 35px;
    //                     border-radius: 50%;
    //                     // margin-right: 5px;
    //                     margin: 2px;
    //                 }
    //             }

    //             .earnings-card{
    //                 width: 215px;
    //                 border-radius: 5px;
    //                 position: absolute;
    //                 right: 10px;
    //                 bottom: 20px;
    //                 box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    //                 .price{
    //                     color: #ffba57;
    //                 }
    //                 .heading{
    //                     color: #8c939b;
    //                 }

    //                 &--upper{
    //                     padding: 8px;
    //                 }
    //                 &--lower{
    //                     height: 40px;
    //                     background-color: #ffba57;
    //                     border-radius: 0 0 5px 5px;
    //                     display: flex;
    //                     justify-content: space-between;
    //                     align-items: center;
    //                     padding: 10px;
    //                     h6{
    //                         color: #fff;
    //                     }
    //                 }
    //             }
    //         }

    // }

    .graph-chart{
        background-color: #fff;
        width: 270px;
        height: 165px;
        border-radius: 5px;
        margin-bottom: 10px;
        &--heading{
            color: #82838b;
            text-align: center;
            font-size: 16px;
            padding: 15px 0;
            margin-bottom: 20px;
        }
    }

    .lower-block{
        display: flex;
        background-color: #4680ff;
        justify-content: space-between;
        color: #fff;
        p{
            margin: 0;
        }
    }

    .pie-block{
        background-color: #fff;
        margin: 0 10px;
        height: 200px;
        &--heading{
            color: #82838b;
            text-align: center;
            font-size: 16px;
            padding: 10px;
        }
    }

    .super-admin-col-2{
            display: flex;
            padding: 0;

            .requests-block{
                background: white;
                margin: 0 10px;
                margin-bottom: 10px;
            }
    }
}

.super-admin-doctors-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.corporate-table{
    margin-top: 30px;

    .super-admin--card{
        padding: 20px 0 0 0;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        background: #fff !important;
    }
    .tab_name {
        color: var(--icons);
        font-weight: bold;
        font-size: 16px;
    }
    .items {
        border-bottom: 1px solid #ddd;
        padding: 12px 0;
    }
    .items * {
        flex: 1;
    }
    .main--custom {
        border: none !important;
        background: #eceff4;
        padding: 15px 0;
        margin-top: 15px;
    }
    .white-space {
        white-space: nowrap;
    }
    .remove_last_border > div:last-child {
        border: none !important;
    }
    .table_sub {
        font-size: 15px;
        color: #888585eb;
    }
    .user_img_two {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50px;
    }

    .prior-low {
        color: #df8688;
        background-color: #faddda;
        padding: 3px;
        border-radius: 5px;
        max-width: 80px;
        text-align: center;
    }
    .prior-high {
        color: #7c9be0;
        background-color: #d8e3ff;
        padding: 3px;
        border-radius: 5px;
        max-width: 80px;
        text-align: center;
    }
    .prior-medium {
        color: #bdd59e;
        background-color: #e9f6dd;
        padding: 3px;
        border-radius: 5px;
        max-width: 80px;
        text-align: center;
    }    

}


.profile-notif--icons{
  padding-right: 15px; 
  display: flex; 
  align-items: center;
}

@media (max-width: 850px){
    .super-admin--header{
        flex-direction: column;
        align-items: self-start;
    }
    .super-admin .admin-btns{
        display: flex;
        flex-direction: column;
        .bk-doct, .send-msg{
            margin: 5px 0;
        }
    }
  
    .super-admin .super-admin-doctors-list{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 650px){
    .profile-notif--icons{
        flex-direction: column;
    }
}

.super-admin-col-1{
:root {
  --icons: #0b2236;
  --hover: #1B68DF;
  --body: #f2f5fa;
}
.logo {
  padding: 20px 0 0;
  font-size: 80px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 45px;
  color: var(--hover);
}
.circles {
  position: fixed;
  width: 800px;
  height: 800px;
  border-radius: 50%;
}
.text-align-strt {
  text-align: start;
}
.c-one {
  bottom: -450px;
  background-color: #1B68DF;
  left: -250px;
  z-index: -1;
}
.c-two {
  top: -300px;
  right: -300px;
  background-color: #daf66e;
  z-index: -1;
}
.white-space {
  white-space: nowrap;
}
.sidebar {
  position: fixed;
  left: 0;
  height: 100%;
  width: 80px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding-top: 20px;
}
.header {
  margin-left: 80px;
  padding: 5px 20px;
  font-size: 25px;
  width: 100%;
}
.display {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-btw {
  justify-content: space-between;
}
.align-items {
  align-items: center;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.cursor-pointer {
  cursor: pointer;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.h-50 {
  height: 50% !important;
}
.w-50 {
  width: 50% !important;
}
.side-icons {
  font-size: 25px;
  gap: 30px;
  padding: 50px 10px;
  color: var(--icons);
}
.side-icons div {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.side-icons div:hover {
  color: #fff;
  background-color: var(--hover);
}
.special-icon {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
}
.reload {
  font-size: 20px;
}

.border-up div:nth-child(1) {
  border-top: 1px solid #ddd;
  background-color: transparent !important;
}

.card--custom {
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background: #fff !important;
}
.header div {
  gap: 40px;
}
.buttons {
  gap: 20px;
}
.buttons button {
  outline: none;
  border: none;
  background-color: var(--hover);
  color: #fff;
  padding: 8px 40px;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
}
.tab_name {
  color: var(--icons);
  font-weight: bold;
  font-size: 19px;
}
.lrg-icons {
  font-size: 50px;
  color: #0b9376;
  margin-right: 120px;
  padding: 10px;
}
.user_img {
  width: 100px;
  height: 100px;
  position: relative;
}
.user_img img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
}
.user_img div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  background-color: #58f9b6;
  right: -50px;
  border: 4px solid #fff;
}
.content {
  display: flex;
  align-items: start;
  gap: 40px;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  justify-content: center;
}
.content > div:nth-child(1),
.content > div:nth-child(2) {
  flex-grow: 1;
}
.content > div:nth-child(1) {
  max-width: 600px;
  gap: 20px;
}
.content > div:nth-child(2) {
  max-width: 400px;
  gap: 20px;
}
.content > div:nth-child(3) {
  max-width: 600px;
  gap: 20px;
}
.content > div:nth-child(1) > div {
  flex-grow: 1;
}
.title {
  font-size: 20px;
  letter-spacing: 0.5px;
  color: #777;
}
.numbers {
  color: var(--icons);
  font-weight: bold;
  font-size: 30px;
}

.stars {
  fill: #eea212;
  font-size: 16px;
}
.rate {
  font-size: 14px;
}
.multcards {
  width: 1000px;
  gap: 40px;
}
.card_img {
  width: 100px;
  height: 80px;
}
.pract_card {
  padding: 10px;
  border-radius: 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.items {
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
}
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.items * {
  flex: 1;
}

.user_img_two {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50px;
}
.prior-low {
  color: #df8688;
  background-color: #faddda;
  padding: 3px;
  border-radius: 5px;
  max-width: 80px;
}
.prior-high {
  color: #7c9be0;
  background-color: #d8e3ff;
  padding: 3px;
  border-radius: 5px;
  max-width: 80px;
}
.prior-medium {
  color: #bdd59e;
  background-color: #e9f6dd;
  padding: 3px;
  border-radius: 5px;
  max-width: 80px;
}
.table_sub {
  font-size: 15px;
  color: #888585eb;
}
.remove_last_border {
  padding: 0 30px 0 0;
}
.remove_last_border > div:last-child {
  border: none !important;
}
.main {
  border: none !important;
  background: #eceff4;
  padding: 15px 0;
  margin-top: 20px;
}
.enquiry {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50px;
}

/* --- Progress --- */
.loader {
  width: 200px;
}
.small {
  width: 170px;
}
.contain {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contain {
  position: relative;
  width: 100%;
}
.contain > div:nth-child(1),
.contain > div:nth-child(3) {
  position: absolute;
}
.progress {
  stroke-width: 1.5px;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  -webkit-animation: fake-load 5s infinite;
  animation: fake-load 5s infinite;
  transform-origin: center;
  transform: rotate(-90deg);
  stroke: #46c7cc;
}
.progress_2 {
  stroke-width: 1.5px;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  -webkit-animation: fake-load2 5s infinite;
  animation: fake-load2 5s infinite;
  transform-origin: center;
  transform: rotate(-90deg);
  stroke: #ffb957;
}
@-webkit-keyframes fake-load {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: 40;
  }
}

@keyframes fake-load {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: 40;
  }
}
@-webkit-keyframes fake-load2 {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: 50;
  }
}

@keyframes fake-load2 {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: 50;
  }
}
.inside_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.inside_circle > div:nth-child(1) {
  font-size: 35px;
  color: var(--icons);
}
.inside_circle > div:nth-child(2) {
  font-size: 25px;
  color: #46c7cc;
  font-weight: bold;
}
.inside_circle > div:nth-child(3) {
  color: #999;
}
.col_new {
  background-color: #ffb957 !important;
}
.make_c {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: #46c7cc;
}
.scard_up {
  background-color: #fff;
  padding: 15px;
  width: 200px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  bottom: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.scard_text {
  color: #ffb957;
  font-size: 20px;
  font-weight: bold;
}
.scard_bottom {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #ffb957;
  color: #fff;
  padding: 10px;
  width: 200px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.sprt {
  color: var(--hover);
  letter-spacing: 0.2px;
  font-size: 18px;
}
.req-bottom {
  background-color: var(--hover);
  color: #fff;
  height: 60px;
  font-size: 18px;
  border-radius: 5px;
}
.rs-badge-content {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px !important;
  right: 2px !important;
  top: 5px !important;
}
.floating {
  position: fixed;
  bottom: 40px;
  left: 10px;
  font-size: 40px;
  background-color: #fff;
  padding: 8px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

}