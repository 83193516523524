.provide-consultation-info {
  .input-field {
    label {
      width: 40%;
      font-weight: 500;
    }

    .neumorphic-input {
      width: 55%;
    }

    .btns {
      width: 60%;
      border-radius: 30px;
      padding: 0.6rem 1rem;
    }
  }
  .no-insurance {
    color:#750007;
    font-weight: 600;
    font-style: italic;
    font-size: 15px;
  }
  .emirates-id-image-field {
    width: 100%;
    .emirates-id-image-field-title {
      color: $izi-logo-color;
    }

    .emirates-id-image-label {
      position: relative;
      cursor: pointer;
      background-color: white;
      width: 95%;
      border-radius: 10px;
      box-shadow: 0px 0px 5px #ccc;
      height: 12rem;
      padding: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .selected-img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        top: 0px;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .emiratesID-input {
      display: none;
    }
  }

  .gender-select-field {
    display: flex;

    .field-label {
      width: 40%;
      font-weight: 500;
    }
    .headlines{
      color: #1B68DF !important;
      font-weight: 500;
      font-size:20px;
    }
    .check-box-container {
      display: flex;
    }

    .mm-radio-button {
      display: flex;
      align-items: center;

      .label {
        color: grey;
      }

      .radio {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        margin-right: 0.5rem;
        background-color: rgb(168, 168, 168);
        display: flex;
        align-items: center;
        justify-content: center;

        &.selected {
          background-color: $izi-logo-color;
        }

        .far {
          color: white;
          font-size: 1.3rem;
        }
      }
    }
  }

  textarea {
    width: 100%;
    height: 8rem;
  }

  .label {
    font-weight: 600;
  }

  .btn-primary {
    box-shadow: none;
    border-radius: 5px;
    width: 70%;
    height: 3rem;
  }
}

// .onboardform-region{
// }
.medical-doc-label{
  justify-content: space-between;
}

.voice-record-field {
  display: flex;
  align-items: center;

  .audio-player {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .btn {
      box-shadow: none;

      .fa-times-square {
        color: red;
        font-size: 1.5rem;
      }
    }
  }

  .voice-record-hint {
    margin-bottom: 0px;
    color: $izi-logo-color;
  }

  .btn {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    box-shadow: 0px 0px 5px #ccc;
  }
}

.medical-document-field {
  font-weight: 600;
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  .medical-doc-label {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    cursor: pointer;
  }

  .medical-doc-input {
    display: none !important;
  }

  .far {
    font-size: 1.5rem;
  }

  .click-to-upload {
    color: #1B68DF;
    font-size: 14px;
    font-weight: 500 !important;
  }

  .fa-file-pdf {
    color: red;
    font-size: 50px;
  }

  .fa-check-circle {
    color: $izi-logo-color;
  }
}
