@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
  .recipe-section {
    .recipe-heading {
      font-weight: bold;
      font-size: 14px;
    }
    svg{
      height: 16px;
      width: 16px;
    }
    .select-search__input {
      padding: 0px 10px;
      height: 25px;
      font-size: 14px;
    }
    .search-cuisine-types {
      .res-book-field {
        width: 125px;
      }
      .select-search__value {
        width: 125px;
      }
    }
    .recipe-card {
      width: 10rem;
      height: 16rem;
      font-size: 14px;
      .recipe-img {
        height: 10rem;
      }
      .recipe-footer {
        padding: 0px;
        justify-content: flex-end;
      }
    }
  }
  .recipe-image{
    width: 100%;
    min-height: 5rem;
    max-height: 5rem;
    min-width: 7rem;
  }
  .recipes-tiles {
    .list-container {
        overflow-x: hidden;
        width: 30rem;
        .loading-container { width: 100px; }
    }
    .scroll-snap-parent-x { scroll-snap-type: block; } 
    .list-container { overflow-x: scroll; } 
    .list-container::-webkit-scrollbar { height: 5px !important; } /* Track */ 
    .list-container::-webkit-scrollbar-track { background: #f1f1f1; } /* Handle */ 
    .list-container::-webkit-scrollbar-thumb { background: rgb(245, 248, 254); } /* Handle on hover */ 
    .list-container::-webkit-scrollbar-thumb:hover { background: #555; } 
    .scrollBtn { display: none; }
  }
}
