.passwordless-signin {
  .logo-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;

    .izi-logo {
      width: 12rem;
    }
  }

  .btn-mobile-signin {
    box-shadow: none;
    width: 14rem;
  }
}
