.dml-5, .dmx-5 {
  margin-left: 3rem!important;
}
.dmr-5, .dmx-5 {
  margin-right: 3rem!important;
}
.dashboard-home {
  font-family: "Montserrat" !important;
  & > .row {
    justify-content: space-between;
  }

  .home-welcome-text {
    p {
      color: #aaa;
      font-size: 0.8rem;
    }
  }

  .home-most-popular {
    padding-top: 0rem !important;
    font-family: "Montserrat" !important;
    .items-list {
      .most-popular-item {
        .content {
          padding: 0px;
          display: flex;
          flex-direction: column;
          justify-content: start;
        }

        .video-card {
          flex-basis: 35%;
        }
      }
    }
  }

  .home-fitness {
    .video-card {
      //margin-right: 1.5rem;
      //height: 15rem;
      min-height: 350px;
      //width: 16rem;
      width: 100% !important;
      min-width: 15rem;
      .title {
        font-size: 1rem;
        font-weight: 400;
      }

      .category {
        margin-bottom: auto;
      }
    }
  }
  .dash-icon-btn-group {
    margin-top: 2rem;
    overflow: hidden;
    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 160px;
      margin: 5px;
      button {
        width: 9rem;
        // height: 7.5rem;
        height: 9rem;
        background-image: linear-gradient(to right, #EBEDFA 0%, #F2F4FC 51%,#EBEDFA 100%);
        transition: 0.5s;
        border-radius: 20px;
        border: none;
        box-shadow: 6px 6px 14px 0 rgba(0, 0, 0, 0.2), -8px -8px 18px 0 rgba(255, 255, 255, 0);
      }
      button:hover{
        box-shadow: 6px 6px 14px 0 rgba(0, 0, 0, 0.5), -8px -8px 18px 0 rgba(255, 255, 255, 0.9);
        background-position: right center; 
      }
      .title {
        font-weight: bold;
        color: #022360;
        margin-top: 20px;
        margin-bottom: 0px;
      }
      label {
        margin-bottom: 0px;
      }
      .description {
        text-align: center;
        max-width: 160px;
        font-size: 12px;
      }
    }
  }

  .dashboard-icons-settings {
    width: 5rem;
    // padding: 0rem !important;
  }
  .dashboard-icons-settings-mp {
    width: 4.2rem;
    // padding: 0rem !important;
  }
}

@media (max-width: 768px) {
  .dashboard-home {
    font-family: "Montserrat";
    & > .row {
      justify-content: center;
    }
  }
}


