.meal-plan-onboarding {
  .meal-plan-onboarding-card {
    // background-color: white;
    border-radius: 10px;
    padding: 1rem 1rem;
    margin: 1rem 1rem;

    .onboarding-steps {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 1000px;
      padding: 0px 5rem 2rem 5rem;

      .step {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        border: 1px solid $izi-logo-color;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .step-name {
          position: absolute;
          bottom: -25px;
          left: -120px;
          right: -120px;
          text-align: center;
        }
      }

      .line {
        height: 1px;
        flex: 1;
        background-color: $izi-logo-color;
      }
    }

    .meal-paln-calories {
      box-shadow: $box-shadow;
      width: fit-content;
      padding-bottom: 1rem;
      background-color: white;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, .125);
      border-radius: 1rem;
    }

    //  BMR Calories
    .bmrCalculator-maincontainer {
      display: grid;
      grid-template-rows: 1fr 1fr;
      align-items: center;
      gap: 2rem;
      margin: 1fr 1fr;
      justify-items: center;

      .Caloriegenerate-button {
        border-radius: 0.6rem;
        border: none;
        background-color: #1b68df;
        width: fit-content;
        padding: 1rem 1rem;
        height: fit-content;
        color: whitesmoke;

        &:hover {
          background-color: blue;
        }
      }
    }

    .recipes-loaded {
      display:flex;
      justify-content: center;
      width: 90%;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, .125);
      margin: 1rem 0rem 0rem 0rem;
      padding: 0.5rem 0rem 0.5rem 0rem;
    }

    .bmrCalories-input {
      display: grid;
      grid-template-columns: 0.5fr 1.5fr;
      text-align: end;
      align-items: center;
      gap: 2rem;
      width: 60%;

      .Calories-calculator {
        border: 1px solid #ced4da;
        display: flex;
        gap: 1rem;
        justify-content: stretch;
        text-align: center;
        padding: 0.5rem 1rem;
      }

      .Calories-calculator>select {
        border: none;
        gap: 3rem;
        width: 100%;
        outline: none;
      }


      .Calories-calculator input {
        border: none;
      }

      .Calories-calculator>input:focus {
        border: none;
        outline: none;
        appearance: none;
      }

      .Calories-calculator input::-webkit-inner-spin-button,
      .Calories-calculator input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .Calories-calculator input::-ms-clear {
        display: none;
        /* Hide the clear button in IE 10 and IE 11 */
      }

      .Calories-calculator>label,
      .Calories-calculator>button {
        padding-top: 0.5rem;
      }

      .Calories-calculator>button {
        background: none;
        outline: none;
        border: none;
        color: #1b68df;
        width: 90%;
      }

      .Calories-calculator>button:hover {
        text-decoration: underline;

      }

      .bmrCalories-inputmeals {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        align-items: center;
        gap: 2rem;
        width: 100%;
        max-width: auto;
      }


    }

    .diet-goals {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: auto;
      align-items: center;
      gap: 2rem;
      width: 100%;
      margin: 0rem 0rem 1rem 0rem;


      .diet-goal-item {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border-radius: 10px;
        padding: 1rem 1rem;
        cursor: pointer;
        color: #1b68df;
        width: 100%;

        &:hover {
          // background-color: #1b68df;
          border-radius: 0.5rem;
          color:#4892ff;
        }

        .name {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0.3rem;
        }

        .name,
        .description {
          text-align: center;

        }

        .description>img {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          margin-bottom: 0.1rem;
        }
      }
    }

    .diet-types {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      .diet-type-item {
        width: 30rem;
        height: 7rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        // border: 1px solid $izi-logo-color;
        box-shadow: $box-shadow;
        cursor: pointer;
        padding: 1rem 1rem;

        .name {
          font-weight: bold;
          margin-bottom: 0.5rem;
        }

        .name,
        .description {
          text-align: center;
        }
      }
    }

    .allergies-and-exclusions {

      .allergies,
      .sub-exclusions {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
      }

      .mojor-exclusion-list-item {
        padding: 1rem 1rem;
        border-radius: 10px;

        &:nth-child(odd) {
          background-color: #8080801a;
        }

        &:nth-child(even) {
          background-color: #1b68df12;
        }
      }

      .exclusion-list-item {
        border: 1px solid $izi-logo-color;
        padding: 0.4rem 1rem;
        border-radius: 20px;
        cursor: pointer;
        font-size: 0.8rem;

        &.selected {
          border-color: red;
          color: red;
          text-decoration: line-through;
        }
      }

      .search-exclusions {
        padding: 0.5rem 1rem;
        border-radius: 20px;
        border: 1px solid #ccc;
        cursor: pointer;

        .text-secondary {
          font-size: 0.8rem;
        }
      }

      .selected-custom-exclusions {
        display: flex;
        gap: 1rem;

        .custom-exclusion-item {
          background-color: lighten($color: $izi-logo-color, $amount: 45);
          // border: 1px solid $izi-logo-color;
          color: $izi-logo-color;
          border-radius: 20px;
          padding: 0.2rem 0.5rem;
          cursor: pointer;
          display: flex;
          gap: 0.5rem;
          align-items: center;
          justify-content: space-between;

          .name {
            padding: 0rem 0.5rem;
          }

          .remove {
            border-radius: 50%;
            border: 1px solid $izi-logo-color;
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

.custom-exclusions-modal {
  .modal-body {
    display: flex;
    flex-direction: column;
    height: 500px;

    .ingredients-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex: 1;
      overflow-y: scroll;

      .custom-exclusion-item {
        color: $izi-logo-color;
        text-align: center;
        padding: 0.5rem;
        cursor: pointer;
      }
    }
  }
}

// Inserted by mahi

.popup-calculation {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.popup-modalboxes {
  width: 100%;
  display: grid;
  align-items: center;

  &>div:first-child {
    background-color: #cce5ff;
    font-size: 12px;
    border-radius: 0.4rem;
  }

  .modal-flexbox>div {

    display: grid;
    grid-template-columns: 0.5fr 1.2fr;
    margin-top: 1rem;
    align-items: center;
    height: 3rem;

  }

  .modal-flexbox>div>div {
    border: 1px solid #ced4da;
    border-radius: 4px;
    width: 100%;
    display: flex;
    border-radius: 4px;
    width: 100%;
    align-items: center;
  }

  .modal-flexbox>div>input {
    height: 3rem;
    width: 50%;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  .modal-flexbox>div>div>input {
    height: 3rem;
    width: 50%;
    border: 1px solid #ced4da;
    border-radius: 4px;
    // border: 2px solid red;
  }

  .modal-flexbox>div>input::placeholder {
    text-align: right;
    padding-right: 2rem;
  }
  .modal-flexbox>div>div>input::placeholder {
    text-align: right;
    padding-right: 2rem;
  }

  .modal-flexbox>div>input:focus {
    outline: 2px solid #4892ff;
    border: 2px solid #4892ff;
  }
  .modal-flexbox>div>div>input:focus {
    outline: 2px solid #4892ff;
    border: 2px solid #4892ff;
  }

  .modal-flexbox>div>select {
    height: 3rem;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }

  .modal-flexbox>div>select:focus {
    outline: 2px solid #4892ff;
    border: 2px solid #4892ff;
  }

  .modal-flexbox-doubleinput>div>button {
    background-color: none;
    height: 3rem;
    width: 50%;
    background: none;
    border: none;
    border-right: 1px solid #ced4da;
  }

  .modal-flexbox-doubleinput>input {
    background-color: none;
    height: 3rem;
    width: 50%;
    background: none;
    border: none;
    border-right: 1px solid #ced4da;
  }

  .modal-flexbox-doubleinput>input:focus {
    outline: 2px solid #4892ff;
    border: 2px solid #4892ff;
  }

  .modal-flexbox-doubleinput>input:last-child {
    border-right: none;
  }

  .modal-flexbox-doubleinput>input::placeholder {
    text-align: right;
    padding-right: 1rem;
  }

  .modal-flexbox-doubleinput>div>button:hover {
    background-color: #4892ff;
    color: white;
  }

  .modal-flexbox-doubleinput>div>button:last-child {
    border-right: none;
  }

  .modal-flexbox-trippleinput>div>button {
    height: 3rem;
    width: 33.333%;
    background: none;
    background: none;
    border: none;
    border-right: 1px solid #ced4da;
  }

  .modal-flexbox-trippleinput>div>button:hover {
    background-color: #4892ff;
    color: white;
  }

  .modal-flexbox-trippleinput>div>button:last-child {
    border-right: none;
  }
 
}


.calculate-unknownCalories {
  width: 40%;
  height: 3rem;
  background-color: #1b68df;
  outline: none;
  margin-top: 1rem;
  border: none;
  color: whitesmoke;
  border-radius: 1.5rem;
}

.Result_calories {
  display: grid;
  justify-items: center;

}

.Result_calories>div:first-child {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  border-top: 1px solid #ced4da;
  border-bottom: 3px solid #ced4da;
  margin: 2rem 0rem 0rem 0rem;
  padding: 1rem 0rem 1.3rem 0rem;
}

.Result_calories>div:nth-child(2) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: 1rem 0rem 0rem 0rem;
  width: 100%;
  font-size: 12px;
}

.mealrecipescards {
  width: 90%;

  .mealrecipetittle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0rem 1rem 0rem;
    padding: 0rem 0.5rem 0rem 0.5rem;
  }

  .mealrecipetittlechart {
    width: 100%;
    height:10rem;
    // border: 2px solid red;
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // border: 2px solid red;
    // margin: 0.5rem 0rem 1rem 0rem;
    // padding: 0rem 0.5rem 0rem 0.5rem;
    // gap: 1rem;
    // word-wrap: break-word;
    // // background-color: #fff;
    // background-clip: border-box;
    // border: 1px solid rgba(0, 0, 0, .125);
  }

  .mealrecipetittlechart:hover {
    cursor: pointer;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    // margin: 1.5rem 0rem 1rem 0rem;
  }

  .mealrecipetittlechart>div {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    margin: 0.5rem 0rem 1rem 0rem;
    width: 100%;
    height: 100%;
    gap: 2rem;
  }

  .mealrecipetittle>button {
    width: 20%;
    height: 2rem;
    outline: none;
    border: none;
    color: black;
    background: none;
  }


  .mealrecipecard {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    gap: 10px;
  }

  .mealrecipecard:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;
  }

  .mealrecipeitems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    padding: 0rem 0.5rem 0.5rem 1rem;
  }
  .mealrecipeitems>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }

  .mealrecipeitems>div>img {
    width: 4rem;
    height: 4rem;
    border-radius: 1rem;
  }
}

.cardheading{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.selected-item {
  background-color:#4892ff; 
  .name {
    color: white;  
  }
}
.error{
  color: red;
  font-size: 12px;
  display: flex;
  justify-content:center ;
  margin: 0.5rem 3rem 0rem 0rem;
}

.daytabs {
  display: flex;
}

.tab {
  // background-color: #f2f2f2;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  margin-right: 10px;
}

.tab:hover {
  background-color: #ddd;
}

.active-tab {
  background-color: blue;
  color: #fff;
}

.inactive-tab {
  background-color: #4892ff;
    color: white;
}

// recipe progress bar
.recipepiechart{
  width: 100%;
  height: 100%;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: space-around;
  align-items: center;
}
.recipepiechart_micros{
  display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.recipepiechart_micros>label{
  font-size: 16px;
  color: pink;
  font-weight: bolder;
}
.recipepiechart_micros>p{
  font-size: 16px;
  font-weight: 400;
}
.CircularProgressbar-text {
  // dominant-baseline: middle; 
  text-anchor: middle; 
}


